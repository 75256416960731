import * as React from "react";
import { Card, Box, Typography } from "@mui/material";
import Route from '../../images/assigned-route.svg'

const CardAssignedRoute = ({ ecdashboard }) => {

  return (
    <Card
      sx={{
        minHeight: 52,
        maxWidth: "1005",
        marginRight: "0.5rem",
        marginTop: "0.5rem",
        display: "flex",
        flexDirection: "column",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
    <Box className="card-title assignroute">
        <div className="img-icon"><img src={Route} /></div>
        <Typography color="textSecondary" component="h2" >
        Assigned Routes
        </Typography>
    </Box>
      <Box
        sx={{
          overflow: "inherit",
          padding: "16px",
          background: (theme) =>
            `url(${theme.palette.mode === "dark" ? "" : ""}) no-repeat`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .icon": {
            color: (theme) =>
              theme.palette.mode === "dark" ? "inherit" : "#dc2440",
          },
        }}
      >
        <Box textAlign="left" className="left-box">
          <Typography variant="h5" component="h2">
            {ecdashboard?.[0]?.totalRoute}
          </Typography>
          <Typography variant="h5" component="p" sx={{ color: "#0076FF" }}>
          Total Routes
          </Typography>
        </Box>
        <Box textAlign="left" className="left-box">
          <Typography variant="h5" component="h2">
            {ecdashboard?.[0]?.totalAssignedTrip}
          </Typography>
          <Typography variant="h5" component="p" sx={{ color: "#63CE78" }}>
          Assigned Routes
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default CardAssignedRoute;
