import React from 'react';
import { useRecordContext } from 'react-admin';
import Icon2 from '@mui/icons-material/GpsNotFixedOutlined';
import geofence from '../views/geofence/index'

 const IconTextField = ( props ) => {
  const { source } = props;
  const record = useRecordContext();
  return (<><geofence.icon /><span>{record && record[source]}</span></>);
  // return (<><Icon2 sx={{ fill: 'black', fontSize: '12px', marginRight: '2px' }} /><span>{record && record[source]}</span></>);
};

export default IconTextField;