import * as React from "react";
import { Card, Box, Typography, Avatar } from "@mui/material";
import { Button, useGetList, useRedirect } from "react-admin";
import MoreIcon from "@mui/icons-material/MoreHorizOutlined";
import Resolved from "../../images/resolved.png";
import Pending from "../../images/pending.png";
import Acknowledged from "../../images/Acknow.svg";
import Cancelled from "../../images/Cancel.svg";
import Grid from '@mui/material/Grid';

const PanicStatus = () => {
  const { data: panicstatus } = useGetList("panicstatus");

  const redirect = useRedirect();

  const handleRedirect = () => {
    redirect("/PanicDetails");
  };

  return (
    <Card
      sx={{
        minHeight: 52,
        overflow: "unset",
        height:"286px",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
      <Box
        sx={{
          overflow: "inherit",
          padding: "16px",
          background: (theme) =>
            `url(${theme.palette.mode === "dark" ? "" : ""}) no-repeat`,

          justifyContent: "space-between",
          alignItems: "center",
          "& .icon": {
            color: (theme) =>
              theme.palette.mode === "dark" ? "inherit" : "#dc2440",
          },
        }}
        className="Panic-left"
      >
        <Typography color="textSecondary" component="h2">
          Panic Status 
          {/* <MoreIcon style={{ float: "right" }} /> */}
        </Typography>
        <Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
          <Box sx={{ display: "flex" }} className="panic-box">
           

            <Box sx={{ padding: "0px 16px",width:"125px" }} textAlign="center">
            <span style={{display:"flex", justifyContent:"end"}}>
              <Avatar
                src={Resolved}
                sx={{
                  bgcolor: "background.paper",
                }}
              />
              <Typography color="textSecondary" component="h2">
                {panicstatus ? panicstatus[0]?.num_sos_resolved : 0}
              </Typography>
            </span>
              
              <Typography color="textSecondary" component="p">
                Resolved
              </Typography>
            </Box>
          </Box>
          </Grid>
          <Grid item xs={4}>
          <Box sx={{ display: "flex" }} className="panic-box">
            
            <Box sx={{ padding: "0px 16px",width:"125px" }} textAlign="center">
              <span style={{display:"flex", justifyContent:"end"}}>
              <Avatar src={Pending} />
              <Typography color="textSecondary" component="h2">
                {panicstatus ? panicstatus[0]?.num_sos_pending : 0}
              </Typography>
              </span>
              <Typography color="textSecondary" component="p">
                Pending
              </Typography>
            </Box>
          </Box>
          </Grid>
         <Grid item xs={4}>
          <Box sx={{ display: "flex", borderRight:"none !important" }} className="panic-box">
            {/* <Avatar src={Pending} /> */}
            <Box sx={{ padding: "0px 16px",width:"125px",position:"relative",top:"30px" }} textAlign="center">
              <Typography color="textSecondary" component="h2" sx={{textAlign:"center !important"}}>
                {panicstatus ? panicstatus[0]?.total_sos : 0}
              </Typography>
              <Typography color="textSecondary" component="p" sx={{textAlign:"center !important"}}>
                Total
              </Typography>
              <Button className="show-btn" onClick={handleRedirect} sx={{float:"none !important"}}>
                View All
              </Button>
            </Box>
          </Box>
          </Grid>
           <Grid item xs={4}>
          <Box sx={{ display: "flex" }} className="panic-box">
            <Box sx={{ padding: "0px 16px",width:"125px" }} textAlign="center">
              <span style={{display:"flex", justifyContent:"end"}}>
                <Avatar src={Acknowledged} className="img-size" />
                <Typography color="textSecondary" component="h2">
                    {panicstatus ? panicstatus[0]?.num_sos_acknowledged : 0}
                  </Typography>
              </span>
                <Typography color="textSecondary" component="p">
                  Acknowledged
                </Typography>
            </Box>
          </Box>
          </Grid>
           <Grid item xs={4}>
          <Box sx={{ display: "flex" }} className="panic-box">
            
            <Box sx={{ padding: "0px 16px",width:"125px" }} textAlign="center">
              <span style={{display:"flex", justifyContent:"end"}}>
              <Avatar src={Cancelled} className="img-size" />
              <Typography color="textSecondary" component="h2">
                {panicstatus ? panicstatus[0]?.num_sos_cancelled : 0}
              </Typography>
              </span>
              <Typography color="textSecondary" component="p">
                Cancelled
              </Typography>
            </Box>
          </Box>
          </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default PanicStatus;
