function initialize(mmiToken , loadCallback) {
    try {
        if(mmiToken)
        {
        
            let count = 0;

            //Insert your script seperated by comma like scriptArr = [mapSDK_url, plugins_url];
            let mapSDK_url = "https://apis.mappls.com/advancedmaps/api/" + mmiToken + "/map_sdk?layer=vector&v=3.0";
            let plugins_url = "https://apis.mappls.com/advancedmaps/api/" + mmiToken + "/map_sdk_plugins?v=3.0";

            var scriptArr = [mapSDK_url, plugins_url];

             const recursivelyAddScript = (script) => {
              if(count < script.length) {
                const el = document.createElement('script')
                el.src = script[count]
                el.async = true;
                el.type = 'text/javascript';
                document.getElementsByTagName('head')[0].appendChild(el);
                count = count + 1;
                el.onload = function () {recursivelyAddScript(script)}
              } else 
                return loadCallback ();
            }
              recursivelyAddScript(scriptArr);
    }

    else console.log("Please! pass a token inside initialize()")

    }
    catch (e) {
        console.error(String(e));
    } 
   
};

export {initialize};
export const MMI_KEY = "4d9db97e8e30430ef7ad6015af16965c";

//getToken();

function getToken()
{
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: JSON.stringify({
      grant_type: "client_credentials",
      client_id: "33OkryzDZsK8bzU9Xe9Yxv4AgOWaX6HmS-pLmeqbZB5vCI_je3rK9nqk_Os_S-9NhEJzc0OXCIAIaZr1sUBK1Q==",
      client_secret: "lrFxI-iSEg_T8p_Y_AFrpntCe9Eu9HEkO45E14De1P3gDduE_4LxcVU00k9m7RjxloXS-Yt8kxzyPBlngqSs_wCSzVJwlXrQ"
    })
};

// const response = await fetch('https://reqres.in/api/posts', requestOptions);
// const data = await response.json();
// this.setState({ postId: data.id });

fetch('https://outpost.mappls.com/api/security/oauth/token', requestOptions)
    .then(response => response.json())
    .then(data => {console.log("dataToken",data)})
    .catch(error => {console.log("errorToken", error)})

}
//export const MMI_KEY = "35705df9-563f-4117-a04d-4c19289c27a5";