import React, { useEffect, useState } from "react";
import ActionField from "../components/actionField";
// material-ui

import {
    Grid,
    Card
} from '@mui/material';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import FormButton from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormLabel from "@mui/material/FormLabel";
import vtsDataProvider from "./../providers/dataProvider";
import {
  Datagrid,
  useEditContext,
  useGetOne,
  EmailField,
  ReferenceField,
  useRecordContext,
  Show,
  TextField,
  SimpleShowLayout,
  TextInput,
  useGetList,
  ListContextProvider,
  ListToolbar,
  Pagination,
  RecordContext,
  EditButton,
  FunctionField,
  Button,
} from "react-admin";
import SendNotification from "../utils/sendNotification";
import moment from "moment";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  
export default function EditPanicDialog({
  panicOpen,
  handlePanicClose,
  currentPanic
}) {
  
const [notifyData, setNotifyData] = useState({});
const [panic, setPanic] = useState(currentPanic)


  useEffect(() =>{
    setPanic(currentPanic)
  },[currentPanic]) 


  const handleChange = (e) =>{
    const {name, value} = e.target
    setPanic({
        ...panic,
        [name]: value
    })
  }



  const handlePanicUpdate = () =>{
    delete panic['readOnly']
    vtsDataProvider.update('panicsos',panic, setNotifyData, "Panic updated successfully", "Failed to update the panic");
    handlePanicClose()
  }


  const actionTypes = [    
    {
      id: 1,
      name: 'False Alarm'
    },
    {
      id: 2,
      name: 'Forwarded To ERSS'
    },
    {
      id: 3,
      name: 'Test'
    },
    {
      id: 4,
      name: 'No Action'
    },
  ]

  
  const statusTypes = [    
    {
      label: 'Pending',
      value: 'pending'
    },
    {
      label: 'Resolved',
      value: 'resolved'
    },
    {
      label: 'Cancelled',
      value: 'cancelled'
    },
    {
      label: 'Acknowledged',
      value: 'acknowledged'
    }
  ]

  return (
    <>
      {panic && (
        <Modal
          open={panicOpen}
          onClose={handlePanicClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="AddNewModal Panic-modal"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              SOS
            </Typography>
            <span className="close-btn" onClick={handlePanicClose}>
            <CloseIcon />
            </span>
            <form>
              <Box sx={{ flexGrow: 1, marginTop: "2em" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ marginTop: "0px" }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={7}
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Box
                              sx={{
                                marginTop: "0em",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <table>
                                <tr>
                                  <td>Serial No</td>
                                  <td>
                                    {panic?.id}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Vehicle No</td>
                                  <td>{panic?.deviceId}</td>
                                </tr>
                                <tr>
                                  <td>Time</td>
                                  <td>{moment(panic?.eventTime).utc().format("DD MMM YYYY, HH:mm:ss")}</td>
                                </tr>
                                <tr>
                                  <td>Location</td>
                                  <td>{panic?.location}</td>
                                </tr>
                                <tr>
                                  <td>Lat,Long</td>
                                  <td>{panic?.latitude+ ', '+panic?.longitude}</td>
                                </tr>
                              </table>
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Box
                              sx={{
                                marginTop: "1em",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <FormLabel>Action</FormLabel>
                              <Select
                                name="action"
                                value={panic?.action}
                                onChange={handleChange}
                                displayEmpty
                                renderValue={
                                  (panic?.action !==  null) ? undefined : () => <span>Select Action</span>
                                }
                                disabled={panic?.readOnly}
                              >
                                {actionTypes?.map((action,index) => (
                                 <MenuItem key={index} value={action?.name}>
                                      {action?.name}
                                 </MenuItem>
                                ))}                               
                              </Select>
                            </Box>
                            <Box
                              sx={{
                                marginTop: "1em",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <FormLabel>Remarks</FormLabel>
                              <TextareaAutosize
                                name="remark"
                                aria-label="empty textarea"
                                placeholder="Write Comments"
                                value={panic?.remark}
                                style={{
                                  background: "#f0f0ff",
                                  borderColor: " #d7d7de",
                                  height: "150px",
                                }}
                                onChange={handleChange}
                                disabled={panic?.readOnly}
                              />
                            </Box>
                            <Box
                              sx={{
                                marginTop: "1em",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <FormLabel>Status</FormLabel>
                              <Select
                                name="status"
                                value={panic?.status}
                                onChange={handleChange}
                                displayEmpty
                                renderValue={
                                  (panic?.status !==  null) ? undefined : () => <span>Select Status</span>
                                }
                                disabled={panic?.readOnly}
                              >                                
                                {statusTypes?.map((status,index) => (
                                 <MenuItem key={index} value={status?.value}>
                                      {status?.label}
                                 </MenuItem>
                                ))}    
                              </Select>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={8}></Grid>
                  <Grid item xs={4}>
                    <div style={{ marginTop: "1em" }}>
                      {!panic?.readOnly && <FormButton
                        variant="contained"
                        color="primary"
                        onClick={handlePanicUpdate}
                      >
                        Update
                      </FormButton>}
                      <FormButton
                        variant="contained"
                        className="cancel-btn"
                        color="primary"
                        onClick={handlePanicClose}
                        sx={{ marginRight: "1em" }}
                      >
                        Cancel
                      </FormButton>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </Modal>
      )}
      <SendNotification data={notifyData} onClose={()=>setNotifyData({})}/>
    </>
  );
}
