import React, { useState } from 'react';
import { Card, Box, Typography } from "@mui/material";
import Route from '../../images/route.svg'
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { default as ReactSelect } from "react-select";
import LinearProgress from "@mui/material/LinearProgress";

const ConstituencyList = ({ ecdashboard, constituencyOption, isLoading }) => {
  const constituencyCounts = {};
  const constituency = constituencyOption ? Object.values(constituencyOption).map(option => option.name).filter(name => name !== undefined) : [];
  ecdashboard?.[0]?.data.forEach((entry) => {
    const constituencyName = entry.constituencyName;
      if (!constituencyName || constituencyName === "." || entry.vehicleType === 'Others' || entry.vehicleType === '') {
      return;
    }
  
    if (!constituencyCounts[constituencyName]) {
      constituencyCounts[constituencyName] = {
        sector: 0,
        polling: 0,
        sectorReserved: 0,
        pollingReserved: 0,
        vehicles: 0,
      };
    }
    if (entry.vehicleType === 'Sector Officer') {
      constituencyCounts[constituencyName].sector++;
    } else if (entry.vehicleType === 'Polling') {
      constituencyCounts[constituencyName].polling++;
    } 
    else if (entry.vehicleType === 'Sector Reserved') {
      constituencyCounts[constituencyName].sectorReserved++;
    } else if (entry.vehicleType === 'Polling Reserved') {
      constituencyCounts[constituencyName].pollingReserved++;
    } else if (entry.vehicleType === 'Vehicle') {
      constituencyCounts[constituencyName].vehicles++;
    }
  });  

const [selectedConstituency, setSelectedConstituency] = useState(null);
const filteredTableRows = Object.keys(constituencyCounts)
.filter(constituencyName => !selectedConstituency || constituencyName === selectedConstituency)
.map((constituencyName, index) => {
  const { sector, polling, sectorReserved, pollingReserved } = constituencyCounts[constituencyName];
  const total = sector + polling + sectorReserved + pollingReserved;
  return (
    <tr key={index}>
      <td>{constituencyName}</td>
      <td>{sector || 0}</td>
      <td>{polling || 0}</td>
      <td>{sectorReserved || 0}</td>
      <td>{pollingReserved || 0}</td>
      <td>{total}</td>
    </tr>
  );
});

  const handleConstituencyChange = (selectedOption) => {
    setSelectedConstituency(selectedOption?.value);
  };

  return (
    <Card
      sx={{
        minHeight: 52,
        maxWidth: "1005",
        marginRight: "15px",
        marginTop: "0.5rem",
        display: "flex",
        marginBottom: "0",
        position: "relative",
        flexDirection: "column",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
    <Box className="card-title">
        <Typography color="textSecondary" component="h2" >
        Constituency List
        </Typography>
        <Box className="sel-group" alwaysOn>
          <ReactSelect
            name="Constituency"
            source="Constituency"
            options={constituency.map(name => ({ value: name, label: name }))}
            placeholder="Select Constituency"
            onChange={handleConstituencyChange}
            isClearable={true}
          />
        </Box>
    </Box>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>
        <LinearProgress />
        </Box>
      ) : (
      <Box style={{ margin: "16px", maxHeight: "230px", overflowY: "auto" }}>
        <table className="const-table">
            <thead>
            <tr>
                <th>Constituency</th>
                <th>Sector</th>
                <th>Polling</th>
                <th>Sector Reserved</th>
                <th>Polling Reserved</th>
                <th>Vehicles</th>
            </tr>
            </thead>
            {filteredTableRows.length > 0 ? (
            <tbody>{filteredTableRows}</tbody>
            ) : (
              <span>No results</span>
            )}
        </table>
    </Box>
    )}
    </Card>
  );
};

export default ConstituencyList;
