import { useEffect, useState } from "react";
import { initialize, MMI_KEY } from './../utils/initializeMap'
import { useGetOne } from "react-admin";

export const GeofenceMapView = (props) => {
    const [isloaded, setLoaded] = useState(false);
    const [mapObject, setMap] = useState(null);
    const [layerstyle, setLayerStyle] = useState("standard-day");
    const { data } = props;
    const styleMap = { width: '100%', height: '69vh', display: 'inline-block' }
    var mapObj;
    var polyline;

    useEffect(() => {
        initialize(MMI_KEY, () => {
            //Action after script has been loaded completely
            afterScriptsLoaded();
        })
    }, [])

    function afterScriptsLoaded() {
        mapObj = window.mappls.Map('map1', {
            center: [21.35516, 81.28199],
            zoom: 7,
            zoomControl: true,
        })

        setMap(mapObj)
       // setLoaded(true);
    }

    useEffect(()=>{
        if (mapObject) {
        loadDataOnlyOnce();
        clickLayer();
        }
        },[mapObject])
    
        function clickLayer(){
          // if(mapObject){
            document.getElementById('map_default').onclick = function(e) {
              setLayers('standard-day')
            }
            document.getElementById('grey-day').onclick = function(e) {
              setLayers('grey-day')
            }
            document.getElementById('standard-night').onclick = function(e) {
              setLayers('standard-night')
            }
            document.getElementById('map_hybrid').onclick = function(e) {
                setLayers('standard-hybrid')
              }
          }
    
      const loadDataOnlyOnce = () => {
          
        //  mapObject.addListener('load', function() {
        //  var container = document.getElementById('mmi_ctrl1')
        var container = document.querySelector(".expand-map-control")
         var layer_id = document.getElementById('layerId')
         if(!layer_id)
            // container.innerHTML +='<div id="layerId"><button name="layerctrl" >Layer</button></div>'
            container.innerHTML +='<li id="myBtn" class="map-layers"><a style="height: 36px;line-height: 32px;" title=" Map Layers" nofollow="" > <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LayersIcon"><path d="m11.99 18.54-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z"></path></svg></a>\
            <ul class="clearfix map-layer" id="map-layer" style="display:none">\
            <span class="" id="layer-close"> X </span>\
            <li id="map_default" class="dyLayerMap">\
                <a href="javascript:void(0)" class="mapStyleBlock active">\
                  <span class="layer-item-img">\
                      <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" />\
                      <img src="https://apis.mapmyindia.com/vector_map/thumb/style.jpg" alt="" class="mode-img" />\
                   </span>\
                   <p>Default Map </p>\
                </a>\
             </li><li id="grey-day" class="dyLayerMap">\
             <a href="javascript:void(0)" class="mapStyleBlock">\
                <span class="layer-item-img">\
                   <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
                   <img src="https://www.mappls.com/images/gray.jpg" alt="" class="mode-img" style={{width: "82px",height: "56px",right: 0,bottom: 0}} />\
                </span>\
                <p>Grey Mode </p>\
             </a>\
          </li><li id="standard-night" class="dyLayerMap">\
             <a href="javascript:void(0)" class="mapStyleBlock" >\
                <span class="layer-item-img">\
                   <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
                   <img src="https://www.mappls.com/images/nightmode.jpg" alt="" class="mode-img" />\
                </span>\
                <p>Night Mode </p>\
             </a></li>\
             <li id="map_hybrid" class="dyLayerMap">\
             <a href="javascript:void(0)" class="mapStyleBlock" >\
                <span class="layer-item-img">\
                   <img src="https://www.mappls.com/images/maplayer/style_check.png" class="styleCheck" alt="" />\
                   <img src="https://www.mappls.com/images/maplayer/hy_map.png" alt="" class="mode-img" />\
                </span>\
                <p>Satellite </p>\
             </a></li>\
          </ul>\
            </li>'
     
          // };
        
    };

    const addShapesToMap = () => {
        if (mapObject) {
            window.mappls.setStyle(layerstyle);
           // mapObject.addListener('load', function () {
                if (data) {
                    if (data.length > 0) {
                        data?.map((gofence) => {
                            const { geofencetype, coordarray, radius, lat, lng } = getlatlng(gofence.area);
                            if (geofencetype === "circle") {
                                var circle = window.mappls.Circle({
                                    map: mapObject,
                                    center: { "lat": lat, "lng": lng },
                                    radius: radius,
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    fillColor:"red",
                                   fillOpacity:0.35,
                                    strokeColor: "red",
                                    popupHtml: "<div>Name : " + gofence.name + "</div>"
                                });

                                window.mappls.Marker({
                                    map: mapObject,
                                    position: {
                                      "lat": lat,
                                      "lng": lng
                                    }
                                  });

                                  mapObject.setCenter({lat:lat,lng:lng})

                                //console.log("circle", circle)
                                circle.addListener((('click')), function () {
                                    var infowindow = window.mappls.InfoWindow({
                                        map: mapObject,
                                        position: { "lat": lat, "lng": lng },
                                        content: "<div>Name : " + gofence.name + "</div>"
                                    });
                                });
                            }

                            if (geofencetype === "polygon") {
                                window.mappls.Polygon({
                                    map: mapObject,
                                    paths: coordarray,
                                    fillColor: "blue",
                                    // fitbounds: true,
                                    popupHtml: "<div>Name : " + gofence.name + "</div>"
                                });

                            }
                            if (geofencetype === "polyline") {
                                window.mappls.Polyline({
                                    map: mapObject,
                                    paths: coordarray,
                                    // fitbounds: true,
                                    strokeColor: "green",
                                    popupHtml: "<div>Name : " + gofence.name + "</div>"
                                });
                            }

                        })
                    }
                }
           // });

        }
    }


    useEffect(() => {

        if (data) {
            if (data.length > 0 && mapObject) addShapesToMap();
        }

    }, [data, mapObject,layerstyle])

    const getlatlng = (area) => {
        let radius = ""
        let lat = ""
        let lng = ""
        let coordarray = [];

        let startpoint = area.indexOf("(");
        let endpoint = area.indexOf(")");
        //get geofence type
        let geofencetype = area.substring(0, startpoint).trim();
        geofencetype = geofencetype.toLowerCase()
        if (geofencetype === "linestring")
            geofencetype = "polyline"

        if (geofencetype === "polygon") {
            startpoint = area.indexOf("((");
            startpoint = startpoint + 1
            endpoint = area.indexOf("))");
        }

        let latlngs = area.substring(startpoint + 1, endpoint).trim();
        if (geofencetype === "polygon" || geofencetype === "polyline") {
            //get lat and lng
            const latlngarray = latlngs.split(",");
            latlngarray.map((pos) => {
                const posarray = pos.split(" ");
                coordarray.push({ lat: posarray[0], lng: posarray[1] });
            })
        }
        if (geofencetype === "circle") {
            const radarray = latlngs.split(",")
            radius = radarray[1].trim();
            const positionarray = radarray[0].split(" ")
            lat = positionarray[0].trim();
            lng = positionarray[1].trim();
        }
        return { geofencetype, coordarray, radius, lat, lng };
    }

    const setLayers = (id) => {
        setLayerStyle(id)
       // window.mappls.setStyle(id)
      }

    return (
        <div>
            <div id="map1" style={styleMap}></div>
        </div>
    );
};
