import React, { useState } from 'react';
import { Card, Box, Typography } from "@mui/material";
import Route from '../../images/sitemap-svgrepo-com.svg'
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { default as ReactSelect } from "react-select";

const CardConstituency = ({ ecdashboard, constituencyOption }) => {
  let totalSectorConstituencyCount = 0;
  let totalPollingConstituencyCount = 0;
  let totalSectorReservedCount = 0;
  let totalSectorOfficerCount = 0;
 
  const [selectedConstituency, setSelectedConstituency] = useState(null);

  ecdashboard?.[0]?.data?.forEach(item => {
    if (!selectedConstituency || item.constituencyName === selectedConstituency) {
      if (!item.constituencyName || item.constituencyName === "." || item.vehicleType === 'Others' || item.vehicleType === '') {
        return;
      }
      if (item.vehicleType === 'Sector Officer') {
        totalSectorConstituencyCount++;
      }
      else if (item.vehicleType === 'Polling') {
        totalPollingConstituencyCount++;
      }
      else if (item.vehicleType === 'Sector Reserved') {
        totalSectorReservedCount++;
      } else if (item.vehicleType === 'Reserve Polling') {
        totalSectorOfficerCount++;
      }
    }
  });

  const handleConstituencyChange = (selectedOption) => {
    setSelectedConstituency(selectedOption?.value);
  };

  const constituency = constituencyOption ? Object.values(constituencyOption).map(option => option.name).filter(name => name !== undefined) : [];

  return (
    <Card
      sx={{
        minHeight: 52,
        maxWidth: "1005",
        marginRight: "15px",
        marginTop: "15px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
    <Box className="card-title route">
        <div className="img-icon"><img src={Route} /></div>
        <Typography color="textSecondary" component="h2" >
        Constituency
        </Typography>
        <Box className="sel-group" alwaysOn>
          <ReactSelect
            name="Constituency"
            source="Constituency"
            options={constituency?.map(name => ({ value: name, label: name }))}
            placeholder="Select Constituency"
            onChange={handleConstituencyChange}
            isClearable={true}
          />
        </Box>
    </Box>
      <Box
        sx={{
          overflow: "inherit",
          padding: "0.35rem 1rem 1rem 1rem",
          background: (theme) =>
            `url(${theme.palette.mode === "dark" ? "" : ""}) no-repeat`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .icon": {
            color: (theme) =>
              theme.palette.mode === "dark" ? "inherit" : "#dc2440",
          },
        }}
      >
        <Box textAlign="left" className="left-box">
          <Typography variant="h5" component="h2">
            {totalSectorConstituencyCount + totalPollingConstituencyCount + totalSectorReservedCount + totalSectorOfficerCount}
          </Typography>
          <Typography variant="h5" component="p" sx={{ color: "#0076FF" }}>
          Total Constituency
          </Typography>
        </Box>
        <hr className="card-divider" />
        <Box textAlign="left" className="left-box">
          <Typography variant="h5" component="h2">
            {totalSectorConstituencyCount}
          </Typography>
          <Typography variant="h5" component="p" sx={{ color: "#6E26FA" }}>
          Total Sector
          </Typography>
        </Box>
        <Box textAlign="left" className="left-box">
          <Typography variant="h5" component="h2">
            {totalSectorReservedCount}
          </Typography>
          <Typography variant="h5" component="p" sx={{ color: "#C362FF" }}>
          Reserve Sector
          </Typography>
        </Box>
        <hr className="card-divider" />
        <Box textAlign="left" className="left-box">
          <Typography variant="h5" component="h2">
            {totalPollingConstituencyCount}
          </Typography>
          <Typography variant="h5" component="p" sx={{ color: "#FA9826" }}>
          Total Polling
          </Typography>
        </Box>
        <Box textAlign="left" className="left-box">
          <Typography variant="h5" component="h2">
            {totalSectorOfficerCount}
          </Typography>
          <Typography variant="h5" component="p" sx={{ color: "#D18E40" }}>
          Reserve Polling
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default CardConstituency;