import React, { useState, useEffect } from 'react';
import { useRecordContext, SearchInput, useListController, Confirm, DateField, List, useGetOne, useGetList, Datagrid, TextField, ListContextProvider, Pagination, TextInput, ListToolbar, EditButton, Button, RefreshButton, useRefresh } from 'react-admin';
import { AddUserRole } from '../components/AddUserRole';
import { EditUserRole } from '../components/EditUserRole';
import Modal from '@mui/material/Modal';
import vtsDataProvider from './../providers/dataProvider'
import "../views/users/users.css";
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SendNotification from "../utils/sendNotification";
import ViewIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box, LinearProgress } from '@mui/material';
import VTSListToolbar from '../components/VTSListToolbar';
import moment from "moment";
import useMediaQuery from '@mui/material/useMediaQuery';

// import {ToastContainer, toast} from 'react-toastify';

// import 'react-toastify/dist/ReactToastify.css';

export const UserroleList = () => {
  const [EditRoleId, setEditRoleId] = useState(0);
  const [notifyData, setNotifyData] = useState({});
  const [EditRoleopen, setEditRoleopen] = React.useState(false);

  const [filter, setFilter] = useState('');
  //const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const refresh = useRefresh()
  const [Accountopen, setAccountOpen] = useState(false);
  const handleAccountClose = () => setAccountOpen(false);

  const handleEditRoleopen = () => setEditRoleopen(true);
  const handleEditRoleClose = () => setEditRoleopen(false);

  const [isDelete, setDeleteId] = useState(0)
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    deleteId: null,
  });

  const matches = useMediaQuery('(min-width:1800px)');

 const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };

  const handleEditClick = async (event, EditRoleId) => {
    setEditRoleId(EditRoleId);
    handleEditRoleopen();
  }


  const perPage = 10;
  const sort = { field: 'createdon', order: 'DESC' };

  const { data, isLoading, page, setPage, setPerPage, ...listContext } = useListController({
    resource: 'userrole'
  });

  const filters = [<TextInput label="search" source="q" size="small" alwaysOn />];
  const filterValues = { q: filter };
  const setFilters = filters => setFilter(filters.q);

  useEffect(() => {
    var queryvalue = document.querySelectorAll('.list-content');
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const CustomEditButton = ({ source }) => {
    const record = useRecordContext();
    return <div>
      <EditIcon onClick={event => handleEditClick(event, record[source])} />
    </div>
  }

  const CustomDeleteButton = ({ source }) => {
    const record = useRecordContext();
    return <div>
      <DeleteIcon onClick={event => handleDeleteClick(event, record[source])} />
    </div>
  }

  const handleDeleteClick = (event, deleteroleId) => {
    setDeleteDialog({
      open: true,
      deleteId: deleteroleId
    });
  }

  const handleConfirmDelete = () => {
    if (deleteDialog.deleteId !== null) {
      vtsDataProvider.delete('userrole', { id: deleteDialog.deleteId }, setNotifyData, "User Role Deleted Succesfully", "Failed to Delete UserRole");
      handleConfirmClose();
    }
  }

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const handleConfirmClose = () => {
    setDeleteDialog({
      open: false,
      deleteId: null,
    });
  };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt = (record[source] !== null) ? moment(record[source]).format("DD MMM YYYY, HH:mm:ss") : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  if (isLoading) {
    return <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  } else
    return (
      <>
        <ListContextProvider debounce={8000} value={{ data, filterValues, page, setFilters, setPage, setPerPage, ...listContext }}>
          <VTSListToolbar filters={filters} actions={<AddUserRole listContext={listContext} />} />
          {/* {(data === undefined || (Array.isArray(data) === true && data.length === 0)) ? <><div className='no-records'>Data Not Available</div></> :
            <> */}
            {matches == true ? (
              <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}  />
              
              ) : (
             <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
  	        )}
              <Box className="list-content">
                <Datagrid bulkActionButtons={false} empty={<CustomEmpty />}>
                  {/* <TextField source="id"  label="Id"/> */}
                  <TextField source="rolename" label="Role Name" />
                  <DateSetter source="createdon" label="Created On" />
                  <DateSetter source="lastupdate" label="Last Update" />
                  <span label="Action" className="action-icons">
                    <CustomEditButton source="id" />
                    <CustomDeleteButton source="id" />
                  </span>
                  {/* <ToastContainer /> */}
                </Datagrid>
              </Box>
              {/* </>} */}
        </ListContextProvider>
        <EditUserRole listContext={listContext} EditRoleopen={EditRoleopen} handleEditRoleClose={handleEditRoleClose} EditRoleId={EditRoleId} />
        <Confirm
          isOpen={deleteDialog?.open}
          title="Delete Role"
          content="Are you sure you want to delete the role?"
          onConfirm={handleConfirmDelete}
          onClose={handleConfirmClose}
        />
        <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
      </>
    );
};
