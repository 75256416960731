import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import "./report.css";
import ReportsVehicle from "../../components/ReportsVehicle";
import ReportsGroup from "../../components/ReportsGroup";
import ReportsSchedule from "../../components/ReportsSchedule";
import { AuditTrials } from "../group/auditList";
import useTabPermissions from "../../hooks/useTabPermissions";
import { Miscellaneous } from "../../components/miscellaneous";

const TabPanel = (props) => {
  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && <Typography>{children}</Typography>}
    </React.Fragment>
  );
};

export const ReportList = () => {
  const [value, setValue] = React.useState(0);

  const tabView = useTabPermissions("reports");

  const handleChange = (event, newValue, index) => {
    setValue(newValue);
  };
  

 
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Tabs value={value} onChange={handleChange}>
        {tabView && tabView?.viewReportVehicle && (
          <Tab value={0} label="Vehicle/Group" />
        )}
        {/* {tabView && tabView?.viewReportGroup && <Tab value={1} label="Group" />} */}
        {tabView && tabView?.viewReportSchedule && (
          <Tab value={1} label="Schedule" />
        )}
        {tabView && tabView?.viewReportTrails && (
          <Tab value={2} label="Audit Trails" />
        )}
         {tabView && 
      (tabView?.misgeofencereport ||
        tabView?.mispanicreport ||
        tabView?.misrepvioreport ||
        tabView?.misgenpanreport ||
        tabView?.misdevstareport ||
        tabView?.misdevhisreport ||
        tabView?.misvltdOemreport
      ) &&   ( <Tab value={3} label="Miscellaneous" />)}
       
      </Tabs>

      <TabPanel value={value} index={0}>
        {tabView && tabView?.viewReportVehicle && (<ReportsVehicle />)}
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        {tabView && tabView?.viewReportGroup && (<ReportsGroup />)}
      </TabPanel> */}
      <TabPanel value={value} index={1}>
        {tabView && tabView?.readReportSchedule && (<ReportsSchedule />)}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {tabView && tabView?.readReportTrails && (<AuditTrials />)}
      </TabPanel>
     
      {tabView && 
      (tabView?.misgeofencereport ||
        tabView?.mispanicreport ||
        tabView?.misrepvioreport ||
        tabView?.misgenpanreport ||
        tabView?.misdevstareport ||
        tabView?.misdevhisreport ||
        tabView?.misvltdOemreport
      ) &&   ( <TabPanel value={value} index={3}><Miscellaneous 
        misgeofencereport = {tabView?.misgeofencereport}
        mispanicreport = {tabView?.mispanicreport}
        misrepvioreport = {tabView?.misrepvioreport}
        misgenpanreport = {tabView?.misgenpanreport}
        misdevstareport = {tabView?.misdevstareport}
        misdevhisreport = {tabView?.misdevhisreport}
        misvltdOemreport = {tabView?.misvltdOemreport}
       /></TabPanel>)}
      
    </Box>
  );
};
