import * as React from "react";
import { Card, Box, Typography } from "@mui/material";
import Route from '../../images/install-route.svg'

const CardInstallation = ({ ecdashboard }) => {

  return (
    <Card
      sx={{
        minHeight: 52,
        maxWidth: "1005",
        marginRight: "0.5rem",
        marginTop: "0.5rem",
        display: "flex",
        flexDirection: "column",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
    <Box className="card-title assignroute">
        <div className="img-icon"><img src={Route} /></div>
        <Typography color="textSecondary" component="h2" >
        Installation Status
        </Typography>
    </Box>
      <Box
        sx={{
          overflow: "inherit",
          padding: "16px",
          background: (theme) =>
            `url(${theme.palette.mode === "dark" ? "" : ""}) no-repeat`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .icon": {
            color: (theme) =>
              theme.palette.mode === "dark" ? "inherit" : "#dc2440",
          },
        }}
      >
          <Box textAlign="left" className="left-box">
          {ecdashboard?.[0]?.installationStatus.map((status, index) => (
          <Typography variant="h5" component="h2">
            {status.vehicletype === "Polling" && status.count} 
          </Typography>
          ))}
          <Typography variant="h5" component="p" sx={{ color: "#FA9826" }}>
          Polling
          </Typography>
        </Box>
        <hr className="card-divider" />
        <Box textAlign="left" className="left-box">
        {ecdashboard?.[0]?.installationStatus.map((status, index) => (
          <Typography variant="h5" component="h2">
            {status.vehicletype === "Sector Officer" && status.count} 
          </Typography>
          ))}
          <Typography variant="h5" component="p" sx={{ color: "#6E26FA" }}>
          Sector
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default CardInstallation;
