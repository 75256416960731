import React, { useState, useEffect } from 'react';
import { TopToolbar, useRefresh } from 'react-admin';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Tabs, Tab } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormLabel from "@mui/material/FormLabel";
import Grid from '@mui/material/Grid';
import TextField2 from '@mui/material/TextField';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from '@mui/material/Checkbox';
import vtsDataProvider from './../providers/dataProvider'
import "../views/users/users.css";
import { TreeViewReportComponent, TreeViewAlarmComponent, TreeViewPermissionComponent, TreeViewViewComponent } from "../components/treeview"
import SendNotification from "../utils/sendNotification";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import { useForm } from 'react-hook-form'
import { TabPanel } from './../utils/TabPanel'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const errorstyle = {
    color: "red",
    fontSize: "12px",
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1111px !important',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Buttonstyle = {
    position: 'absolute',
    background: '#4646F2',
    color: '#fff',
    zIndex: 1,
    textTransform: 'capitalize',
    width: '115px',
    border: '1px solid #4646F2',
    padding: '5px 0'
}


let date = new Date();

export const AddUserRole = ({ listContext }) => {
    const [formValues, setFormValues] = useState({});

    const [notifyData, setNotifyData] = useState({});
    const [Roleopen, setRoleOpen] = React.useState(false);
    const [Rolevalue, setRoleValue] = React.useState(0);
    const refresh = useRefresh();
    const handleRoleOpen = () => {
        setRoleOpen(true);
        setRoleValue(0);
    }
    const handleRoleClose = () => {
        setRoleOpen(false);
        reset();
    }

    const Rolehandle = (event, newValue) => {
        setRoleValue(newValue);

    };

    const { register, setValue , handleSubmit, reset, watch, formState: { errors } } = useForm({
        defaultValues: {
            rolename: "",
            createdon: date,
            roles: {
                alarm: {
                    general: false,
                    sos: false,
                    vibration: false,
                    movement: false,
                    low_speed: false,
                    overspeed: false,
                    fall_down: false,
                    low_power: false,
                    low_battery: false,
                    fault: false,
                    power_off: false,
                    power_on: false,
                    door: false,
                    lock: false,
                    unlock: false,
                    geofence: false,
                    geofence_enter: false,
                    geofence_exit: false,
                    gps_antenna_cut: false,
                    accident: false,
                    tow: false,
                    idle: false,
                    high_rpm: false,
                    acceleration: false,
                    braking: false,
                    cornering: false,
                    lane_change: false,
                    fatigue_driving: false,
                    power_cut: false,
                    power_restored: false,
                    jamming: false,
                    temperature: false,
                    parking: false,
                    bonnet: false,
                    foot_brake: false,
                    fuel_leak: false,
                    tampering: false,
                    removing: false
                },
                report: {
                    all: "",
                    route: "",
                    trip: "",
                    summary: "",
                    event: "",
                    stop: "",
                    geofence: "",
                    panic: "",
                    reportedPermitViolation: "",
                    generatedPanicViolation: "",
                    deviceStatistics: "",
                    deviceHistoryLogs: "",
                    vltdOem: ""
                },
                permission: {
                    audit_trail: "",
                    command: "",
                    dashboard: "",
                    device: "",
                    driver: "",
                    event: "",
                    geofence: "",
                    group: "",
                    // leg: "",
                    notification: "",
                    control_panel: "",
                    position: "",
                    route: "",
                    schedule: "",
                    server: "",
                    sos: "",
                    statistic: "",
                    trip: "",
                    role: "",
                    user: "",
                    vehicle_registration: "",
                    generate_permit_violation: "",
                },
                view:
                {
                    dashboard_overall: "",
                    dashboard: "",
                    dashboard_vehicle_summary: "",
                    vehicle_list: "",
                    vehicle_map: "",
                    trails: "",
                    trip_trail: "",
                    by_vehicle: "",
                    by_group: "",
                    schedule: "",
                    panic_status: "",
                    panic_sos: "",
                    entity: "",
                    group: "",
                    audit_trail: "",
                    route_list: "",
                    route_assign: "",
                    geofence_configuration: "",
                    alarm_configuration: "",
                    alarm_log: "",
                    user: "",
                    role: "",
                    control_panel: "",
                }
            }
        }
    });

      
    const checkRoleValue = (name, dataValue) => {
        let changeValue = "0";
        if (Array.isArray(dataValue)) {
            if (dataValue.length === 1) {
                if (name === "control_panel") return dataValue[0]*1;
                if(dataValue.indexOf("4") > -1) return dataValue[0] * 1;
                return dataValue[0] * 1 + 4
            }
            else if (dataValue.length > 1 && dataValue.indexOf("4") > -1) {
              if (name === "control_panel") return "9" * 1
                dataValue?.map((value, index)=>{
                    changeValue = (changeValue *1) + (value *1)
                })
               
                return changeValue * 1;
            }
            else if (dataValue.length > 1 && dataValue.indexOf("4") < 0) {
                if (name === "control_panel") return "9" * 1
                dataValue?.map((value, index)=>{
                    changeValue = (changeValue*1) + (value*1)
                })
               
                return changeValue * 1 + 4;
            }
        }
        else {
            return (dataValue === "" || dataValue === false) ? 0 : dataValue * 1 ;
        }
    }

    const checkViewValue = (name, dataValue) =>{
        if (dataValue?.length > 0) return 1;
            return 0;
    }

    const onSubmit = data => {
        setFormValues({
            rolename: data.rolename,
            createdon: data.createdon,
            roles: {
                alarm: {
                    general: data.roles.alarm.general,
                    sos: data.roles.alarm.sos,
                    vibration: data.roles.alarm.vibration,
                    movement: data.roles.alarm.movement,
                    low_speed: data.roles.alarm.low_speed,
                    overspeed: data.roles.alarm.overspeed,
                    fall_down: data.roles.alarm.fall_down,
                    low_power: data.roles.alarm.low_power,
                    low_battery: data.roles.alarm.low_battery,
                    fault: data.roles.alarm.fault,
                    power_off: data.roles.alarm.power_off,
                    power_on: data.roles.alarm.power_on,
                    door: data.roles.alarm.door,
                    lock: data.roles.alarm.lock,
                    unlock: data.roles.alarm.unlock,
                    geofence: data.roles.alarm.geofence,
                    geofence_enter: data.roles.alarm.geofence_enter,
                    geofence_exit: data.roles.alarm.geofence_exit,
                    gps_antenna_cut: data.roles.alarm.gps_antenna_cut,
                    accident: data.roles.alarm.accident,
                    tow: data.roles.alarm.tow,
                    idle: data.roles.alarm.idle,
                    high_rpm: data.roles.alarm.high_rpm,
                    acceleration: data.roles.alarm.acceleration,
                    braking: data.roles.alarm.braking,
                    cornering: data.roles.alarm.cornering,
                    lane_change: data.roles.alarm.lane_change,
                    fatigue_driving: data.roles.alarm.fatigue_driving,
                    power_cut: data.roles.alarm.power_cut,
                    power_restored: data.roles.alarm.power_restored,
                    jamming: data.roles.alarm.jamming,
                    temperature: data.roles.alarm.temperature,
                    parking: data.roles.alarm.parking,
                    bonnet: data.roles.alarm.bonnet,
                    foot_brake: data.roles.alarm.foot_brake,
                    fuel_leak: data.roles.alarm.fuel_leak,
                    tampering: data.roles.alarm.tampering,
                    removing: data.roles.alarm.removing
                },
                report: {
                    all: data.roles.report.all * 1,
                    route: data.roles.report.route * 1,
                    trip: data.roles.report.trip * 1,
                    summary: data.roles.report.summary * 1,
                    event: data.roles.report.event * 1,
                    stop: data.roles.report.stop * 1,
                    geofence: data.roles.report.geofence * 1,
                    panic: data.roles.report.panic * 1,
                    reportedPermitViolation: data.roles.report.reportedPermitViolation * 1,
                    generatedPanicViolation: data.roles.report.generatedPanicViolation * 1,
                    deviceStatistics: data.roles.report.deviceStatistics * 1,
                    deviceHistoryLogs:data.roles.report.deviceHistoryLogs * 1,
                    vltdOem: data.roles.report.vltdOem * 1
                },
                permission: {
                    audit_trail: checkRoleValue("audittrail", data.roles.permission.audit_trail),
                    command: checkRoleValue("command", data.roles.permission.command),
                    dashboard: checkRoleValue("dashboard", data.roles.permission.dashboard),
                    device: checkRoleValue("device", data.roles.permission.device),
                    driver: checkRoleValue("driver", data.roles.permission.driver),
                    event: checkRoleValue("event", data.roles.permission.event),
                    geofence: checkRoleValue("geofence", data.roles.permission.geofence),
                    group: checkRoleValue("group", data.roles.permission.group),
                    // leg: checkRoleValue("leg", data.roles.permission.leg),
                    notification: checkRoleValue("notification", data.roles.permission.notification),
                    control_panel: checkRoleValue("control_panel", data.roles.permission.control_panel),
                    position: checkRoleValue("position", data.roles.permission.position),
                    route: checkRoleValue("route", data.roles.permission.route),
                    schedule: checkRoleValue("schedule", data.roles.permission.schedule),
                    server: checkRoleValue("server", data.roles.permission.server),
                    sos: checkRoleValue("sos", data.roles.permission.sos),
                    statistic: checkRoleValue("statistic", data.roles.permission.statistic),
                    trip: checkRoleValue("trip", data.roles.permission.trip),
                    role: checkRoleValue("userrole", data.roles.permission.role),
                    user: checkRoleValue("user", data.roles.permission.user),
                    vehicle_registration: checkRoleValue("vehiclereg", data.roles.permission.vehicle_registration),
                    generate_permit_violation: checkRoleValue("generateper", data.roles.permission.generate_permit_violation),
                },
                view:
                {
                    dashboard_overall: checkViewValue("dashboard_overall", data.roles.view.dashboard_overall),
                    dashboard: checkViewValue("dashboard", data.roles.view.dashboard),
                    dashboard_vehicle_summary: checkViewValue("dashboard_vehicle_summary", data.roles.view.dashboard_vehicle_summary),
                    vehicle_list: checkViewValue("vehicle_list", data.roles.view.vehicle_list),
                    vehicle_map: checkViewValue("vehicle_map", data.roles.view.vehicle_map),
                    trails: checkViewValue("trails", data.roles.view.trails),
                    trip_trail: checkViewValue("trip_trail", data.roles.view.trip_trail),
                    by_vehicle: checkViewValue("by_vehicle", data.roles.view.by_vehicle),
                    by_group: checkViewValue("by_group", data.roles.view.by_group),
                    schedule: checkViewValue("schedule", data.roles.view.schedule),
                    panic_status: checkViewValue("panic_status", data.roles.view.panic_status),
                    panic_sos: checkViewValue("panic_sos", data.roles.view.panic_sos),
                    entity: checkViewValue("entity", data.roles.view.entity),
                    group: checkViewValue("group", data.roles.view.group),
                    audit_trail: checkViewValue("audit_trail", data.roles.view.audit_trail),
                    route_list: checkViewValue("route_list", data.roles.view.route_list),
                    route_assign: checkViewValue("route_assign", data.roles.view.route_assign),
                    geofence_configuration: checkViewValue("geofence_config", data.roles.view.geofence_configuration),
                    alarm_configuration: checkViewValue("alarm_config", data.roles.view.alarm_configuration),
                    alarm_log: checkViewValue("alarm_log", data.roles.view.alarm_log),
                    user: checkViewValue("user", data.roles.view.user),
                    role: checkViewValue("role", data.roles.view.role),
                    control_panel: checkViewValue("control_panel", data.roles.view.control_panel),
                }
            }
        })
    }

    useEffect(() => {
        if (formValues.rolename !== "" && formValues.rolename !== undefined) {
            vtsDataProvider.create('userrole', formValues, setNotifyData, "User Role Created Successfully", "Failed to Add User Role");
            setFormValues("")
            reset();
            handleRoleClose();
        }
    }, [formValues])

    useEffect(() => {
        if (notifyData?.severity === "success") {
            refresh();
        }
    }, [notifyData])


    const totalRoles = (typeof (listContext?.total) === "undefined") ? 0 : listContext?.total?.toString()
    return (
        <>
            <TopToolbar className="user-toolbar">
                {/* Add your custom actions */}

                <Button className="Adduser" onClick={handleRoleOpen} startIcon={<AddIcon />} sx={Buttonstyle}>Add Role</Button>
            </TopToolbar>
            <Modal
                open={Roleopen}
                onClose={handleRoleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description" className="AddRoleModal"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={style} className="modal-box">
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "left !important" }} >
                            Add Role
                        </Typography>

                        <Grid container spacing={2} className="rolename-top">
                            <Grid item xs={6}>
                                <Box sx={{ marginTop: '10px' }} className="rolename">
                                    <FormLabel>Role Name</FormLabel>
                                    <TextField2
                                        {...register("rolename", { required: true })}
                                        value={watch("rolename")}
                                        sx={{ width: "82%" }}
                                        placeholder="Enter Role Name"
                                    />
                                    {errors.rolename && <div style={errorstyle}>This field is required</div>}
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="modal-footer" style={{ marginTop: '10px' }}>
                                    <Button variant="contained" color="primary" type="submit" sx={{ marginRight: "-21px !important" }}>Create</Button>
                                    <Button variant="contained" className="cancel-btn" onClick={handleRoleClose} color="primary" sx={{ marginRight: '1em' }}>Cancel</Button>
                                </div>
                            </Grid>
                        </Grid>


                        <Box sx={{ flexGrow: 1, marginTop: '2em' }}>
                            <Grid container spacing={2} sx={{ marginLeft: "0px !important" }}>
                                <Grid item xs={2} className="left-side">
                                    <Typography variant="h6" component="h5">Permissions:</Typography>
                                    <Tabs value={Rolevalue} onChange={Rolehandle} className="Addrole-tabs">
                                        <Tab label="Alarms" />
                                        <Tab label="Report" />
                                        <Tab label="Permission" />
                                        <Tab label="View" />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={10} sx={{ paddingLeft: "5px" }}>

                                    <TabPanel value={Rolevalue} index={0}>
                                        <Typography variant="h6" component="h4">
                                            <TreeViewAlarmComponent watch={watch} register={register} />
                                        </Typography>
                                    </TabPanel>
                                    <TabPanel value={Rolevalue} index={1}>
                                        <TreeViewReportComponent watch={watch} setValue={setValue} register={register} />
                                    </TabPanel>

                                    <TabPanel value={Rolevalue} index={2}>
                                        <TreeViewPermissionComponent watch={watch} register={register} />
                                    </TabPanel>
                                    <TabPanel value={Rolevalue} index={3}>
                                        <TreeViewViewComponent watch={watch} register={register} />
                                    </TabPanel>
                                </Grid>

                            </Grid>
                        </Box>

                    </Box>
                </form>
            </Modal>
            <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
        </>
    );
};
