import React, { useEffect, useState } from "react";
import RouteAssignTripRouting from '../components/RouteAssignTripRouting'
import { PositionContextProvider } from "../utils/PositionContext";
import { MapContextProvider } from "../utils/mapContext";
import { useLocation } from "react-router-dom";
import { TitleContext } from "../utils/TitleContext";

export default function RouteAssignTrip(){
    const location = useLocation()
    const { setTitle } = React.useContext(TitleContext);
    const [playRoutesData, setplayRoutesData] = useState(null)
    const [planRoute, setplanRouteDetails] = useState(null);
    useEffect(() => {
        setTitle("Trip Trails")
        setplayRoutesData(null)
        setplanRouteDetails(null)
    }, [])

    useEffect(() => {
        if (location?.state) {
            console.log("location?.state",location?.state)
            setplayRoutesData(location?.state?.trip)
            setplanRouteDetails(location?.state?.route)
        }
    }, [location?.state])

    return (

        <PositionContextProvider>
            <MapContextProvider>
               <RouteAssignTripRouting playRoutesData={playRoutesData} planRoute={planRoute} />
            </MapContextProvider>
        </PositionContextProvider>

    );
};
