import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Card, Typography } from "@mui/material";
import { Button, useListContext, useRecordContext } from "react-admin";

import Icon1 from "@mui/icons-material/CellTowerOutlined";
import Icon2 from "@mui/icons-material/MyLocationOutlined";
import Icon3 from "@mui/icons-material/AddModeratorOutlined";
import Icon4 from "@mui/icons-material/HomeRepairServiceOutlined";
import Icon5 from "@mui/icons-material/RampRightOutlined";
import Card1 from "../images/card1.svg";
import Card2 from "../images/gps-grey.svg";
import Card3 from "../images/card3.svg";
import Card4 from "../images/card4.svg";
import Card5 from "../images/card5.svg";

import Card1G from "../images/card1-g.svg";
import Card2G from "../images/gps-green.svg";
import Card3G from "../images/card3-g.svg";
import Card4G from "../images/card4-g.svg";
import Card5G from "../images/card5-g.svg";
import Icon6 from "../images/Viewmap.svg";
import Card2O from "../images/gps-orange.svg";
import { containsLatLong, exists, validateLatLong } from "../utils/validate";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/icons-material/DownloadOutlined";
import Grid from "@mui/material/Grid";
import {
  MapContext,
  findUpdateMarker,
  findAddressMarker,
} from "../utils/mapContext";
import L from "leaflet";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import { getVehicleLocation } from "../providers/dataProvider";
import { Tooltip } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: "rotate(0deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandUp = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", flexDirection: "row" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};
var expandIndex = 0;

const CardListView = (props) => {
  const { data, isLoading } = useListContext();
  const [details, setDetails] = useState([]);
  const [hover, setHover] = useState(false);

  const [expanded, setExpanded] = React.useState(false);
  const { mapData, setMapData } = useContext(MapContext);

  const { bounds, setPanClick , ispanclick} = props;

  const handlePanClick = (item) => {
    let markers = findUpdateMarker(details, item);
    setMapData({ map: "map", markers: markers});

    // setPanClick(prev => {
    //   if (ispanclick == true) return false
    //   return true
    // })

  };

  const handleExpandClick = (item) => {
    handleAddress(item)
    setExpanded(!expanded);
    expandIndex = item?.id;
  };

  

  useEffect(() => {
    props?.record != null ? setDetails([props?.record]) : setDetails(data);
  }, [data, props?.record]);

  if (!exists(data)) return null;

  const handleAddress = async (item) => {
    const address = {};
    await getVehicleLocation(item, address);
    item["address"] = address?.address;
    let markers = findAddressMarker(details, item);
    setMapData({ map: "map", markers: markers });
  };

  

  return (
    <Box
      container
      spacing={1}
      justify="center"
      sx={{
        minWidth: "280px",
        width: "360px",
        height: "75vh",
        overflowY: "scroll",
        marginBottom: "20px"
      }}
    >
      {details?.length > 0 ?
        details?.map((item, index) => {
          if(item?.currentLat) item['latitude'] = item?.currentLat
          if(item?.currentLng) item['longitude'] = item?.currentLng
          var gpsColor = <img src={Card2} title="NO SIGNAL" />
          if(item?.gps){
            if(item?.gps < 11) gpsColor = <img src={Card2} title="NO SIGNAL" />
            else if(item?.gps >= 11 && item?.gps <= 20) gpsColor = <img 
            src={Card2O} title="MODERATE SIGNAL"/>
            else if(item?.gps > 20) gpsColor = <img src={Card2G} title="STRONG SIGNAL" />
          }

          let allowcheck = false;

         // console.log("item", item)

          if(item.highlight === true)
          {
            allowcheck =true
          }
          // const comment =
          //   item.status === "offline" ? "Data may be inaccurate" : "";
          if (
            validateLatLong(item.latitude, item.longitude) &&
           (containsLatLong(item.latitude, item.longitude, bounds) || allowcheck === true)
          ) {
            return (
              <Box item p={1}>
                <Card
                  sx={{
                    minHeight: 60,
                    minWidth: 250,
                    display: "flex",
                    width: "100%",
                    height: "150px",
                    flexDirection: "column",
                    "& a": {
                      textDecoration: "none",
                      color: "inherit",
                    },
                  }}
                  className="map-left-card"
                >
                  {/* <Link to="/"> */}
                  <Box
                    sx={{
                      overflow: "inherit",
                      padding: "16px 0 0 16px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      "& .icon": {
                        color: (theme) =>
                          theme.palette.mode === "dark" ? "inherit" : "#dc2440",
                      },
                    }}
                  >
                    <Box textAlign="left">
                      {!(expandIndex === item.id) ? (
                        <>
                          <Typography
                            color="textSecondary"
                            variant="h"
                            component="h4"
                            sx={{
                              marginBottom: "15px",
                            }}
                          >
                            {item?.name ? item?.name : item?.vehicleNo}
                            <Button
                              onClick={() => {
                                handlePanClick(item);
                              }}
                              variant="contained"
                              className="Card-Icons"
                              sx={{
                                border: "none !important",
                              }}
                            >
                              <img src={Icon6} />
                            </Button>
                            <Typography
                              variant="p"
                              component="p"
                              sx={{
                                fontSize: "13px",
                                fontStyle: "italic",
                                float: "right",
                                marginRight: "4px",
                              }}
                              style={styles.flexRow}
                            >
                              {/* {comment}{" "} */}
                              <Button
                                label={item?.status}
                                className="status-btn"
                                status={item?.status}
                                sx={{
                                  background:"#7b7b9e",
                                  border: "1px solid #7b7b9e",
                                  color: "#fff",
                                  fontWeight: "600",
                                  fontSize: "10px",
                                  textTransform: "capitalize",
                                  borderRadius: "10px",
                                  padding: "2px",
                                  marginLeft: "15px",
                                  minWidth: "48px",
                                }}
                              />
                            </Typography>
                            {/* {item?.status == "offline" ? (
                            <CircleIcon className="status-icon" />
                          ) : (
                            <CircleIcon className="status-icon-green" />
                          )} */}
                          </Typography>



                          <Box sx={{ marginTop: "15px" }} className="mapcard-icons">
                            {/* <Tooltip
                              title={item?.status == "online" ? "ON" : "OFF"}
                              open={hover}
                              onClose={() => setHover(false)}
                              onOpen={() => setHover(true)}
                            > */}
                              {/* <Button
                                variant="contained"
                                className="Card-Icons"
                                href=""
                                startIcon={<Icon1 />}
                                sx={{ marginTop: "10px" }}
                              >
                                {item?.status == "online" ? "green" : ""}
                              </Button> */}
                              {(item?.status == "online" || item?.status == "idle" || item?.status == "moving" || item?.status == "towing") ? <img src={Card1G} title="ON" /> : <img src={Card1} title="OFF" />}
                              
                            {/* </Tooltip> */}
                            {/* <Button
                              variant="contained"
                              className="Card-Icons"
                              href=""
                              startIcon={<Icon2 />}
                              sx={{ marginTop: "10px" }}
                            >{gpsColor ? gpsColor : ''}</Button> */}

                            {gpsColor ? gpsColor : ''}

                            {/* <Button
                              variant="contained"
                              className="Card-Icons"
                              href=""
                              startIcon={<Icon3 />}
                              sx={{ marginTop: "10px" }}
                            >{item?.ignition ? 'green' : ''}</Button> */}

                            {/* {item?.ignition ? <img src={Card3G} title="ON" /> : <img src={Card3} title="OFF" />} */}
                            {item?.ignition ?  <img src={Card4G} title="ON" /> : <img src={Card4} title="OFF" /> }
                            {/* <img src={Card4} title={item?.batteryLevel ? `EXTERNAL BATTERY ${item?.batteryLevel} V` : `EXTERNAL BATTERY 0 V`}/> */}
                            <img src={Card3} title={item?.power ? `EXTERNAL BATTERY ${item?.power?.toFixed(2)} V` : `EXTERNAL BATTERY 0 V`}/>
                            <img src={Card5} />
                            <CardActions disableSpacing className="expand-btn">
                              <ExpandMore
                                expand={expanded}
                                // onClick={handleExpandClick}
                                onClick={() => {
                                  handleExpandClick(item);
                                }}
                                aria-expanded={expanded}
                                aria-label="show more"
                              ></ExpandMore>
                            </CardActions>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Typography
                            color="textSecondary"
                            variant="h"
                            component="h4"
                            sx={{
                              marginBottom: "8px",
                            }}
                          >
                            {item?.name ? item?.name : item?.vehicleNo}
                            <Button
                              onClick={() => {
                                handlePanClick(item);
                              }}
                              variant="contained"
                              className="Card-Icons f-leftB"
                              sx={{
                                border: "none !important",
                                marginLeft: "0px !important",
                              }}
                            >
                              <img src={Icon6} />
                            </Button>
                            <Button
                              label={item?.status}
                              className="status-btn"
                                status={item?.status}
                              sx={{
                                background: "#7b7b9e",
                                border: "1px solid #7b7b9e",
                                color: "#fff",
                                fontWeight: "600",
                                fontSize: "10px",
                                textTransform: "capitalize",
                                borderRadius: "10px",
                                padding: "2px",
                                marginLeft: "15px",
                                minWidth: "48px",
                                float: "right",
                              }}
                            >
                              
                            </Button>
                          </Typography>

                          <Typography
                            variant="p"
                            component="p"
                            sx={{
                              fontSize: "13px",
                              fontStyle: "italic",
                            }}
                            style={styles.flexRow}
                          >
                            {/* Centrum, Raipur Bypass, Vishrampuri, Raipur,
                          Chhattisgarh. 56 m from Shri Laxmi Jewellers
                          pin-492013 (India) */}
                            {item?.address ? (
                              <span>{item?.address}</span>
                            ) : (
                              // <Link to="#" style={{textDecoration: 'underline',color: 'blue'}} onClick={() => handleAddress(item)}>
                              //   Show Address
                              // </Link>  
                              <Link to="#" style={{textDecoration: 'underline',color: 'blue'}}>
                              </Link>                              
                            )}
                          </Typography>
                          <Box sx={{ flexGrow: 1 }} className="information">
                            <Typography variant="h6" component="h1">
                              Information
                            </Typography>
                            <Box sx={{ marginTop: "15px" }}>
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={8}>
                                    <Typography variant="h6" component="h4">
                                      Latitude
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography variant="h6" component="h4">
                                      Engine
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <FormLabel>
                                      {item?.latitude}
                                    </FormLabel>
                                  </Grid>
                                  <Grid item xs={4} sx={{ marginTop: "8px" }}>
                                    <FormLabel>
                                      {item?.ignition ? "On" : "Off"}
                                    </FormLabel>
                                  </Grid>
                                  
                                  <Grid item xs={8}>
                                    <Typography variant="h6" component="h4">
                                      Longitude
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography variant="h6" component="h4">
                                      Speed
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <FormLabel>
                                      {item?.longitude}
                                    </FormLabel>
                                  </Grid>
                                  <Grid item xs={4} sx={{ marginTop: "8px" }}>
                                    <FormLabel>{item?.speed?.toFixed(2)} Km/hr</FormLabel>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Typography variant="h6" component="h4">
                                      GPRS
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography variant="h6" component="h4">
                                      Altitude
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <FormLabel>
                                      {moment(item?.gps_time).format(
                                        "DD MMM YYYY, HH:mm:ss"
                                      )}
                                    </FormLabel>
                                  </Grid>
                                  <Grid item xs={4} sx={{ marginTop: "8px" }}>
                                  <FormLabel>{item?.altitude?.toFixed(2)}</FormLabel>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Typography variant="h6" component="h4">
                                      Odometer (Km)
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography variant="h6" component="h4">
                                      Distance
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={8}>
                                    <FormLabel>{item?.totalDistance}</FormLabel>
                                  </Grid>
                                  
                                  
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{ marginTop: "8px" }}
                                  ><FormLabel>{item?.distance}</FormLabel></Grid>
                                  
                                </Grid>
                              </Box>
                            </Box>
                          </Box>

                          <Box sx={{ display: "flex", marginTop: "15px" }} className="mapexpand-icons">
                            {/* <Button
                              variant="contained"
                              className="Card-Icons"
                              href=""
                              startIcon={<Icon1 />}
                            ></Button> */}
                            <img src={Card4} />
                            <Typography
                              variant="p"
                              component="p"
                              sx={{
                                fontSize: "13px",
                              }}
                            >
                              Engine: {item?.ignition ? "ON" : "OFF"}
                            </Typography>
                            {/* <Button
                              variant="contained"
                              className="Card-Icons"
                              href=""
                              startIcon={<Icon2 />}
                            ></Button> */}
                            <img src={Card3} />
                            <Typography
                              variant="p"
                              component="p"
                              sx={{
                                fontSize: "13px",
                              }}
                            >
                              Internal Battery: {item?.battery ? item?.battery?.toFixed(2)+' V' : '0 V'}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", marginTop: "15px" }} className="mapexpand-icons">
                            {/* <Button
                              variant="contained"
                              className="Card-Icons"
                              href=""
                              startIcon={<Icon4 />}
                            ></Button> */}
                            <img src={Card3} />
                            <Typography
                              variant="p"
                              component="p"
                              sx={{
                                fontSize: "13px",
                              }}
                            >
                              External Battery: {item?.power ? item?.power?.toFixed(2) +' V' : '0 V'}
                            </Typography>
                          </Box>
                          <CardActions disableSpacing className="expand-btn">
                            <ExpandUp
                              onClick={handleExpandClick}
                              aria-label="show more"
                            >
                              {/* <ExpandMoreIcon /> */}
                            </ExpandUp>
                          </CardActions>
                        </>
                      )}
                    </Box>
                  </Box>
                  {/* </Link> */}
                </Card>
              </Box>
            );
          }
        }) : <Typography variant="h8" style={{ textAlign: "center" }} className="no-message">NO RECORDS</Typography>}
    </Box>
  );
};

export default CardListView;
