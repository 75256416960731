import React, { useEffect, useState, useRef } from 'react';
import { Button, useGetOne, useRefresh } from "react-admin";
import { Box, Typography } from '@mui/material';
import {
  Grid,
  Card
} from '@mui/material';
import vtsDataProvider from './../providers/dataProvider';
import FormField from '@mui/material/TextField';
import "../views/geofence/geofence.css";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CircleIcon from '@mui/icons-material/Circle';
import PointerIcon from '@mui/icons-material/AddLocation';
import PolygonIcon from '@mui/icons-material/Diamond';
import Modal from '@mui/material/Modal';
import SendNotification from "../utils/sendNotification";
import CloseIcon from '@mui/icons-material/HighlightOffOutlined';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { GeofenceMap } from '../components/GeofenceMap'
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { validateLatLong } from "../utils/validate"

const errorstyle = {
  color: "red",
  fontSize: "12px",
};

const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

let date = new Date();
const defaultValues = {
  id: "",
  attributes: {
    speedLimit: "",
    loadingPoint: false,
    unloadingPoint: false
  },
  calendarId: 0,
  name: "",
  description: "",
  area: "",
  createdon: "",
  lastupdate: date,
  latitude: "",
  longitude: "",
  geofencetype: "",
  radius: "",
  button: 0,
  createdBy: "",
  createdByUser: ""
}

export const EditGeofence = ({ EditGeofopen, listContext, handleEditGeofClose, EditGeofenceId }) => {
  const [notifyData, setNotifyData] = useState({});
  const [geofencedata, setGeofenceData] = useState(defaultValues)
  const [formValues, setFormValues] = useState(defaultValues);
  const [opened, openTextbox] = useState(false);
  const [position, setPosition] = useState([]);
  const [buttonChange, setButtonChange] = useState(false);
  const [drawmode, setDrawMode] = useState(false);
  const [radiusvalue, setRadiusValue] = React.useState(100);
  const [raddisplayvalue, setRadDisplayValue] = React.useState({ type: "mtr", value: 100 });
  const [coordValue, setcoordinates] = useState({
    type: "",
    coordarray: [],
    lat: "",
    lng: "",
    radius: ""
  })
  const [formErrors, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const refresh = useRefresh();


  const { data: EditData, isLoading, error } = useGetOne(
    'geofences',
    { id: EditGeofenceId },
    { enabled: (EditGeofenceId !== 0) }
  );


  const handleSliderChange = (event, newValue) => {
    if (validateLatLong(formValues.latitude * 1, formValues.longitude * 1)) {
      setRadiusValue(newValue);
      setFormValues({ ...formValues, radius: newValue });
    }
  };

  const handleRadiusChange = (event) => {
    if (validateLatLong(formValues.latitude * 1, formValues.longitude * 1)) {
      let value = event.target.value === '' ? '' : Number(event.target.value) * 1000
      setRadiusValue(value);
      setFormValues({ ...formValues, radius: value });
    }
  };

  const handleBlur = () => {
    if (radiusvalue <= 0) {
      setRadiusValue(100);
      setFormValues({ ...formValues, radius: 100 });
    } else if (radiusvalue > 80000) {
      setRadiusValue(80000);
      setFormValues({ ...formValues, radius: 80000 });
    }
  };

  useEffect(() => {

    if (radiusvalue > 0 && validateLatLong(formValues.latitude*1, formValues.longitude*1)) {
      setRadDisplayValue({ type: "km", value: radiusvalue / 1000 })
      circlegoClick();
    }
    
  }, [radiusvalue,formValues.latitude,formValues.longitude])

  useEffect(() => {
    setDrawMode(false);
  }, [handleEditGeofClose])

  useEffect(() => {
    const getEditGeofences = () => {
      if (EditData) {
        const { geofencetype, coordarray, radius, lat, lng } = getlatlng(EditData.area);
        setFormValues({
          ...formValues,
          id: EditGeofenceId,
          attributes: {
            speedLimit: EditData?.attributes.speedLimit,
            loadingPoint: EditData?.attributes.loadingPoint,
            unloadingPoint: EditData?.attributes.unloadingPoint
          },
          calendarId: 0,
          name: EditData?.name,
          description: EditData?.description,
          area: EditData?.area,
          createdon: EditData?.createdon,
          geofencetype: geofencetype,
          radius: radius,
          latitude: lat,
          longitude: lng,
          createdBy: EditData?.createdBy,
          createdByUser: EditData?.createdByUser
        })
        if (geofencetype === "circle") setRadiusValue(radius * 1)
        setcoordinates(
          {
            type: geofencetype,
            coordarray: coordarray,
            lat: lat,
            lng: lng,
            radius: radius
          })

        if (geofencetype === "circle") openTextbox(true);

        EditGeofenceId = 0;
      }
    }
    getEditGeofences();
  }, [EditData]);


  const circlegoClick = () => {
    setcoordinates(
      {
        type: formValues.geofencetype,
        coordarray: [],
        lat: formValues.latitude,
        lng: formValues.longitude,
        radius: formValues.radius
      })
  }


  const getlatlng = (area) => {
    let radius = ""
    let lat = ""
    let lng = ""
    let coordarray = [];

    let startpoint = area.indexOf("(");
    let endpoint = area.indexOf(")");
    //get geofence type
    let geofencetype = area.substring(0, startpoint).trim();
    geofencetype = geofencetype.toLowerCase()
    if (geofencetype === "linestring")
      geofencetype = "polyline"

    if (geofencetype === "polygon") {
      startpoint = area.indexOf("((");
      startpoint = startpoint + 1
      endpoint = area.indexOf("))");
    }

    let latlngs = area.substring(startpoint + 1, endpoint).trim();
    if (geofencetype === "polygon" || geofencetype === "polyline") {
      //get lat and lng
      const latlngarray = latlngs.split(",");
      latlngarray.map((pos) => {
        const posarray = pos.split(" ");
        if (validateLatLong(posarray[0], posarray[1]))
        coordarray.push({ "lat": posarray[0], "lng": posarray[1] });
      })
    }
    if (geofencetype === "circle") {
      const radarray = latlngs.split(",")
      radius = radarray[1].trim();
      const positionarray = radarray[0].split(" ")
      if (validateLatLong(positionarray[0], positionarray[1]))
      {
      lat = positionarray[0].trim();
      lng = positionarray[1].trim();
      }
    }
    return { geofencetype, coordarray, radius, lat, lng };
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleAttributesChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      attributes: {
        ...formValues.attributes,
        [name]: type === 'checkbox' ? checked : value,
      }
    });
  };

  useEffect(() => {
    if (opened === false) {
      setFormValues({ ...formValues, latitude: "", longitude: "", radius: "" });
    }
  }, [opened])


  useEffect(() => {

    if (formValues.geofencetype === "polygon" || formValues.geofencetype === "polyline") {
      let area = ""
      let latlngs;
      if (position) {

        let posi = ""

        position?.map((latlng, index) => {
          if (posi !== "") posi = posi + ","
          posi = posi + "" + latlng.lat + " " + latlng.lng
        })

        if (formValues.geofencetype === "polygon")
          area = (formValues.geofencetype).toUpperCase() + "((" + posi + "))"

        if (formValues.geofencetype === "polyline")
          area = "LINESTRING(" + posi + ")"


        setFormValues({ ...formValues, area: area })
      }
      else {
        setFormValues({ ...formValues, area: "" })
      }
    }

    if (formValues.geofencetype === "circle") {
      if (position) {
        setFormValues({
          ...formValues,
          latitude: position.lat,
          longitude: position.lng,
          radius: position.radius,
          area: (formValues.geofencetype).toUpperCase() + "(" + position.lat + " " + position.lng + "," + position.radius + ")"
        })
      }
      else {
        setFormValues({
          ...formValues,
          latitude: "",
          longitude: "",
          radius: "",
          area: ""
        })
      }
    }
  }, [position])


  const transferformValues = (formValues) => {
    const formtransferdata = {
      id: formValues.id,
      attributes: {
        speedLimit: formValues.attributes.speedLimit * 1,
        loadingPoint: formValues.attributes.loadingPoint,
        unloadingPoint: formValues.attributes.unloadingPoint
      },
      calendarId: 0,
      name: formValues.name,
      description: formValues.description,
      area: formValues.area,
      createdon: formValues.createdon,
      lastupdate: formValues.lastupdate,
      createdBy: formValues.createdBy,
      createdByUser: formValues.createdByUser
    }
    return formtransferdata
  }



  useEffect(() => {
    if (geofencedata.area !== "") {
      vtsDataProvider.update('geofences', geofencedata, setNotifyData, "Geofence Updated Successfully", "Failed to Update Geofence");
      setFormValues(defaultValues)
      setGeofenceData(defaultValues)
      handleEditGeofClose();
      setFormError({});
      setIsSubmit(false);
    }
  }, [geofencedata])


  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const validate = (values) => {
    const errors = {};
    //const numberregex = "^[1-9]+[0-9]*$";
    let regexnumber = /\+?\d+/;

    if (values.attributes.speedLimit !== "" && values.attributes.speedLimit !== undefined) {
      if (!String(values.attributes.speedLimit).match(regexnumber))
        errors.speedLimit = "Numbers only allowed!";
    }
    if (!values.name) errors.name = "Geofence Name is required!";
    if (values.area === "") errors.area = "Draw shapes on the map!";
    return errors;
  };

  useEffect(() => {

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const formtransferdata = transferformValues(formValues);
      setGeofenceData(formtransferdata)
    }
  }, [formErrors])

  const EditGeofence = (event) => {
    event.preventDefault();
    setFormError(validate(formValues));
    setIsSubmit(true);
  }



  const changebutton = (e) => {
    setButtonChange(prev => {
      if (buttonChange == true) return false
      return true
    })
    setDrawMode(true);
  };


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  return (<>
    {/* create new Geofence */}

    {/* <Fade in={checked}> */}
    <Modal
      open={EditGeofopen}
      onClose={handleEditGeofClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description" className="AddgeofModal"
    >
      <form onSubmit={EditGeofence}>
        <Box sx={style} className="geof-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Geofence
          </Typography>
          <span className="close-btn"><CloseIcon onClick={handleEditGeofClose} /></span>
          <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            <Card
              sx={{
                minHeight: 52,
                minWidth: 250,
                display: 'flex',
                width: '30%',
                height: '500px',
                overflow: "auto",
                flexDirection: 'column',
                '& a': {
                  textDecoration: 'none',
                  color: 'inherit',
                },
              }}
            >


              <Box textAlign="left" className='audit-left geofence-left'>
                <Typography color="textSecondary" variant="h" component="h4" className='Card-Title'
                  sx={{
                    marginBottom: '15px'
                  }}>Edit Geofence
                </Typography>
                <Box sx={{ marginTop: '1em' }}>
                  <FormControl>
                    <FormLabel>Geofence Name</FormLabel>
                    <FormField
                      name="name"
                      placeholder="Enter Name"
                      type="text"
                      value={formValues.name}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <div style={errorstyle}>
                    {formErrors.name}
                  </div>
                </Box>

                <Box sx={{ marginTop: '1em' }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <div style={errorstyle}>
                      {formErrors.area}
                    </div>
                    <FormControl>
                      <FormLabel>Geofence Type</FormLabel>
                    </FormControl>
                    <Grid container spacing={2}>
                      <Grid item xs={4} sx={{ display: "flex", flexDirection: "column" }}>
                        <div className='button' onClick={() => { openTextbox(true); }}>
                          <input type="radio" name="geofencetype" checked={(formValues.geofencetype === "circle") ? true : false} value="circle" onChange={handleInputChange} onClick={changebutton} />
                          <label class="btn btn-default" for="a25"><CircleIcon /><span>Circle</span></label>
                        </div>
                      </Grid>
                      <Grid item xs={4} sx={{ display: "flex", flexDirection: "column" }}>
                        {/* <AddButton className='area-button' startIcon={<PointerIcon />}>
                                          Pointer
                                      </AddButton> */}
                        <div className='button' onClick={() => { openTextbox(false); }}>
                          <input type="radio" name="geofencetype" checked={(formValues.geofencetype === "polyline") ? true : false} value="polyline" onChange={handleInputChange} onClick={changebutton} />
                          <label class="btn btn-default" for="a25"><PointerIcon /><span>Polyline</span></label>
                        </div>
                      </Grid>
                      <Grid item xs={4} sx={{ display: "flex", flexDirection: "column" }}>
                        {/* <AddButton className='area-button' startIcon={<PolygonIcon />}>
                                          Polygon
                                      </AddButton> */}
                        <div className='button' onClick={() => { openTextbox(false); }}>
                          <input type="radio" name="geofencetype" checked={(formValues.geofencetype === "polygon") ? true : false} value="polygon" onChange={handleInputChange} onClick={changebutton} />
                          <label class="btn btn-default" for="a25"><PolygonIcon /><span>Polygon</span></label>
                        </div>
                      </Grid>

                    </Grid>
                  </Box>
                </Box>
                {opened === true ?
                  <> <Box sx={{ marginTop: '1em' }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={5} sx={{ display: "flex", flexDirection: "column" }}>
                          <FormLabel>Latitude</FormLabel>
                          <FormField
                            name="latitude"
                            placeholder="Enter Latitude"
                            type="text"
                            value={formValues.latitude}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={5} sx={{ display: "flex", flexDirection: "column" }}>
                          <FormLabel>Longitude</FormLabel>
                          <FormField
                            name="longitude"
                            placeholder="Enter Longitude"
                            type="text"
                            value={formValues.longitude}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button onClick={() => { circlegoClick(); }} className='show-btn add'>
                            Go
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                    <hr></hr>
                    <Box className="range-slider" sx={{ marginTop: '1em' }}>
                      <FormLabel>Move the slider to set radius for this zone</FormLabel>
                      <div class="range-field">
                        <span>100 mtr</span>
                        <span>80 km</span>
                      </div>
                      <PrettoSlider
                        className='slider'
                        aria-label="pretto slider"
                        // defaultValue={100}
                        step={100}
                        marks
                        min={100}
                        max={80000}
                        value={typeof radiusvalue === 'number' ? radiusvalue : 0}
                        onChange={handleSliderChange}
                      />
                      <div class="field">
                        <span>Selected range area</span>
                        <input type="number"
                          class="input-min"
                          value={raddisplayvalue.value}
                          size="medium"
                          onChange={handleRadiusChange}
                          onBlur={handleBlur}
                          max="80"
                          min="0.1"
                          step="0.1"
                        />

                        {/* <input type="number" class="input-min" value={value} /> */}
                        <span>{raddisplayvalue.type}</span>
                      </div>
                    </Box>

                    {/* <Box sx={{ marginTop: '1em' }}>
                    <FormControl>
                      <FormLabel>Radius</FormLabel>
                      <FormField
                        name="radius"
                        placeholder="Enter Radius"
                        type="text"
                        value={formValues.radius}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                  </Box> */}
                  </>
                  :
                  <></>
                }
                <Box sx={{ marginTop: '1em' }}>
                  <FormControl>
                    <FormLabel>Description</FormLabel>
                    <TextareaAutosize
                      name="description"
                      aria-label="empty textarea"
                      placeholder='Description'
                      value={formValues.description}
                      onChange={handleInputChange} />
                  </FormControl>
                </Box>

                <Box sx={{ marginTop: '1em' }} >
                  <FormLabel>Choose Area Type</FormLabel>
                  <Box sx={{ display: "flex" }} className="left-checkbox">
                    <Box sx={{ marginTop: '10px', marginRight: "8px" }}>
                      <input type="checkbox" name="loadingPoint" checked={formValues.attributes.loadingPoint} onChange={handleAttributesChange} />
                      <FormLabel>Loading Point</FormLabel>
                    </Box>
                    <Box sx={{ marginTop: '10px', marginRight: "8px" }}>
                      <input type="checkbox" name="unloadingPoint" checked={formValues.attributes.unloadingPoint} onChange={handleAttributesChange} />
                      <FormLabel>Unloading point</FormLabel>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ marginTop: '1em' }}>
                  <FormControl>
                    <FormLabel>Max Speed(km)</FormLabel>
                    <FormField
                      name="speedLimit"
                      placeholder="Enter Value"
                      type="text"
                      value={formValues.attributes.speedLimit}
                      onChange={handleAttributesChange}
                    />
                    <div style={errorstyle}>
                      {formErrors.speedLimit}
                    </div>
                  </FormControl>
                </Box>

                <Box sx={{ marginTop: '1em' }}>
                  <Button type="submit" className='show-btn'>
                    Update
                  </Button>
                </Box>

              </Box>




            </Card>


            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
                height: '500px',
                marginBottom: '25px',
                marginLeft: '10px',
                '& a': {
                  textDecoration: 'none',
                  color: 'inherit',
                },
              }}
            >
              {/* <MapView/> */}
              <>
                {/* <MapContainer
                  center={[21.35516, 81.28199]}
                  zoom={11}
                  scrollWheelZoom={false}
                  style={{ height: "100vh" }}
                >
                  <TileLayer
                    url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                    maxZoom={20}
                    subdomains={['mt1', 'mt2', 'mt3']}
                  />
                  {(formValues.geofencetype.length > 0) && <GeofenceMap drawData={formValues} setPosition={setPosition} callChild={coordValue} />}
                </MapContainer> */}

                <GeofenceMap drawData={formValues} setPosition={setPosition} circlePoints={coordValue} buttonChange={buttonChange} drawmode={drawmode} setFormValues={setFormValues} setDrawMode={setDrawMode} />
              </>
            </Card>

          </Box>


        </Box>
      </form>
      {/* </Fade> */}
    </Modal>
    <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
  </>

  );

}