import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Avatar, Button, Card, CardActions } from "@mui/material";
import {
  Link,
  required,
  minLength,
  maxLength,
  email,
  Form,
  TextInput,
  DateInput,
} from "react-admin";
import Logo from "../../images/logo.svg";
import TransportLogo from "../../images/transport_department_logo.jpeg";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ArrowIcon from "@mui/icons-material/ArrowCircleLeft";
import { useNavigate } from "react-router-dom";
import "./header.css";
import { newRegister, sendNewRegisterOTP } from "../../providers/dataProvider";
import SendNotification from "../../utils/sendNotification";
import EyeIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";

let passwordRegex =
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";

  const phoneRegex = "^\\d{10}$";

const checkPassword = (value) => {
  if (!value) return "Password required";
  if (!value.match(passwordRegex))
    return "uppercase,lowercase,special character and number required";
  return undefined;
 
};

const checkPhone = (value) => {
  if (!value) return "Phone Number required";
  if (!value.match(phoneRegex))
    return "Require 10 digit number!";
  return undefined;
};

const checkConfirmPassword = (value, allValues) => {
  if (value !== allValues?.password) return "Passwords do not match";
  return undefined;
};


const checkLastName = (value) => {
  if (!value) return "Last Name required";
  // let res = /^[a-zA-Z]+$/.test(value);
  // if(!res) return "Must contain only letters!";
  let res = /^[A-Za-z0-9-_ ]+$/.test(value);
  if(!res) return "Special characters not allowed!";
  return undefined;
};


const checkFirstName = (value) => {
  if (!value) return "First Name required";
  // let res = /^[a-zA-Z]+$/.test(value);
  // if(!res) return "Must contain only letters!";
  let res = /^[A-Za-z0-9-_ ]+$/.test(value);
  if(!res) return "Special characters not allowed!";
  return undefined;
};

const errorstyle = {
  color: "#d32f2f",
  fontSize: "12px",
  display: "block"
};

const validateFirstName = [required(), minLength(2), maxLength(15),checkFirstName];
const validateLastName = [required(),checkLastName];
const validatePhone = [required(), checkPhone];
const validatePassword = [required(), minLength(10), checkPassword];
const validateConfirmPassword = [required(), checkConfirmPassword];
const validateEmail = [required(), email()];

const Signup = () => {
  const [notifyData, setNotifyData] = useState({});
  const [newUserRegister, setNewUserRegister] = useState({});
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState({});
  const [emailError, setEmailError] = useState('');
  const [phonenoError, setPhonenoError] = useState('');
  const [pwdError, setPwdError] = useState('');
  const [confpwdError, setConfPwdError] = useState('')
  const [passwordValues, setPasswordValues] = useState('');
  const [firstNameError, setfirstNameError] = useState('')
  const [lastNameError, setlastNameError] = useState('')


  const togglePassword = () => {
    setShowPassword(!showPassword);
  };


  const toggleConfPassword = () => {
    setShowConfPassword(!showConfPassword);
  };

  const validPassword = (e) => {
    var pass = e.target.value;
      const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15})");
    if(strongRegex.test(pass)) {
      setPwdError('');
      setPasswordValues(pass);
      }
    else{
      if (pass.length == 0){
        setPwdError('');
      }
      else setPwdError('Must contain at least one number and one uppercase and lowercase letter, and at least 8 to 15 characters')
    }
  }

  const validConfPassword = (e) => {
    if (passwordValues !== e.target.value && e.target.value.length > 0){
    setConfPwdError('Passwords do not match');
    }
    else{
      setConfPwdError('');
    }
  }

  const handleSubmit = async (FormValues) => {
    delete FormValues["confirmPassword"];
    await sendNewRegisterOTP(FormValues, setNewUserRegister)
    // await newRegister(FormValues, setRegisterSuccess);
  };

  const validEmail = (e) => {
    var email = e.target.value
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(email != "" && regex.test(email) === false){
      setEmailError('Enter valid Email!')
    } else {
      setEmailError('')
    }
  }
  const validNames = (e) => {
    let regex = /^[A-Za-z]+$/  
    if (e.target.name == "firstName" || e.target.name == "lastName") {
    if (e.target.value == "" || regex.test(e.target.value)) {
      setfirstNameError('');
      setlastNameError('');
    }
    else{
      if(e.target.name == "firstName"){
        setfirstNameError('Enter valid first name!')
      } else {
        setlastNameError('Enter valid last name')
      }
    }
  }
}
const validPhoneno = (e) => {
  var phoneno = e.target.value
  const regex = /^\d{10}$/;
  if(phoneno != "" && regex.test(phoneno) === false){
    setPhonenoError('Enter valid Phone number!')
  } else {
    setPhonenoError('')
  }
}
  // useEffect(() => {
  //   if (registerSuccess?.status == "SUCCESS"){
  //     navigate("/login", { state: "REGISTER SUCCESS" });
  //   }
  //   if (registerSuccess?.status == "ERROR"){
  //     setNotifyData({
  //       open: true,
  //       message: registerSuccess?.message,
  //       severity: "warning",
  //       duration: 4000,
  //     });
  //   }
  // }, [registerSuccess]);

  useEffect(() => {
    if(newUserRegister?.status == "SUCCESS"){   
      navigate("/otp-verfication-register", { state: {
        type: "register",
        data: newUserRegister
      }});        
    }
  }, [newUserRegister])
  
  

  const handleRedirect = () => {
    navigate("/login");
  };



  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "flex-start",
            flexGrow: 1,
          }}
          className="login-sec newreg"
        >
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Card
                sx={{ height: "60vh", marginTop: "6em", boxShadow: "none" }}
                className="bg-image"
              ></Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                sx={{
                  minWidth: 382,
                  padding: "18px 42px !important",
                }}
              >
                <Box
                  sx={{
                    margin: "1em",
                    justifyContent: "center",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={1}>
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          right: "45px",
                        }}
                        className="backtopage"
                        onClick={handleRedirect}
                      >
                        <ArrowIcon className="arrow-icon" />
                        <Typography
                          color="textSecondary"
                          variant="h"
                          component="h4"
                        >
                          Back
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={10} className="logo-box">
                      <img src={TransportLogo} />
                      <Typography variant="h5" component="h2" align="center">
                        Vehicle Tracking System
                      </Typography>
                      <Typography variant="p" component="p" align="center">
                        Transport Department, Chhattisgarh
                      </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Box>
                <Box sx={{ padding: "0" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box sx={{ marginTop: "1em", paddingTop: "10px" }}>
                        <TextField
                          validate={validateFirstName}
                          placeholder="Enter"
                          source="firstName"
                          label="First Name"
                          name="firstName"
                          fullWidth
                          autoComplete="off"
                          onChange={validNames}
                          className="name-field"
                        />
                        {firstNameError === '' ? null :
                        <span style={errorstyle}>{firstNameError}</span>
                        }
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ marginTop: "1em", paddingTop: "10px" }}>
                        <TextField
                          validate={validateLastName}
                          placeholder="Enter"
                          source="lastName"
                          name="lastName"
                          label="Last Name"
                          fullWidth
                          autoComplete="off"
                          onChange={validNames}
                          className="name-field"
                        />
                        {lastNameError === '' ? null :
                        <span style={errorstyle}>{lastNameError}</span>
                        }
                      </Box>
                    </Grid>
                    <Grid item xs={12} className="pt-0">
                      <Box sx={{ marginTop: "15px" }}>
                        <TextInput
                          label="Email"
                          source="email"
                          placeholder="Enter"
                          validate={validateEmail}
                          fullWidth
                          autoComplete="off"
                          onChange={validEmail}
                          inputProps={{ maxLength: 50 }}
                        />
                        {emailError === '' ? null :
                        <span style={errorstyle}>{emailError}</span>
                        }
                      </Box>
                      <Box sx={{ marginTop: "15px" }}>
                        <TextInput
                          validate={validatePhone}
                          placeholder="Enter"
                          source="phone"
                          fullWidth
                          autoComplete="off"
                          onChange={validPhoneno}
                        />
                        {phonenoError === '' ? null :
                        <span style={errorstyle}>{phonenoError}</span>
                        }
                      </Box>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <Box sx={{ marginTop: "15px" }}>
                        <TextInput
                          validate={validatePassword}
                          placeholder="Enter"
                          source="password"
                          type={showPassword ? "text" : "password"}
                          fullWidth
                          autoComplete="off"
                          onChange={validPassword}
                          inputProps={{ maxLength: 15 }}
                        />
                        {showPassword ? (
                          <EyeIcon
                            onClick={togglePassword}
                            sx={{
                              position: "absolute",
                              right: "calc(100% - 74.5%)",
                              marginTop: "19px",
                              fontSize: "18px",
                            }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={togglePassword}
                            sx={{
                              position: "absolute",
                              right: "calc(100% - 74.5%)",
                              marginTop: "19px",
                              fontSize: "18px",
                            }}
                          />
                        )}
                      </Box>
                      {pwdError === '' ? null :
                      <span style={errorstyle}>{pwdError}</span>
                      }
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <Box sx={{ marginTop: "15px" }} className="conf-pwd">
                        <TextInput
                          validate={validateConfirmPassword}
                          placeholder="Enter"
                          source="confirmPassword"
                          type={showConfPassword ? "text" : "password"}
                          fullWidth
                          autoComplete="off"
                          inputProps={{ maxLength: 15 }}
                          onChange={validConfPassword}
                        />
                        {showConfPassword ? (
                          <EyeIcon
                            onClick={toggleConfPassword}
                            sx={{
                              position: "absolute",
                              right: "calc(100% - 88%)",
                              marginTop: "19px",
                              fontSize: "18px",
                            }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={toggleConfPassword}
                            sx={{
                              position: "absolute",
                              right: "calc(100% - 88%)",
                              marginTop: "19px",
                              fontSize: "18px",
                            }}
                          />
                        )}
                      </Box>
                      {confpwdError === '' ? null :
                      <span style={errorstyle}>{confpwdError}</span>
                      }
                    </Grid>
                  </Grid>
                </Box>
                <CardActions sx={{ padding: "0" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Register
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Box>
      </Form>
      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>
  );
};

export default Signup;
