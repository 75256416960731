import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormLabel from "@mui/material/FormLabel";
import FormField from "@mui/material/TextField";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableRowsIcon from '@mui/icons-material/TableRowsOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DistanceIcon from '@mui/icons-material/RouteOutlined';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import PointTypeIcon from '@mui/icons-material/PinDropOutlined';
import vtsDataProvider from './../providers/dataProvider';
import { getData } from './../providers/dataProvider';
import ChangeLocation from '../images/change-location.png';

export default function EditRouteAccordian({ EditRouteId, legDetails, geofenceRemove, setlegDetails, setlegSubDetails, legSubDetails, handleChangeLocation,handleSelectLocation }) {
  const [expanded, setExpanded] = useState(false);
  const [editName, setEditName] = useState(
    {
      id: 0,
      controlchange: false
    });
  const [changeName, setChangeName] = useState();


  const iconstyle = {
    position: "absolute",
    right: "28px",
    top: "12px"
  }

  const editiconstyle = {
    position: "absolute",
    right: "46px",
    top: "12px"
  }
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleEditName = (id, value) => {
    setEditName({ id: id, controlchange: true });
    setChangeName(value);
  }

  const setName = (id) => {
    const copylegDetails = legDetails;
    copylegDetails[id].name = changeName;
    setlegDetails(copylegDetails)
    const copylegSubDetails = legSubDetails;
    copylegSubDetails[id].name = changeName;
    setlegSubDetails(copylegSubDetails)
    setEditName({ id: 0, controlchange: false });
  }

  const handleChangeName = (event) => {
    setChangeName(event.target.value)
  }


  return (
    <div>
      {
        legDetails.map((leg, index) => {
          return (
            <>
            <Accordion expanded={expanded === leg.userindex} onChange={handleChange(leg.userindex)} className="accordian-class">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <TableRowsIcon />
                <Typography sx={{ flexShrink: 0 }} className="rounded-num">
                  {(index * 1) + 1}
                </Typography>
                <Typography variant="h" component="h4" sx={{ color: 'text.secondary' }}>
                  {(editName.controlchange === true && editName.id === index) ?
                    <input type="text" name="legName_" index value={changeName} onChange={handleChangeName}
                      onMouseOut={() => setName(index)}></input> : leg.name
                  }</Typography>
                {leg.from === "userentry" && <EditIcon style={editiconstyle} onClick={() => handleEditName(index, leg.name)} />}
                {/* {leg.from === "userentry" && EditRouteId === 0 && <DeleteIcon style={iconstyle} onClick={() => handlePointsRemove(leg.userindex)} />} */}
                <DeleteIcon style={iconstyle} onClick={() => geofenceRemove(leg.userindex)} />
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ borderLeft: '1.4px solid #252F40', paddingLeft: '8px' }}>
                  <div className='change-location'>
                  <Typography >
                    {leg.address}
                  </Typography>
                 {leg?.radius===200?<a onClick={()=>handleChangeLocation(leg)}><img src={ChangeLocation} /></a>
                  :<a onClick={()=>handleSelectLocation(leg)}><img src={ChangeLocation} /></a>}
                  </div>

                  <Box sx={{ marginTop: '1.3rem', flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <DistanceIcon />
                        <Typography variant="h6" component="h4">
                          Distance(Km)
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TimeIcon />
                        <Typography variant="h6" component="h4">
                          Planned Time
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormLabel>{
                          index === 0 ? 0 : leg?.distance
                          // leg?.subRoutePoints?.legs?.map((subroute,index)=>{
                          //   leg?.subRoutePoints?.legs?
                          // leg?.subRoutePoints?.legs[(index*1)-1]?.estDistanceFromOrigin :0
                          // })
                        }</FormLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <FormLabel>{leg.plandate}</FormLabel>
                      </Grid>
                      {/* <Grid item xs={6}>
                            <PointTypeIcon />
                        <Typography variant="h6" component="h4">
                            Point type
                        </Typography>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <FormLabel>12/09/22 02:11:58</FormLabel>
                        </Grid> */}
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            
        </>

          )
        }
        )
      }
    </div>
  );
}
