import * as React from "react";
import DollarIcon from "@mui/icons-material/AttachMoney";
import { useListController, useTranslate } from "react-admin";

import CardWithIcon from "./CardwithIcon";

const GpsRemoved = ({ overviewData }) => {

  const statusValues = overviewData ? overviewData[0] : null;
  const translate = useTranslate();


  
  return (
    <>
      <CardWithIcon
        to="/vehicles"
        title={translate(`Disabled Vehicles`)}
        subtitle={statusValues?.gps_removed}
        status="gps_removed"
        icon={DollarIcon}
        statusValues={statusValues}
      />
    </>
  );
};

export default GpsRemoved;
