import React, { useState } from 'react';
import { Card, Box, Typography } from "@mui/material";
import { useGetList } from "react-admin";
import LinearProgress from "@mui/material/LinearProgress";

const VehicleCount = () => {
  const { data: vehiclecountlist, isLoading } = useGetList("vehiclecount");
  return (
    <Card
      sx={{
        minHeight: 52,
        marginRight: "15px",
        marginBottom: "30px",
        position: "relative",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
      <Box className="card-title trip">
        <Typography color="textSecondary" component="h2" >
          Vehicle Count Status
        </Typography>
        <Box className="sel-group" alwaysOn>
          {/* <ReactSelect
            name="Constituency"
            source="Constituency"
            options={constituency.map(name => ({ value: name, label: name }))}
            placeholder="Select Constituency"
            onChange={handleConstituencyChange}
            isClearable={true}
          /> */}
        </Box>
        {/* <Button className="show-btn" sx={{ float: "right !important", width: "64px !important" }} href={`${urls.EXPORT_ROUTE_ASSIGN}?excel=true`} download>Export</Button> */}
      </Box>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <Box style={{ margin: "16px", maxHeight: "230px", overflowY: "auto" }}>
          <table className="const-table">
            <thead>
              <tr>
                <th>Vehicle Type Count</th>
                <th>Prabhari Number Count</th>
                <th>Alt Phone Count</th>
                <th>Constituency Count</th>
                <th>Phone Count</th>
                <th>Vehicle Category Count</th>
                <th>Prabhari Name Count</th>
              </tr>
            </thead>
            {vehiclecountlist.length > 0 ? (
              <tbody>{vehiclecountlist.map((item, index) => {
                return (
                  <tr>
                    <td>{item?.countVehicleType?.count}</td>
                    <td>{item?.countPrabhariNumber?.count}</td>
                    <td>{item?.countAltPhone?.count}</td>
                    <td>{item?.countConstituency?.count}</td>
                    <td>{item?.countPhone?.count}</td>
                    <td>{item?.countVehicleCategory?.count}</td>
                    <td>{item?.countPrabhariName?.count}</td>
                  </tr>
                )
              })}</tbody>
            ) : (
              <span>No results</span>
            )}
          </table>
        </Box>
      )}
    </Card>
  );
};

export default VehicleCount;
