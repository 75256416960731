import React, { useState, useEffect, useRef, useContext } from "react";
import {
  List,
  TextField,
  DateTimeInput,
  Datagrid,
  SelectInput,
  TextInput,
  Pagination,
  ListToolbar,
  useGetList,
  ListContextProvider,
  Button,
  useListController,
  useResourceContext,
  RefreshButton,
  useRefresh,
  useRecordContext,
  LinearProgress,
} from "react-admin";
import SearchIcon from "@mui/icons-material/Search";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { Grid, Card } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./overview.css";
import FormControl from "@mui/material/FormControl";
import PanicStatus from "./panicStatus";
import urls from "../../constants";
import httpClient from "../../utils/httpclient";
import { stringify } from "query-string";
import { now, oneDayAgo, oneMonthAgo } from "../../utils/time";
import {
  StatisticContext,
  StatisticContextProvider,
} from "../../utils/statisticContext";
import { TitleContext } from "../../utils/TitleContext";
import { fetchGroupList } from "../../providers/dataProvider";
import { GroupContext } from "../../utils/GroupContext";
import useTabPermissions from "../../hooks/useTabPermissions";
import CardRoute from "./CardRoute";
import CardAssignedRoute from "./CardAssignedRoute";
import CardInstallation from "./CardInstallation";
import CardConstituency from "./CardConstituency";
import ConstituencyList from "./ConstituencyList";
import TripsSummary from "./TripsSummary";
import VehicleCount from "./VehicleCount";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", flexDirection: "row" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};
const Buttonstyle = {
  position: "absolute",
  background: "blue",
  color: "#fff",
  zIndex: 1,
  textTransform: "capitalize",
  width: "150px",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const defaultValues = {
  name: "",
  phone: "",
  email: "",
  password: "",
};

const position = [51.505, -0.09];

const TabPanel = (props) => {
  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && <Typography>{children}</Typography>}
    </React.Fragment>
  );
};

export const ECDashboard = () => {
  const [value, setValue] = React.useState(0);
  const { setTitle } = React.useContext(TitleContext);
  var tot = 0;
  const perPage = 10;
  const [filter, setFilter] = useState("");
  const { groupList } = React.useContext(GroupContext);
  const [selectDate, setSelectDate] = useState([]);
  const [groupdrop, setgroupdrop] = useState([]);
  const datePickerRef = useRef(null);
  const [groupId, setGroupId] = useState();

  var date = new Date();
  var formatedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  var StaringDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  var placeholderDate = StaringDate + " - " + formatedDate;

  let todate = new Date();
  let prvDate = new Date(
    todate.getFullYear(),
    todate.getMonth() - 1,
    todate.getDate()
  );
  const [dateRange, setDateRange] = React.useState([prvDate, todate]);
  const [startDate, endDate] = dateRange;

  const refresh = useRefresh();

  const fetchData = () => {
    refresh();
  };

  useEffect(() => {
    setTitle("Dashboard");
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, []);

  const tabView = useTabPermissions("dashboard");

  const { data: overview } = useGetList("overview");
  const { data: ecdashboard } = useGetList("ecdashboard")
  const { data: ecconstituency, isLoading } = useGetList("ecconstituency")
  const { data: constituency } = useGetList("constituency")
  let overviewData = overview;

  if (overview?.length > 0) tot = overview[0]["total_devices"];

  const { statistic, setStatistic } = useContext(StatisticContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sort = { field: "id", order: "ASC" };

  useEffect(() => {
    var queryvalue = document.querySelectorAll(".list-content");
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const fetchGroupData = async () => {
    const res = await fetchGroupList("groupsdata");
    const list = formatDevices(res);
    setgroupdrop(list);
  };

  useEffect(() => {
    fetchGroupData();
  }, []);

  const formatDevices = (list) => {
    var te = list?.map((data) => ({
      // label: (data?.id).toString() + " - " + data?.name.toString(),
      value: data?.id,
      label: data?.name,
    }));
    return te;
  };

  const handlegroupChange = (selectedOption) => {
    setGroupId(selectedOption);
  };

  const handleUpdateDate = (update) => {
    setDateRange(update);
  };

  const handleDatePicker = () => {
    const dp = datePickerRef.current;
    dp.setOpen(true);
  };

  useEffect(() => {
    dateRange[0] && dateRange[1] && setSelectDate(dateRange);
  }, [dateRange]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }} className="dashboard">
      <Tabs value={value} onChange={handleChange} className="tab-top">
        {tabView && tabView?.viewOverall && <Tab label="Overall" />}
      </Tabs>

      <TabPanel value={value} index={0}>
        {tabView && tabView?.readOverall && (
          <Box className="tab-content">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4}>
                    <CardRoute ecdashboard={ecdashboard} />
                    <CardAssignedRoute ecdashboard={ecdashboard} />
                    <CardInstallation ecdashboard={ecdashboard} />
                </Grid>
                <Grid item xs={8} paddingLeft={"0px !important"}>
                    <CardConstituency ecdashboard={ecconstituency} constituencyOption={constituency?.[0]} />
                    <ConstituencyList ecdashboard={ecconstituency} constituencyOption={constituency?.[0]} isLoading={isLoading} />
                </Grid>
                <Grid item xs={12}>
                    <TripsSummary ecdashboard={ecconstituency} constituencyOption={constituency?.[0]} isLoading={isLoading} />
                </Grid>
                <Grid item xs={12}>
                    <VehicleCount  />
                </Grid>
            </Grid>
          </Box>
        )}
      </TabPanel>
    </Box>
  );
};
