import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material';
import { TextField, DateField, useTranslate, useListController, Show, SimpleShowLayout, useNotify, useRefresh, useGetList, Confirm, useRecordContext, useDelete, Datagrid, ListContextProvider, TextInput, Pagination, ListToolbar, TopToolbar, Button } from "react-admin";
import AddButton from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddAlarm from '../components/AddAlarm';
import EditAlarm from '../components/EditAlarm';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ViewIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SendNotification from "../utils/sendNotification";
import vtsDataProvider from './../providers/dataProvider'
import { Box, LinearProgress } from '@mui/material';
import VTSListToolbar from '../components/VTSListToolbar';
import moment from "moment";
import useMediaQuery from '@mui/material/useMediaQuery';

const Buttonstyle = {
  position: 'absolute',
  background: '#4646F2',
  color: '#fff',
  zIndex: 1,
  textTransform: 'capitalize',
  width: '115px',
  border: '1px solid #4646F2'
}
export default function AlarmConfigurationList({ createper, updateper, deleteper }) {
  const [notifyData, setNotifyData] = useState({});
  const [Alarmopen, setAlarmopen] = React.useState(false);
  const [EditAlarmopen, setEditAlarmopen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const refresh = useRefresh();
  const [EditAlarmId, setEditAlarmId] = useState(0);
  const [isDelete, setDeleteId] = useState(0)
  const notify = useNotify();
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    deleteId: null,
  });

  const translate = useTranslate();
  const handleAlarmopen = () => setAlarmopen(true);
  const handleAlarmClose = () => setAlarmopen(false);

  const handleEditAlarmopen = () => setEditAlarmopen(true);
  const handleEditAlarmClose = () => setEditAlarmopen(false);

  useEffect(() => { if (EditAlarmopen === false) listContext.refetch(); }, [EditAlarmopen])
  useEffect(() => { if (Alarmopen === false) listContext.refetch(); }, [Alarmopen])

  const [filter, setFilter] = useState('');

  const perPage = 10;
  const sort = { field: 'createdon', order: 'DESC' };
  const { data, isLoading, page, setPage, setPerPage, ...listContext } = useListController({
    resource: 'alarm'
  });

  const totalconfig = (typeof (listContext?.total) === "undefined") ? 0 : listContext?.total?.toString()

  const filters = [<TextInput label="Search" defaultValue="" source="q" size="small" alwaysOn />];
  const filterValues = { q: filter };
  const setFilters = filters => setFilter(filters.q);

  useEffect(() => {
    var queryvalue = document.querySelectorAll('.list-content');
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const matches = useMediaQuery('(min-width:1800px)');

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };

  const PanicActions = () => (
    <TopToolbar className='Alarm-toolbar'>
      {createper && (<><AddButton className="Addentity addalarm" startIcon={<AddIcon />} sx={Buttonstyle} onClick={handleAlarmopen}>Add Alarm</AddButton></>)}

    </TopToolbar>
  );

  const handleDeleteClick = (event, deleteId) => {
    setDeleteDialog({
      open: true,
      deleteId: deleteId
    });
  };

  const handleConfirmDelete = () => {
    if (deleteDialog.deleteId !== null) {
      vtsDataProvider.delete('alarm', { id: deleteDialog.deleteId }, setNotifyData, "Alarm Deleted Succesfully", "Failed to Delete");
      handleConfirmClose();
    }

  };


  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const handleConfirmClose = () => {
    setDeleteDialog({
      open: false,
      deleteId: null,
    });
    refresh();
  };


  const CustomDeleteButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <DeleteIcon
          onClick={(event) => handleDeleteClick(event, record[source])}
        />
      </div>
    );
  };

  const handleEditClick = (event, EditAlarmId) => {
    setEditAlarmId(EditAlarmId);
    handleEditAlarmopen();
  }

  const CustomEditButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <EditIcon
          onClick={(event) => handleEditClick(event, record[source])}
        />
      </div>
    );
  };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt = (record[source] !== null) ? moment(record[source]).format("DD MMM YYYY, HH:mm:ss") : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  if (isLoading) {
    return <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  } else
    return (<>
      <ListContextProvider debounce={8000} value={{ data, filterValues, page, setFilters, setPage, setPerPage, ...listContext }}>
        <VTSListToolbar filters={filters} actions={<PanicActions />} />
        {/* {(data === undefined || (Array.isArray(data) === true && data.length === 0)) ? <><div className='no-records'>Data Not Available</div></> :
          <> */}
          {matches == true ? (
              <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}  />
              
              ) : (
                  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
            )}
        
        <Box className="list-content">
          <Datagrid bulkActionButtons={false} empty={<CustomEmpty />}>
            {/* <TextField label="Id"  source="id" /> */}
            <TextField label="Alarm" source="type" />
            <TextField label="Notificator" source="notificators" />
            <DateSetter label="Created On" source="createdon" />
            <DateSetter label="Updated On" source="lastupdate" />
            <span label="Action" className="action-icons">
              {/* <ViewIcon><ShowButton /></ViewIcon> */}
              {updateper && (<><CustomEditButton source="id" /></>)}
              {deleteper && (<><CustomDeleteButton source="id" /></>)}
            </span>
          </Datagrid>
        </Box>
        {/* </>} */}
      </ListContextProvider>
      <AddAlarm Alarmopen={Alarmopen} handleAlarmClose={handleAlarmClose}></AddAlarm>
      <EditAlarm EditAlarmopen={EditAlarmopen} handleEditAlarmClose={handleEditAlarmClose} EditAlarmId={EditAlarmId}></EditAlarm>
      <Confirm
        isOpen={deleteDialog?.open}
        title="Delete Alarm"
        content="Are you sure you want to delete the alarm?"
        onConfirm={handleConfirmDelete}
        onClose={handleConfirmClose}
      />
      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />

    </>

    )
}


export const AlarmShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="Alarm Type" source="type" />
      <TextField label="Notificators" source="notificators" />
      <DateField label="Created On" showTime source="createdon" />
      <DateField label="Updated On" showTime source="lastupdate" />
    </SimpleShowLayout>
  </Show>
);