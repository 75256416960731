import React, { useEffect} from "react";
import { useGetOne, useListController } from "react-admin";


export default function ExportReportData({ viewReport, setExport }) {
  
  const { data, isLoading } = useGetOne("reportsexport", {
    id: 0,
    meta: { reportData: viewReport },
  });
  useEffect(() => {
    setExport(false);
  }, []);
  
  return <></>;
}
