import React, { useState } from 'react';
import { Card, Box, Typography } from "@mui/material";
import { useGetList, useRefresh, downloadCSV } from "react-admin";
import LinearProgress from "@mui/material/LinearProgress";
import { Button } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse-min';
import urls from "../../constants";

const TripsSummary = () => {
  const { data: consolidatedList, isLoading } = useGetList("tripsummary");

  // const exporter = posts => {
  //   const postsForExport = posts.map(post => {
  //     // add a field from an embedded resource
  //     return post;
  //   });
  //   const csv = convertToCSV({
  //     data: postsForExport,
  //     // select and order fields in the export
  //     fields: ['district', 'otherTrips', 'otherTripsFinished', 'otherTripsStarted', 'otherTripsYetToStart', 'pollingTrips', 'pollingTripsFinished', 'pollingTripsStarted', 'pollingTripsYetToStart', 'sectorTrips', 'sectorTripsFinished', 'sectorTripsStarted', 'sectorTripsYetToStart']
  //   });
  //   downloadCSV(csv, 'tripsummary'); // download as 'posts.csv` file
  // }


  return (
    <Card
      sx={{
        minHeight: 52,
        marginRight: "15px",
        marginBottom: "0",
        position: "relative",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
      <Box className="card-title trip">
        <Typography color="textSecondary" component="h2" >
          Trip Summary
        </Typography>
        <Box className="sel-group" alwaysOn>
          {/* <ReactSelect
            name="Constituency"
            source="Constituency"
            options={constituency.map(name => ({ value: name, label: name }))}
            placeholder="Select Constituency"
            onChange={handleConstituencyChange}
            isClearable={true}
          /> */}
        </Box>
        <Button className="show-btn" sx={{ float: "right !important", width: "64px !important" }} href={`${urls.EXPORT_ROUTE_ASSIGN}?excel=true`} download>Export</Button>
      </Box>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <Box style={{ margin: "16px", maxHeight: "230px", overflowY: "auto" }}>
          <table className="const-table">
            <thead>
              <tr>
                <th>District</th>
                <th>Other Trips</th>
                <th>Other Trips Finished</th>
                <th>Other Trips Started</th>
                <th>Other Trips Yet To Start</th>
                <th>Polling Trips</th>
                <th>Polling Trips Finished</th>
                <th>Polling Trips Started</th>
                <th>Polling Trips Yet To Start</th>
                <th>Sector Trips</th>
                <th>Sector Trips Finished</th>
                <th>Sector Trips Started</th>
                <th>Sector Trips Yet To Start</th>
              </tr>
            </thead>
            {consolidatedList.length > 0 ? (
              <tbody>{consolidatedList.map((item, index) => {
                return (
                  <tr>
                    <td>{item?.district}</td>
                    <td>{item?.otherTrips}</td>
                    <td>{item?.otherTripsFinished}</td>
                    <td>{item?.otherTripsStarted}</td>
                    <td>{item?.otherTripsYetToStart}</td>
                    <td>{item?.pollingTrips}</td>
                    <td>{item?.pollingTripsFinished}</td>
                    <td>{item?.pollingTripsStarted}</td>
                    <td>{item?.pollingTripsYetToStart}</td>
                    <td>{item?.sectorTrips}</td>
                    <td>{item?.sectorTripsFinished}</td>
                    <td>{item?.sectorTripsStarted}</td>
                    <td>{item?.sectorTripsYetToStart}</td>
                  </tr>
                )
              })}</tbody>
            ) : (
              <span>No results</span>
            )}
          </table>
        </Box>
      )}
    </Card>
  );
};

export default TripsSummary;
