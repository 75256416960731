import React, { useState, useEffect } from "react";
import {
  Button,
  useListController,
  useRedirect,
  useGetOne,
  useGetList,
  useRefresh,
} from "react-admin";
import { Box, Typography } from "@mui/material";
import { Grid, Card } from "@mui/material";
import "../views/route/route.css";
import ArrowIcon from "@mui/icons-material/ArrowBackOutlined";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormField from "@mui/material/TextField";
import SelectedList from "./../components/Accordian";
import vtsDataProvider, {
  getRouteAddress,
  getRouteAddressCustom,
  getRoutelinePoints,
  getRoutelinePointsCustom,
  getVehicleLocation,
} from "./../providers/dataProvider";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import moment from "moment";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import CreateRouteMap from "./../components/CreateRouteMap";
import SendNotification from "../utils/sendNotification";
import { validateLatLong } from "../utils/validate";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


const errorstyle = {
  color: "red",
  fontSize: "12px",
};

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", flexDirection: "row" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, geofence, theme) {
  return {
    fontWeight:
      geofence.name.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const CreateRoute = ({
  setExpanded,
  RouteId,
  setRouteId,
  listContext,
}) => {
  const theme = useTheme();
  const defaultValues = {
    name: "",
    routetype: "",
    stoptypes: "",
    description: "",
    isRouteVerified: false,
  };
  const [notifyData, setNotifyData] = useState({});
  const [formValues, setFormValues] = useState(defaultValues);
  const [geofences, SetGeofences] = useState([]);
  const [stopType, SetStopType] = useState({});
  const [routeType, SetRouteType] = useState({});
  const [legDetails, setlegDetails] = useState([]);
  const [legSubDetails, setlegSubDetails] = useState([]);
  const [address, setResponse] = useState();
  const [RoutePoints, setRoutePoints] = useState([]);
  const [showlegPoints, setShowLegPoints] = useState([]);
  const [RouteSubmitDetails, setRouteSubmitDetails] = useState({});
  const [EditRouteId, setEditRouteId] = useState(0);
  const [EditRouteDetails, setEditRouteDetails] = useState({});
  const [SaveEditDetails, setSaveEditDetails] = useState({});
  const [formErrors, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isdeleteRoute, setdeleteRoute] = useState(false);
  const [legPoints, setlegPoints] = useState({
    name: formValues.name,
    points: [],
  });

  const [dropgeofences, setdropgeofences] = useState([]);
  const [geofencechange, setgeofencechange] = useState(null);
  const [editgeofencechange, setEditGeofencechange] = useState(null);
  const [geofencelist, setGeofence] = React.useState([]);
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [PointsList, setPointsList] = useState(
    {
      latitude: "",
      longitude: "",
      radius: "200",
      errors: "",
      name: "",
      randomid: Math.floor(Math.random() * 1001),
    }
  );
  const [roundTrip, setRoundTrip] = useState(false);
  const [editLeg, setEditLeg] = useState(null);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [editGeoError, setEditGeoError] = useState(null);

  const filterstopType = { filter: { type: "SelectStopType" } };
  const filterrouteType = { filter: { type: "SelectRouteType" } };

  const { data: geofencesdata } = useGetList("geofencesdrop");
  const { data: stopTypedata } = useGetList("stoptype", filterstopType);
  const { data: routeTypedata } = useGetList("stoptype", filterrouteType);

  const [changelocation, setChangeLocation] = React.useState(false);
  const [selectlocation, setSelectLocation] = React.useState(false);
  const handleSelectLocation = (item) => {
    setEditLeg(item)
    setSelectLocation(true);
    setEditGeofencechange(null);
  }
  const handleSelectLocationClose = () => {
    setEditLeg(null)
    setSelectLocation(false);
  }

  const handleChangeLocation = (item) => {
    setEditLeg(item)
    setChangeLocation(true);
  }
  const handleChangeLocationClose = () => {
    setEditLeg(null)
    setChangeLocation(false);
  }

  useEffect(() => {
    console.log("editLeg#1234", editLeg)
  }, [editLeg])

  useEffect(() => {
    setFormError({});
    setFormValues(defaultValues);
    SetGeofences([]);
    setlegDetails([]);
    setShowLegPoints([]);
    setEditRouteId(RouteId);
  }, []);

  useEffect(() => {
    const getRouteDrop = () => {
      if (stopTypedata) SetStopType(stopTypedata[0]);
      if (routeTypedata) SetRouteType(routeTypedata[0]);
      if (geofencesdata) SetGeofences(geofencesdata);
    };
    getRouteDrop();
  }, [geofencesdata, stopTypedata, routeTypedata]);

  useEffect(() => {
    if (geofences) {
      var value = geofences.filter(function (item) {
        let startpoint = item.area.indexOf("(");
        let geofencetype = item.area.substring(0, startpoint).trim();
        geofencetype = geofencetype.toLowerCase();

        return geofencetype === "circle";
      });
      const list = formatDevices(value);
      setdropgeofences(list);
    }
  }, [geofences]);

  const formatDevices = (list) => {
    var te = list?.map((data) => ({
      label: (data?.id).toString() + " - " + data?.name.toString(),
      value: data?.id,
      showlabel: data?.name,
      name: ""
    }));
    return te;
  };

  const checknull = (value) => {
    if (
      value === null ||
      typeof value === "undefined" ||
      value === NaN ||
      value === ""
    )
      return "";
    else return value;
  };

  const handlePointsChange = (e) => {
    const { name, value } = e.target;
    const list = { ...PointsList };
    list[name] = value;
    setPointsList(list);
  };

  const handleChangeRouteVerified = (e) => {
    const verified = e.target.checked
    setFormValues({
      ...formValues,
      isRouteVerified: verified,
    });
  }

  const getGeofenceDetails = async () => {
    var value = geofences.filter(function (item) {
      return item.id == geofencechange?.value;
    });
    if (value?.length > 0) {
      let area = value[0].area;
      let startpoint = area.indexOf("(");
      let endpoint = area.indexOf(")");
      let latlngs = area.substring(startpoint + 1, endpoint).trim();
      const radarray = latlngs.split(",");
      let radius = radarray[1].trim();
      const positionarray = radarray[0].split(" ");
      let lat = positionarray[0].trim();
      let lng = positionarray[1].trim();

      if (!validateLatLong(lat * 1, lng * 1)) {
        formErrors.routecount =
          value[0].name +
          " geofence is not contain valid latitude and longitude!";
        return;
      }
      else {
        formErrors.routecount = ""
      }

      setFormError(formErrors)

      const address = {};
      const record = {
        latitude: lat * 1,
        longitude: lng * 1,
      };
      let geoaddress = ""
      await getVehicleLocation(record, address)
        .then((res) => {
          if (address?.address === "" || address?.address === undefined) {
            geoaddress = address?.address
          }
        })
        .catch((error) => {
          geoaddress = ""
        });


      let pointsdetails = {
        name: value[0].name,
        latitude: lat * 1,
        longitude: lng * 1,
        radius: radius * 1,
        address: geoaddress,
        distance: 0,
        plandate: moment(new Date()).format("DD MMM YYYY hh:mm:ss"),
        from: "geofence",
        userindex: Math.floor(Math.random() * 1001),
      }
      GetLegDetails(pointsdetails)
      setgeofencechange(null)
    }
  }

  useEffect(() => {
    if (geofencechange?.value) getGeofenceDetails(geofencechange);

  }, [geofencechange]);


  const geofenceRemove = (geoId) => {
    setDisabledSubmit(true)

    if (legDetails && legDetails.length === 1) setlegDetails([]);

    var value = legDetails.filter(function (item) {
      return item.userindex !== geoId
    })
    setlegDetails(value);

    var value1 = legSubDetails.filter(function (item) {
      return item.userindex !== geoId
    })
    setlegSubDetails(value1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let regex = /^[A-Za-z0-9-_ ]+$/;
    if (name == "name") {
      if (value == "" || regex.test(value)) {
        setFormValues({
          ...formValues,
          [name]: checknull(value),
        });
      }
    } else {
      setFormValues({
        ...formValues,
        [name]: checknull(value),
      });
    }
  };

  const handlePointsAdd = async () => {
    const list = { ...PointsList }
    let pointaddress = ""
    let validate = true;
    if (list.latitude.trim() === "") {
      list.errors = "Please fill the latitude";
      validate = false;
    } else if (list.longitude.trim() === "") {
      list.errors = "Please fill the longitude";
      validate = false;
    } else if (list.radius.trim() === "") {
      list.errors = "Please fill the radius";
      validate = false;
    } else if (
      list.latitude.trim() !== "" &&
      list.longitude.trim() !== ""
    ) {
      if (!validateLatLong(list.latitude, list.longitude)) {
        list.errors = "This is not a valid latitude and longitude!";
        validate = false;
      } else {
        const address = {};
        const record = {
          latitude: list.latitude,
          longitude: list.longitude,
        };
        await getVehicleLocation(record, address)
          .then((res) => {
            if (address?.address === "" || address?.address === undefined) {
              pointaddress = ""
            } else {
              pointaddress = address?.address
            }
          })
          .catch((error) => {
            pointaddress = ""
          });
      }
    } else {
      list.errors = "";
    }

    setPointsList(list)
    const timer = setTimeout(() => {
      const replaceIndex = legSubDetails.findIndex(item => item.userindex === editLeg?.userindex && editLeg?.from === "userentry")
      if (validate === true) {
        setChangeLocation(false);
        let pointsdetails = {
          name: replaceIndex > -1 ? editLeg?.name : "userentry" + Math.floor(Math.random() * 1001),
          latitude: list.latitude * 1,
          longitude: list.longitude * 1,
          radius: list.radius * 1,
          address: pointaddress,
          distance: 0,
          plandate: moment(new Date()).format("DD MMM YYYY hh:mm:ss"),
          from: "userentry",
          userindex: replaceIndex > -1 ? editLeg?.userindex : Math.floor(Math.random() * 1001),
        }
        GetLegDetails(pointsdetails)
        setPointsList({
          latitude: "",
          longitude: "",
          radius: "200",
          errors: "",
        })
      }
    }, 2000);
  };

  const GetLegDetails = (params) => {
    setDisabledSubmit(true);
    let details = {
      name: params.name,
      latitude: params.latitude * 1,
      longitude: params.longitude * 1,
      radius: params.radius * 1,
      address: params.address,
      distance: 0,
      plandate: params.plandate,
      from: params.from,
      userindex: params.userindex,
      subRoutePoints: {},
    };
    let detailssub = {
      name: params.name,
      latitude: params.latitude * 1,
      longitude: params.longitude * 1,
      radius: params.radius * 1,
      userindex: params.userindex
    };
    const filter = { filter: { details } };
    console.log("editLeg", editLeg)
    const replaceIndex = legSubDetails.findIndex(item => item.userindex === editLeg?.userindex)
    console.log("replaceIndex", replaceIndex)
    if (replaceIndex > -1) {
      legSubDetails[replaceIndex] = detailssub
      legDetails[replaceIndex] = details
      // setlegSubDetails(legSubDetails)
      // setlegDetails(legDetails)
      setEditLeg(null)
    } else {
      setlegSubDetails([...legSubDetails, detailssub]);
      setlegDetails([...legDetails, details]);
    }
  }


  const validate = (values) => {
    const errors = {};
    if (!values.name) errors.name = "Route name is required!";
    if (!values.stoptypes) errors.stoptypes = "Select stop type";
    if (!values.routetype) errors.routetype = "Select route type!";

    if (legDetails.length < 2)
      errors.routecount = "Select or Enter two location!";
    return errors;
  };

  const handleCheckPlot = async (e) => {

    let subRoute = ""

    if (legSubDetails?.length > 0) {
      let subRoutePoints = {
        name: formValues.name,
        points: legSubDetails,
      };
      // await getRoutelinePoints("legdetails", subRoutePoints, legs, index);
      await getRoutelinePointsCustom(subRoutePoints).then(response => subRoute = response.json)


      const legDetailsCopy = [...legDetails]
      subRoute?.legs?.map((routes, index) => {
        const temp_index = legDetails.findIndex((leg, index1) => {
          return leg.name === routes.nameTo && index1 == routes?.routeIndex
        })
        legDetailsCopy[temp_index].distance = routes?.estDistanceFromOrigin
        legDetailsCopy[(legDetails?.length - 1)].subRoutePoints = subRoute
      })
      setlegDetails(legDetailsCopy)
      setDisabledSubmit(false)

    }

  }

  const handleCheck = (e) => {
    setDisabledSubmit(true);

    let legDetails_ = [...legDetails];
    let legSubDetails_ = [...legSubDetails];
    let route_reverse = legDetails.reverse()
    let route_sub_reverse = legSubDetails.reverse()
    let routearr = [];
    let routesubarr = [];
    route_reverse?.map((route, index) => {
      routearr.push({
        name: route.name,
        latitude: route.latitude * 1,
        longitude: route.longitude * 1,
        radius: route.radius * 1,
        address: route.address,
        distance: route?.distance,
        plandate: route.plandate,
        from: route.from,
        userindex: Math.floor(Math.random() * 1001),
        subRoutePoints: route.subRoutePoints,
      })
    })

    route_sub_reverse?.map((subroute, index) => {
      routesubarr.push({
        name: subroute.name,
        latitude: subroute.latitude * 1,
        longitude: subroute.longitude * 1,
        radius: subroute.radius * 1,
        userindex: routearr[index].userindex
      })
    })

    let routes_ = legDetails_.concat(routearr)
    let routessub_ = legSubDetails_.concat(routesubarr)
    // if(!e.target.checked) routes_ = Array.from(new Set(routes_.map(JSON.stringify))).map(JSON.parse)
    if (!e.target.checked) {
      const unique = routes_.filter((obj, index) => {
        return index === routes_.findIndex(o => obj.latitude === o.latitude && obj.longitude === o.longitude);
      });
      routes_ = unique

      const unique1 = routessub_.filter((obj, index) => {
        return index === routessub_.findIndex(o => obj.latitude === o.latitude && obj.longitude === o.longitude);
      });
      routessub_ = unique1
    }
    setlegDetails(routes_)
    setlegSubDetails(routessub_)
    setRoundTrip(e.target.checked)
  };

  // useEffect(()=>{
  //   console.log("legDetails",legDetails)
  // },[legDetails])

  // useEffect(()=>{
  //   console.log("legSubDetails",legSubDetails)
  // },[legSubDetails])

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (EditRouteId > 0) {
        setSaveEditDetails({
          id: EditRouteId,
          name: formValues.name,
          points: legSubDetails,
          stopType: formValues.stoptypes,
          routeType: formValues.routetype,
          description: formValues.description,
          verified: formValues.isRouteVerified,
        });
      } else {
        setRouteSubmitDetails({
          name: formValues.name,
          points: legSubDetails,
          stopType: formValues.stoptypes,
          routeType: formValues.routetype,
          description: formValues.description,
          verified: formValues.isRouteVerified,
        });
      }
    }
  }, [formErrors]);

  const handleRouteSubmit = (event) => {
    event.preventDefault();
    setFormError(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (
      RouteSubmitDetails.name !== "" &&
      typeof RouteSubmitDetails.name !== "undefined"
    ) {
      vtsDataProvider.create('route', RouteSubmitDetails, setNotifyData, "Route Created Successfully", "Failed to Create Route");
    }
  }, [RouteSubmitDetails]);

  useEffect(() => {
    if (
      SaveEditDetails.name !== "" &&
      typeof SaveEditDetails.name !== "undefined"
    ) {
      vtsDataProvider.update('route', SaveEditDetails, setNotifyData, "Route Updated Successfully", "Failed to Update Route");

    }
  }, [SaveEditDetails]);

  useEffect(() => {
    if (notifyData?.severity === "success") {
      setFormValues(defaultValues)
      setSaveEditDetails({})
      setFormError({});
      setIsSubmit(false);
      setRouteId(0);
      setEditRouteId(0);
      const timer = setTimeout(() => {
        setExpanded(false);
      }, 5000);
      //  refresh();
    }
  }, [notifyData]);

  const backtoRoute = () => {
    setEditRouteId(0);
    setFormValues(defaultValues);
    setFormError({});
    setRouteId(0);
    setIsSubmit(false);
    setExpanded(false);
  };

  const handleSelectAdd = () => {
    if (editgeofencechange) {
      setEditGeoError(null);
      setgeofencechange(editgeofencechange);
      setSelectLocation(false)
    }
    else {
      setEditGeoError("Please select the Location")
    }
  }

  const handleChangeAdd = () => {
    handlePointsAdd();
  }

  return (
    <>
      <div
        style={{ display: "flex" }}
        className="backtopage"
        onClick={() => {
          backtoRoute();
        }}
      >
        <ArrowIcon className="arrow-icon" />
        <Typography
          color="textSecondary"
          variant="h"
          component="h4"
          className="Card-Title"
        >
          Back to Route List
        </Typography>
      </div>
      <Box
        sx={{ display: "flex", flexDirection: "row", height: "100%" }}
        className="route-list-sec"
      >
        <Card
          sx={{
            minHeight: 52,
            minWidth: 330,
            display: "flex",
            width: "25%",
            height: "73vh",
            overflowY: "auto",
            paddingRight: "0px",
            flexDirection: "column",
            "& a": {
              textDecoration: "none",
              color: "inherit",
            },
          }}
          className="first-card"
        >
          <form onSubmit={handleRouteSubmit}>
            <Box textAlign="left" className="audit-left">
              <Typography
                color="textSecondary"
                variant="h"
                component="h4"
                className="Card-Title"
                sx={{
                  marginBottom: "15px",
                }}
              >
                {EditRouteId > 0 ? "Edit Route" : "Create new Route"}
              </Typography>
              <Box sx={{ marginTop: "0.5em" }} style={styles.flexColumn}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Route Name</FormLabel>
                  <FormField
                    name="name"
                    placeholder="Enter Name"
                    type="text"
                    value={formValues.name}
                    onChange={handleInputChange}
                  />
                  <div style={errorstyle}>{formErrors.name}</div>
                </FormControl>
              </Box>
              <Box sx={{ flexGrow: 1, marginTop: "0.5em" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormLabel>Stop Type</FormLabel>
                    <Select
                      sx={{ background: "#f0f0ff" }}
                      placeholder="Select"
                      name="stoptypes"
                      value={formValues?.stoptypes}
                      onChange={handleInputChange}
                    >
                      {stopType
                        ? stopType?.configurations
                          ? Object.values(stopType?.configurations).map(
                            (stop, index) => (
                              <MenuItem key={index} value={stop}>
                                {stop}
                              </MenuItem>
                            )
                          )
                          : ""
                        : ""}
                    </Select>
                    <div style={errorstyle}>{formErrors.stoptypes}</div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormLabel>Route Type</FormLabel>
                    <Select
                      sx={{ background: "#f0f0ff" }}
                      placeholder="Select"
                      name="routetype"
                      value={formValues?.routetype}
                      onChange={handleInputChange}
                    >
                      {routeType
                        ? routeType?.configurations
                          ? Object.values(routeType?.configurations).map(
                            (route, index) => (
                              <MenuItem key={index} value={route}>
                                {route}
                              </MenuItem>
                            )
                          )
                          : ""
                        : ""}
                    </Select>
                    <div style={errorstyle}>{formErrors.routetype}</div>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ marginTop: "0.5em" }}>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <TextareaAutosize
                    name="description"
                    aria-label="empty textarea"
                    placeholder="Write here..."
                    style={{
                      background: "#f0f0ff",
                      height: "40px",
                    }}
                    value={formValues.description}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Box>
              <hr />
              <Typography
                color="textSecondary"
                variant="h"
                component="h4"
                className="Card-Title"
                sx={{
                  marginBottom: "15px",
                }}
              >
                Enter Location
              </Typography>
              <Box sx={{ flexGrow: 1, marginTop: "0.5em" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={5}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormLabel>Latitude</FormLabel>
                    <FormField
                      name="latitude"
                      placeholder="Enter"
                      type="text"
                      value={PointsList.latitude}
                      onChange={(e) => handlePointsChange(e)}
                      disabled={roundTrip === true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormLabel>Longitude</FormLabel>
                    <FormField
                      name="longitude"
                      placeholder="Enter"
                      type="text"
                      value={PointsList.longitude}
                      onChange={(e) => handlePointsChange(e)}
                      disabled={roundTrip === true}
                    />
                  </Grid>

                  <div style={errorstyle}>{PointsList.errors}</div>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1, marginTop: "0.5em" }}>
                <Button className="show-btn add" onClick={handlePointsAdd} disabled={roundTrip === true}>
                  Add
                </Button>
              </Box>
              <Box sx={{ flexGrow: 1, marginTop: "0.5em" }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Box className="or-hr" sx={{ display: "flex" }}>
                      <hr />
                      <span> Or </span>
                      <hr />
                    </Box>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              </Box>
              <Box sx={{ marginTop: "0.5em" }}>
                <div style={errorstyle}>{formErrors.routecount}</div>
                <Typography
                  color="textSecondary"
                  variant="h"
                  component="h4"
                  className="Card-Title"
                  sx={{
                    marginBottom: "15px",
                  }}
                >
                  Select existing Stops
                </Typography>

                <FormControl>
                  <Stack spacing={3}>
                    <Autocomplete
                      // multiple
                      id="tags-standard"
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                          {option.showlabel}
                        </li>
                      )}
                      options={dropgeofences}
                      getOptionLabel={(option) => option.showlabel}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Stops"
                          placeholder="Select"
                        />
                      )}
                      value={geofencechange}
                      onChange={(event, newValue) => {
                        setgeofencechange(newValue);
                      }}
                      disabled={roundTrip === true}
                    />
                  </Stack>
                </FormControl>
              </Box>
              <br />
              <span style={{ marginLeft: "10px" }}>
                <input
                  type="checkbox"
                  name="roundTrip"
                  checked={roundTrip}
                  onChange={(e) => handleCheck(e)}
                  disabled={legDetails?.length < 2}
                />
                <label style={{ marginLeft: "10px" }}>Round Trip</label>
              </span>

              <Box sx={{ marginTop: "0.5em" }}>
                <SelectedList
                  EditRouteId={EditRouteId}
                  legSubDetails={legSubDetails}
                  setlegSubDetails={setlegSubDetails}
                  setlegDetails={setlegDetails}
                  legDetails={legDetails}
                  geofenceRemove={geofenceRemove}
                  handleChangeLocation={handleChangeLocation}
                  handleSelectLocation={handleSelectLocation}
                />
              </Box>
              <span style={{ marginLeft: "10px", }}>
                <Button type="button" style={{ marginTop: "0.5em" }} className="show-btn new" onClick={(e) => handleCheckPlot(e)} disabled={legDetails?.length < 2}>
                  Plot on Map
                </Button>

              </span>
              <div style={{ marginTop: "1.5em" }}>
                <input
                  type="checkbox"
                  name="routeverified"
                  checked={formValues?.isRouteVerified}
                  onChange={handleChangeRouteVerified}
                />
                <label style={{ marginLeft: "10px" }}>Route Verified</label>
              </div>
              <Box sx={{ marginTop: "1em" }}>
                <Button type="submit" className="show-btn" disabled={disabledSubmit}>
                  Submit
                </Button>
              </Box>
            </Box>
            <br />
            <br />
            <br />
          </form>
        </Card>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "73%",
            height: "73vh",
            marginBottom: "25px",
            marginLeft: "10px",
            "& a": {
              textDecoration: "none",
              color: "inherit",
            },
          }}
          className="first-card"
        >
          <CreateRouteMap legDetails={legDetails} />
        </Card>
      </Box>
      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
      <Modal
        open={changelocation}
        onClose={handleChangeLocationClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="AddRoleModal alarm-modal"
      >
        <Box sx={style} className="modal-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change Location
          </Typography>
          <span className="close-btn">
            <CloseIcon onClick={handleChangeLocationClose} />
          </span>
          <form>
            <Box sx={{ flexGrow: 1, marginTop: "0" }}>
              <Typography
                color="textSecondary"
                variant="h"
                component="h4"
                className="Card-Title"
                sx={{
                  marginBottom: "10px",
                }}
              >
                Enter Location
              </Typography>
              <Box sx={{ flexGrow: 1, marginTop: "0" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormLabel>Latitude</FormLabel>
                    <FormField
                      name="latitude"
                      placeholder="Enter"
                      type="text"
                      value={PointsList.latitude}
                      onChange={(e) => handlePointsChange(e)}
                      disabled={roundTrip === true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormLabel>Longitude</FormLabel>
                    <FormField
                      name="longitude"
                      placeholder="Enter"
                      type="text"
                      value={PointsList.longitude}
                      onChange={(e) => handlePointsChange(e)}
                      disabled={roundTrip === true}
                    />
                  </Grid>
                  <div style={errorstyle}>{PointsList.errors}</div>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1, marginTop: "1em" }} style={{ display: "flex" }}>
                <Button className="cancel-btn add" style={{ width: "48%", marginRight: "10px", color: "#4646F2 " }} onClick={() => { handleChangeLocationClose() }}>
                  Cancel
                </Button>
                <Button className="show-btn add" style={{ width: "48%" }} onClick={() => { handleChangeAdd() }} disabled={roundTrip === true}>
                  Add
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Modal>
      <Modal
        open={selectlocation}
        onClose={handleSelectLocationClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="AddRoleModal alarm-modal"
      >
        <Box sx={style} className="modal-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change Location
          </Typography>
          <span className="close-btn">
            <CloseIcon onClick={handleSelectLocationClose} />
          </span>
          <form>
            <Box sx={{ flexGrow: 1, marginTop: "0" }}>
              <Typography
                color="textSecondary"
                variant="h"
                component="h4"
                className="Card-Title"
                sx={{
                  marginBottom: "15px",
                }}
              >
                Select existing Stops
              </Typography>
              <Box sx={{ flexGrow: 1, marginTop: "0" }}>
                <div style={errorstyle}>{formErrors.routecount}</div>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormLabel>Stops</FormLabel>
                    <FormControl>
                      <Stack spacing={3}>
                        <Autocomplete
                          // multiple
                          id="tags-standard"
                          className="custom"
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          renderOption={(props, option) => (
                            <li {...props} key={option.value}>
                              {option.showlabel}
                            </li>
                          )}
                          options={dropgeofences}
                          getOptionLabel={(option) => option.showlabel}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                            />
                          )}
                          value={editgeofencechange}
                          onChange={(event, newValue) => {
                            setEditGeofencechange(newValue);
                          }}
                          disabled={roundTrip === true}
                        />
                      </Stack>
                    </FormControl>
                    <div style={errorstyle}>{editGeoError}</div>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1, marginTop: "1em" }} style={{ display: "flex" }}>
                <Button className="cancel-btn add" style={{ width: "48%", marginRight: "10px", color: "#4646F2 " }} onClick={() => handleSelectLocationClose()}>
                  Cancel
                </Button>
                <Button className="show-btn add" style={{ width: "48%" }} onClick={() => { handleSelectAdd() }}>
                  Add
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};
