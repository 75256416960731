import React, { useState, useEffect } from "react";
import { useRefresh } from 'react-admin';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import vtsDataProvider from './../providers/dataProvider';
import FormTextField from '@mui/material/TextField';
import SendNotification from "../utils/sendNotification";
import CloseIcon from '@mui/icons-material/HighlightOffOutlined';
import "../views/group/group.css";
import { RefreshButton } from "react-admin";

let date = new Date();

const defaultValues = {
  id: 0,
  name: "",
  attributes: {},
  createdon: date,
  groupId: 0
};

export const AddGroups = ({ Accountopen, handleGroupClose, listContext }) => {
  const [formValues, setFormValues] = useState(defaultValues);
  const [notifyData, setNotifyData] = useState({});

  const refresh = useRefresh();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleGroupSubmit = (event) => {
    formValues.createdon = new Date();
    vtsDataProvider.create('groupsdata', formValues, setNotifyData, "Group Created Successfully", "Failed to Create Group");
    setFormValues(defaultValues)
    handleGroupClose();
  }

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (<>
    <Modal
      open={Accountopen}
      onClose={handleGroupClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description" className="AddNewModal addgroup"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Add Group
        </Typography>
        <span className="close-btn"><CloseIcon onClick={handleGroupClose} /></span>
        <form onSubmit={handleGroupSubmit}>
          <Box sx={{ flexGrow: 1, marginTop: '2em' }}>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Box>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel>Group Name</FormLabel>
                    <FormTextField
                      name="name"
                      placeholder="Enter Group Name"
                      type="text"
                      value={formValues?.name}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={8}>
                <div style={{ marginTop: '2em' }} className="modal-footer">
                  <Button variant="contained" color="primary" type="submit">
                    Create
                  </Button>
                  <Button onClick={handleGroupClose} variant="contained" className="cancel-btn" color="primary" sx={{ marginRight: '1em' }}>
                    Cancel
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </Modal>
    <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
  </>
  );
}
