import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Select,
  Card,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { Button, useGetList, useListController } from "react-admin";
import { Datagrid, TextField, useRecordContext, List } from "react-admin";
import MapView from "./mapview";
import ReportType from "../views/reports/reportType";
import useReport from "../hooks/useReport";
import ListItemText from "@mui/material/ListItemText";
import MultiSelect from "react-select";
import { TitleContext } from "../utils/TitleContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { default as ReactSelect } from "react-select";
import { fetchEventTypeData } from "../providers/dataProvider";

export default function ReportsVehicle() {
  const [formValues, setFormValues] = useState({
    reporttype: "reportType",
    deviceId: null,
    groupId: null,
    from: null,
    to: null,
    columnNames: [],
    columnData: [],
    reporturl: "",
    selectedDevice: null,
    selectedGroup: null,
    selectedOptions: []
  });
  const [viewReport, setViewReport] = useState({
    data: {},
    expanded: false,
  });

  const [formError, setFormError] = useState({
    disabled: true,
  });

  const [reportTypes, setReportTypes] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [groupVehicle, setGroupVehicle] = useState(null);
  const { setTitle } = React.useContext(TitleContext);
  const [groupData, setGroupData] = useState([]);
  const [eventTypeRecords, setEventTypeRecords] = useState([]);

  let todate = new Date();
  let prvDate = new Date(
    todate.getFullYear(),
    todate.getMonth() - 1,
    todate.getDate()
  );

  const [dateRange, setDateRange] = React.useState([prvDate, todate]);
  const [startDate, endDate] = dateRange;

  const [fromDate, setFromDate] = useState(prvDate);
  const [toDate, setToDate] = useState(new Date());

  const fromDatePickerRef = React.useRef(null);
  const toDatePickerRef = React.useRef(null);

  const reports = [
    {
      label: "Vehicle",
      value: "Vehicle",
    },
    {
      label: "Group",
      value: "Group",
    },
  ];

  const handleFromDatePicker = () => {
    const dp = fromDatePickerRef.current;
    dp.setOpen(true);
  };

  const handleToDatePicker = () => {
    const dpt = toDatePickerRef.current;
    dpt.setOpen(true);
  };

  const { data: reportTypeData } = useListController({
    resource: "reporttypes",
    filter: { type: formValues?.reporttype },
  });

  const { data: vehicledata } = useGetList("vehicledata");

  const { data: groupsdata } = useGetList("groupsdata");

  useEffect(() => {
    setTitle("Reports");
  }, []);

  useEffect(() => {
    if (!reportTypeData) return;
    if (formValues?.reporttype === "reportType") setReportTypes(reportTypeData);
    else getColumnNames(reportTypeData);
  }, [reportTypeData, formValues?.reporttype]);



  const formatEventType = (list) => {
    var te = list?.map((data) => ({
      label: data?.type,
      value: data?.id,
    }));
    return te;
  };



  useEffect(() => {
    const getEventTypeData = async () => {
      const res = await fetchEventTypeData("alarmtype");
      if (!res) return;
      const dt = formatEventType(res);
      setEventTypeRecords(dt)
    };
    if (formValues?.reporttype == "event") getEventTypeData();
  }, [formValues?.reporttype]);



  const formatGroup = (list) => {
    var te = list?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
    return te;
  };

  useEffect(() => {
    if (!vehicledata) return;
    const dta = formatVehicle(vehicledata);
    setVehicleData(dta);
  }, [vehicledata]);

  useEffect(() => {
    if (!groupsdata) return;
    const dta = formatGroup(groupsdata);
    setGroupData(dta);
  }, [groupsdata]);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleReport = (event) => {
    setGroupVehicle(event.target.value);
  };

  const handleDeviceChange = (e) => {
    setFormValues({
      ...formValues,
      selectedDevice: e,
    });
  };


  const handleGroupChange = (e) => {
    setFormValues({
      ...formValues,
      selectedGroup: e,
    });
  };



  const handleEventTypeChange = (e) => {
    setFormValues({
      ...formValues,
      selectedOptions: e,
    });
  };



  const handleSelect = (e) => {
    setColumnNames(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = { ...formValues };
    let formatFromDate = moment(fromDate).format("YYYY-MM-DD hh:mm");
    let formatToDate = moment(toDate).format("YYYY-MM-DD hh:mm");
    // let formatFromDate = moment(dateRange[0]).format("YYYY-MM-DD hh:mm");
    // let formatToDate = moment(dateRange[1]).format("YYYY-MM-DD hh:mm");
    if (
      formData["reporttype"] === "event" ||
      formData["reporttype"] === "stop" ||
      formData["reporttype"] === "trip"
    ) {
      formData["reporturl"] = formData["reporttype"].concat("s");
    } else formData["reporturl"] = formData["reporttype"];
    formData["from"] = moment.utc(formatFromDate).format();
    formData["to"] = moment.utc(formatToDate).format();
    formData["columnNames"] = columnNames;
    formData["columnData"] = columnData;
    if(groupVehicle == 'Vehicle') formData["deviceId"] = formValues?.selectedDevice?.value
    if(groupVehicle == 'Group') formData["groupId"] = formValues?.selectedGroup?.value

   
    setViewReport({
      data: formData,
      expanded: true,
    });
  };

  const getColumnNames = (colData) => {
    clearColumns();
    const cols = colData?.filter((obj) => obj?.fieldName != "id");
    const colNames = cols.map((data) => ({
      label: data.headerName,
      value: data.fieldName,
    }));
    setColumnData(colNames);
  };

  const clearColumns = () => {
    if (columnNames?.length > 0) setColumnNames([]);
  };


  useEffect(() => {
    const fe = {...formError}
    fe['disabled'] = true

    if(groupVehicle == 'Vehicle'){
      if(formValues?.selectedDevice == null) fe['disabled'] = true
      else fe['disabled'] = false
    }
    if(groupVehicle == 'Group'){
      if(formValues?.selectedGroup == null) fe['disabled'] = true
      else fe['disabled'] = false
    }
    setFormError(fe)
  }, [groupVehicle, formValues])

  
  
  const PostPanel = () => {
    const record = useRecordContext();
    return <div dangerouslySetInnerHTML={{ __html: Tablebody }} />;
  };
  const Tablebody = (
    <>
      <table id="example" class="display" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>Office</th>
            <th>Age</th>
            <th>Start date</th>
            <th>Salary.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Tiger Nixon</td>
            <td>System Architect</td>
            <td>Edinburgh</td>
            <td>61</td>
            <td>2011/04/25</td>
            <td>$320,800</td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const formatVehicle = (list) => {
    var te = list?.map((data) => ({
      label: data?.name,
      value: data?.device_id,
    }));
    return te;
  };

  // var date = new Date();
  // var formatedDate = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`
  // var StaringDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
  // var placeholderDate = StaringDate+" - "+formatedDate;

  const handleFromDate = (dates) => {
    setFromDate(dates);
  };

  const handleToDate = (dates) => {
    setToDate(dates);
  };

  

  
  useEffect(() => {
    if(formError?.disabled && groupVehicle != null) {
      setViewReport({
      data: [],
      expanded: true,
    });
   }
    if((formError?.disabled && groupVehicle == 'Group') || (formError?.disabled && groupVehicle == 'Vehicle')){
      setFormValues({
        ...formValues,
        reporttype: "reportType",
        selectedOptions: [],
      })
    }
  }, [formError?.disabled])




  return (
    <>
      <Box>
        {/* <Typography
        variant="h5"
        component="h2"
        align="left"
        sx={{ fontSize: "14px", marginTop: "20px", fontWeight: "600",position:"relative",left:"280px" }}
      >
        Total count{" "}
        <Button
          label="2"
          sx={{
            background: "none",
            border: "1px solid blue",
            color: "black",
            fontWeight: "600",
            borderRadius: "10px",
            padding: "2px",
            marginLeft: "15px",
            minWidth: "48px",
            marginBottom: "10px",
          }}
        />
      </Typography> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          marginTop: "17px",
        }}
        className="rep-vehicle-tab"
      >
        <Card
          sx={{
            minHeight: 62,
            minWidth: 350,
            display: "flex",
            width: "25%",
            height: "78vh",
            marginBottom: "20px",
            overflowY: "auto",
            flexDirection: "column",
            "& a": {
              textDecoration: "none",
              color: "inherit",
            },
          }}
        >
          <Box
            sx={{
              padding: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "& .icon": {
                color: (theme) =>
                  theme.palette.mode === "dark" ? "inherit" : "#dc2440",
              },
            }}
          >
            <Box textAlign="left" className="audit-left">
              <Typography
                color="textSecondary"
                variant="h"
                component="h4"
                className="Card-Title"
                sx={{
                  marginBottom: "0px",
                }}
              >
                Reports
              </Typography>
              <Box sx={{ marginTop: "2px" }}>
                <FormControl>
                  <FormLabel>Report</FormLabel>
                  <Select
                    sx={{ background: "#f0f0ff" }}
                    name="report"
                    value={groupVehicle}
                    onChange={handleReport}
                    displayEmpty
                    renderValue={
                      groupVehicle !== null
                        ? undefined
                        : () => <span>Select Report</span>
                    }
                  >
                    {reports?.map((type, index) => (
                      <MenuItem key={index} value={type?.value}>
                        {type?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ marginTop: "2px" }}>
                <FormControl>
                  <FormLabel>Select Report Type</FormLabel>
                  <Select
                    sx={{ background: "#f0f0ff" }}
                    name="reporttype"
                    value={formValues?.reporttype}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={
                      formValues?.reporttype !== "reportType"
                        ? undefined
                        : () => <span>Select...</span>
                    }
                  >
                    {reportTypes?.map((action, index) => (
                      <MenuItem
                        key={index}
                        value={action?.fieldName?.toLowerCase()}
                      >
                        {action?.fieldName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {formValues?.reporttype == 'event' &&
              <Box sx={{ marginTop: "2px" }}>
                  <FormControl>
                    <FormLabel>Select Event Type</FormLabel>
                    <MultiSelect
                      isMulti
                      name="eventtype"
                      options={eventTypeRecords}
                      className="basic-multi-select top-down"
                      classNamePrefix="select"
                      placeholder="Select"
                      value={formValues?.selectedOptions}
                      closeMenuOnSelect={false}
                      onChange={handleEventTypeChange}
                    />
                  </FormControl>
                </Box>
              }
              <>
                {/* <Box sx={{ marginTop: "1em" }}>
                    <FormControl>
                      <FormLabel>Date From</FormLabel>
                      <input
                        type="date"
                        name="from"
                        value={formValues?.from}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ marginTop: "1em" }}>
                    <FormControl>
                      <FormLabel>Date To</FormLabel>
                      <input
                        type="date"
                        name="to"
                        value={formValues?.to}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box> */}
                <Box className="date-range top-down" sx={{ marginTop: "2px" }}>
                  {/* <FormControl>
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setDateRange(update);
                        }}
                        // placeholderText={placeholderDate}
                        isClearable={true}
                        ref={datePickerRef}                        
                        dateFormat="dd/MM/yyyy HH:mm:ss"
                      />
                      <IconButton className="cal-icon" onClick={handleDatePicker}>
                        <CalendarIcon />
                      </IconButton>
                    </FormControl> */}
                  <FormControl>
                    <FormLabel>Date From</FormLabel>
                    <DatePicker
                      selected={fromDate}
                      onChange={(date) => handleFromDate(date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={1}
                      timeCaption="Time"
                      dateFormat="dd/MM/yyyy hh:mm aa"
                      ref={fromDatePickerRef}
                      maxDate={new Date()}
                    />
                    <IconButton
                      className="cal-icon"
                      onClick={handleFromDatePicker}
                    >
                      <CalendarIcon />
                    </IconButton>
                  </FormControl>
                </Box>
                <Box className="date-range" sx={{ marginTop: "2px" }}>
                  <FormControl>
                    <FormLabel>Date To</FormLabel>
                    <DatePicker
                      selected={toDate}
                      onChange={(date) => handleToDate(date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={1}
                      timeCaption="Time"
                      dateFormat="dd/MM/yyyy hh:mm aa"
                      ref={toDatePickerRef}
                      maxDate={new Date()}
                    />
                    <IconButton
                      className="cal-icon"
                      onClick={handleToDatePicker}
                    >
                      <CalendarIcon />
                    </IconButton>
                  </FormControl>
                </Box>
                {groupVehicle == "Vehicle" ? (
                  <Box sx={{ marginTop: "2px" }} className="select-drops top-down">
                    <FormControl>
                      <FormLabel>Vehicle</FormLabel>
                      <ReactSelect
                        name="deviceId"
                        options={vehicleData}
                        value={formValues?.selectedDevice}
                        onChange={handleDeviceChange}
                      />
                    </FormControl>
                  </Box>
                ) : groupVehicle == "Group" ? (
                  // <Box sx={{ marginTop: "1em" }} className="select-drops">
                  //   <FormControl>
                  //     <FormLabel>Group</FormLabel>
                  //     <ReactSelect
                  //       name="deviceId"
                  //       options={vehicleData}
                  //       value={selected}
                  //       onChange={handleDeviceChange}
                  //     />
                  //   </FormControl>
                  // </Box>
                  <Box sx={{ marginTop: "2px" }} className="select-drops">
                    <FormControl>
                      <FormLabel>Group</FormLabel>
                      <ReactSelect
                        name="groupId"
                        options={groupData}
                        value={formValues?.selectedGroup}
                        onChange={handleGroupChange}
                      />
                    </FormControl>
                  </Box>
                ) : (
                  false
                )}
                <Box sx={{ marginTop: "2px" }}>
                  <FormControl>
                    <FormLabel>Select Column</FormLabel>
                    <MultiSelect
                      isMulti
                      name="columnName"
                      options={columnData}
                      className="basic-multi-select top-down"
                      classNamePrefix="select"
                      placeholder="All"
                      value={columnNames}
                      closeMenuOnSelect={false}
                      onChange={handleSelect}
                    />
                  </FormControl>
                </Box>
              </>
              <Box sx={{ marginTop: "8px" }}>
                <Button
                  className={`${
                    formValues?.reporttype == "reportType" || formError['disabled'] 
                      ? "disable"
                      : ""
                  } show-btn`}
                  onClick={handleSubmit}
                  disabled={
                    formValues?.reporttype == "reportType" || formError['disabled']
                  }
                >
                  Show
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
        {viewReport?.expanded ? (
          <ReportType viewReport={viewReport?.data} />
        ) : (
          false
        )}
      </Box>
    </>
  );
}
