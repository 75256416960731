import React, { useEffect, useState } from "react";
import { useGetList, useRefresh } from "react-admin";
import Modal from "@mui/material/Modal";
import FormField from "@mui/material/TextField";
import FormButton from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import vtsDataProvider from "../providers/dataProvider";
import { Box, Typography, TextField, FormControl} from "@mui/material";
import { Grid } from "@mui/material";
import Switch from "@mui/material/Switch";
import SendNotification from "../utils/sendNotification";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import Autocomplete from "@mui/material/Autocomplete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

let date = new Date();
const formdefaultValues = {
  type: "",
  notificators: "",
  web: false,
  email: false,
  sms: false,
  createdon: date,
  attributes: {},
  calendarId: 0,
  always: false,
};

const defaultValues = {
  type: "",
  notificators: "",
  createdon: date,
  attributes: {},
  calendarId: 0,
  always: false,
};

export default function AddAlarm({ Alarmopen, handleAlarmClose }) {
  const [notifyData, setNotifyData] = useState({});
  const [formValues, setFormValues] = useState(formdefaultValues);
  const [alarmtypes, setAlarmType] = useState([]);
  const [alarmnotificator, setAlarmNotificator] = useState([]);
  const refresh = useRefresh();
  const [statenotificator, setStateNotificator] = useState(defaultValues);
  const [notifycheck, setNotifyCheck] = useState([]);
  const { data: alarmtype } = useGetList("alarmtype");
  const { data: alarmnotificators } = useGetList("alarmnotificators");
  const [alarmTypeValue, setAlarmTypeValue] = useState(null);
  const [openAlarmDrp, setOpenAlarmDrp] = useState(false);

  useEffect(() => {
    const getAlarm = () => {
      if (alarmtype) {
        const list = format_drpdown(alarmtype);
        setAlarmType(list);      
      }
      if (alarmnotificators) {
        const alarmnotificatorasc = [...alarmnotificators].sort(
          (a, b) => a.id - b.id
        );

        setAlarmNotificator(alarmnotificatorasc);
      }
    };
    getAlarm();
  }, [alarmtype, alarmnotificators]);


  const format_drpdown = (list) => {
    var te = list?.map((data, index) => ({
      label: data?.type,
      value: data?.type,
      showlabel: data?.type,
    }));
    return te;
  };

  useEffect(() => {
    if (statenotificator.type !== "") {
      vtsDataProvider.create(
        "alarm",
        statenotificator,
        setNotifyData,
        "Alarm Created Successfully",
        "Failed to Add Alarm"
      );
      setFormValues(formdefaultValues);
      setStateNotificator(defaultValues);
      refresh();
      handleAlarmClose();
    }
  }, [statenotificator]);

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleAlarmSubmit = (event) => {
    event.preventDefault();

    var value = alarmnotificator.filter(function (item) {
      return item.isChecked === true;
    });

    var notifi = value.map((result) => {
      return result.type;
    });

    //  var notifi = "";
    // if(event.target.sms.checked ===true)
    // {
    //     notifi = event.target.sms.name;
    // }
    // if(event.target.email.checked ===true)
    // {
    //     if(!notifi=="") notifi = notifi + ","
    //     notifi = notifi + event.target.email.name;
    // }
    // if(event.target.web.checked ===true)
    // {
    //     if(!notifi=="") notifi = notifi + ","
    //     notifi = notifi + event.target.web.name;
    // }

    setStateNotificator({
      ...statenotificator,
      type: event.target.type.value,
      always: event.target.always.checked,
      notificators: notifi.toString(),
    });
  };

  const NotSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 22,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "green" : "green",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 18,
      height: 18,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#a5a5a5" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const toggleSwitch = (e) => {
    const alarm_check = alarmnotificator.map((item, idx) => {
      let { isChecked } = "";
      if (item.type === e.target.name) {
        isChecked = e.target.checked;
      } else {
        if (item.isChecked === undefined) isChecked = false;
        else isChecked = item.isChecked;
      }
      return { ...item, isChecked };
    });
    setAlarmNotificator(alarm_check);
  };

  const handleAlarmBoxClose = () => {
    setFormValues(formdefaultValues);
    setAlarmTypeValue(null)
    handleAlarmClose();
  };



  return (
    <>
      <Modal
        open={Alarmopen}
        onClose={handleAlarmBoxClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="AddRoleModal alarm-modal"
      >
        <Box sx={style} className="modal-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create an Alarm
          </Typography>
          <span className="close-btn">
            <CloseIcon onClick={handleAlarmBoxClose} />
          </span>
          <form onSubmit={handleAlarmSubmit}>
            <Box sx={{ flexGrow: 1, marginTop: "2em" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={1}></Grid>
                        <Grid
                          item
                          xs={10}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          {/* <FormLabel>Alarm Type</FormLabel>
                        <Select
                          name="type"
                          placeholder="Alarm Type"
                          value={formValues.type}
                          onChange={handleInputChange}
                        >
                          {
                            alarmtypes.map((alarm) => (
                              <MenuItem key={alarm.type} value={alarm.type}>
                                {alarm.type}
                              </MenuItem>
                            ))
                          }
                        </Select> */}
                          <FormControl className="select-component">
                            <FormLabel>Select Alarm Type</FormLabel>
                            <Autocomplete
                              isOptionEqualToValue={(option, value) =>
                                option.value === value?.value
                              }
                              disablePortal
                              id="type"
                              name="type"
                              className="select-auto"
                              options={alarmtypes}
                              renderOption={(props, option) => (
                                <li {...props} key={option.value}>
                                  {option.showlabel}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // label="Select Group"
                                  
                                />
                              )}
                              open={openAlarmDrp}
                              onOpen={() => {
                                setOpenAlarmDrp(true);
                              }}
                              onClose={() => {
                                setOpenAlarmDrp(false);
                              }}
                              getOptionLabel={(option) => option.showlabel}
                              onChange={(event, newValue) => {
                                setAlarmTypeValue(newValue);
                                setFormValues({
                                  ...formValues,
                                  type: newValue.value,
                                });
                              }}
                              value={alarmTypeValue}
                            />
                          </FormControl>
                          <FormLabel sx={{ marginTop: "20px" }}>
                            Always
                          </FormLabel>
                          <FormField
                            name="always"
                            checked={formValues.always}
                            className="alarm-check"
                            type="checkbox"
                            onChange={handleInputChange}
                          ></FormField>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    </Box>
                  </Box>

                  <Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                          <FormLabel>Additional Notification</FormLabel>
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            {alarmnotificator.map((item, idx) => (
                              <FormControlLabel
                                control={
                                  <NotSwitch
                                    sx={{ m: 1 }}
                                    checked={
                                      item.isChecked === undefined
                                        ? false
                                        : item.isChecked
                                    }
                                    name={item.type}
                                    onChange={toggleSwitch}
                                  />
                                }
                                label={item.type}
                                className="Switchbtn"
                              />
                            ))}
                            {/* <FormControlLabel
                                                    control={<NotSwitch sx={{ m: 1 }}  
                                                    name="web"
                                                    checked={formValues.web} 
                                                    onChange={handleInputChange}  />}
                                                    label="Web" className='Switchbtn'
                                                />
                                                <FormControlLabel
                                                    control={<NotSwitch sx={{ m: 1 }}  
                                                    checked={formValues.email}
                                                    name = "email" 
                                                    onChange={handleInputChange}  />}
                                                    label="Email" className='Switchbtn'
                                                />   */}
                          </Box>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  <div style={{ marginTop: "1em" }} className="modal-footer">
                    <FormButton
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Create Alarm
                    </FormButton>
                    <FormButton
                      variant="contained"
                      onClick={handleAlarmClose}
                      className="cancel-btn"
                      color="primary"
                      sx={{ marginRight: "1em" }}
                    >
                      Cancel
                    </FormButton>
                  </div>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Modal>
      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>
  );
}
