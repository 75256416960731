import React, { useState, useEffect } from "react";
import { useGetOne, useGetList, useListController, useNotify } from 'react-admin';
import Button from '@mui/material/Button';
import "../views/group/group.css";
import Modal from "@mui/material/Modal";
import WarningIcon from '@mui/icons-material/PriorityHighOutlined';
import DoneIcon from '@mui/icons-material/DoneOutlined';
import { Box, Typography } from '@mui/material';
import MobileIcon from '@mui/icons-material/SendToMobileOutlined';
import vtsDataProvider from './../providers/dataProvider';
import { getData } from './../providers/dataProvider';
import { ValidDeviceOtp } from './../providers/dataProvider';

const defaultValues =
{
  otp: "",
  phone: "",
  deviceId: "",
  userId: 0,
  timestampkey: null
}

export const EntityOtpSend = ({ open, handleClose, entityId }) => {
  const [formValues, setFormValues] = useState({ defaultValues })
  const [sucsopen, setsucessOpen] = useState(false);
  const [otpResponse, setOtpResponse] = useState({});
  const [notifyData, setNotifyData] = useState({});
  const [ValidOTPdevice, setValidOTP] = useState({});
  const [validIcon, setValidIcon] = useState(<DoneIcon className='verified-icon' />)
  const notify = useNotify();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  const SuccessOpen = () => {
    setsucessOpen(true);
  };
  const SuccessClose = () => {
    setsucessOpen(false);
  };

  const { data: EntityData, isLoading, error } = useGetOne(
    'groups',
    { id: entityId },
    { enabled: (entityId > 0) }
  );


  useEffect(() => {
    const getEntity = async () => {
      if (EntityData) {
        const formValues1 = {
          deviceId: EntityData?.id,
          permitHolderName: EntityData?.permit_holder,
          clientPhoneNo: EntityData?.contact
        }
        entityId = 0;

        const responsedata = await getData("otpsend", formValues1, setOtpResponse)
      }
    }
    getEntity();
  }, [EntityData]);


  const resendotp = async() =>{
    if (EntityData) {
      const formValues1 = {
        deviceId: EntityData?.id,
        permitHolderName: EntityData?.permit_holder,
        clientPhoneNo: EntityData?.contact
      }
     // entityId = 0;

      const responsedata = await getData("otpsend", formValues1, setOtpResponse)
      setMinutes(1);
      setSeconds(30);
    }

  }


  useEffect(() => {
    if (otpResponse) {
      setFormValues({
        otp: "",
        phone: otpResponse.phone,
        deviceId: otpResponse.deviceId,
        userId: 0,
        timestampkey: null
      })
    }
  }, [otpResponse])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 1,
    p: 4,
    borderRadius: '4px'
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const ValidOtp = (event) => {
    event.preventDefault();
    //  const validOtpResponse = vtsDataProvider.update('otpsend', formValues, setNotifyData, " A OTP has been verified successfully", "Not Valid OTP");
    const validOtpResponse = ValidDeviceOtp('otpsend', formValues, setValidOTP)
    handleClose();
    SuccessOpen();
  }

  useEffect(() => {
    if (ValidOTPdevice) {

      if (ValidOTPdevice.Error) {
        setValidIcon({
          message:"Verification Rejected!",
          icon:<WarningIcon className='warn-icon' />}
          )
      }
      else if (ValidOTPdevice.status === "Invalid Otp.") {
        setValidIcon({
          message:"Verification Rejected!",
          icon:<WarningIcon className='warn-icon' />}
          )
      }
      else if (ValidOTPdevice.status === "Valid Otp."){
        setValidIcon({
          message:"Verification Completed!",
          icon:<DoneIcon className='verified-icon' />
        })
      }

    }
  }, [ValidOTPdevice])

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
  
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (<>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="otp-successModal"
    >
      <form onSubmit={ValidOtp}>
        <Box sx={style} className="modal-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">

          </Typography>

          <Box>
            <MobileIcon className="mobile-icon" />
            <Typography variant="h6" component="h4">
              Verification
            </Typography>
            <Typography variant="p" component="p" sx={{ mt: 2 }}>
              Please enter the verification code sent to <br /> {otpResponse?.phone}
            </Typography>
            <div className="otp-verification">
              <form className="form-otp">
                <div className="form-group">

                  <label className="form-control-placeholder" for="name">Please Enter the code here</label>
                  <input
                    type="text"
                    id="otp"
                    className="form-control"
                    name='otp'
                    value={formValues.otp}
                    onChange={handleInputChange}
                    required></input>

                </div>
              </form>
              <div className="row mb-3">

                  <div className="col-md-12">
                    <div className="resendOTP" style={{textAlign:"left"}}>
                      {seconds > 0 || minutes > 0 ? (
                        <span className="pr-2"><span href="#" className="fc-435367">Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                          </span></span>
                      ) : (
                        <span className="pr-2"><span href="#" className="fc-435367">Didn’t receive the OTP?</span></span>
                      )}
                      {/* <span className="pr-2"><span href="#" className="fc-435367">Did not receive a code?</span></span> */}
                      <a onClick={(e) => resendotp()}  className="fc-818c99" style={{float:"right",cursor:"pointer",marginRight:"5px"}}>Resend</a>
                    </div>
                  </div>
                </div>
            </div>
            <div style={{ marginTop: "1em" }} className="modal-footer">
              <Button
                variant="contained"
                className="cancel-btn"
                onClick={handleClose}
                color="primary"
                sx={{ marginRight: "1em" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="continue-btn"
                type="submit"
                //onClick={() => {  }}
                color="primary"
                sx={{ marginRight: "1em" }}
              >
                Continue
              </Button>
            </div>
          </Box>

        </Box>
      </form>
    </Modal>


    {/* Otp Success modal */}
    <Modal
      open={sucsopen}
      onClose={SuccessClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="otp-successModal"
    >
      <Box sx={style} className="modal-box">
        <Typography id="modal-modal-title" variant="h6" component="h2">

        </Typography>

        <Box>
          {validIcon?.icon}
          {/* <WarningIcon className="warn-icon" /> 
                  <DoneIcon className="verified-icon" />*/}
          <Typography variant="h6" component="h4">
            {validIcon?.message}
          </Typography>
          <Typography variant="p" component="p" sx={{ mt: 2 }}>
            {/* A OTP has been verified successfully */}
            {ValidOTPdevice?.status}
          </Typography>
          <div style={{ marginTop: "1em" }} className="modal-footer">
            <Button
              variant="contained"
              className="continue-btn"
              onClick={SuccessClose}
              color="primary"
              sx={{ marginRight: "1em" }}
            >
              Done
            </Button>
          </div>
        </Box>

      </Box>
    </Modal>
  </>

  );

}


