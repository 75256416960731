import * as React from 'react';
import logo from '../images/logo-png.png'
export const Spinner = () => {
    return (
        <div className="spinner-container">
        {/* <div className="loading-spinner"></div> */}
        <img src={logo} />
        <span>Please Wait...</span>
        </div>
    );
};