import React, { useState, useEffect, useRef } from 'react';
import {
  ReferenceField,
  TextField,
  useListController,
  Datagrid,
  useRecordContext,
  ListContextProvider,
  TextInput,
  Pagination,
  FunctionField,
  TopToolbar,
  Confirm,
  useRefresh,
  ResourceContextProvider,
  useGetList
} from "react-admin";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import {
  Grid,
  Card
} from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ViewIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import "../views/route/route.css";
import AddButton from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import RouteIcon from '@mui/icons-material/RouteOutlined';
import ArrowIcon from '@mui/icons-material/ArrowBackOutlined';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MapView from './../components/mapview';
import SelectedList from './../components/Accordian';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableRowsIcon from '@mui/icons-material/TableRowsOutlined';
import DistanceIcon from '@mui/icons-material/RouteOutlined';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import PointTypeIcon from '@mui/icons-material/PinDropOutlined';
import { RouteAssign } from './../components/RouteAssign';
import { LinearProgress } from '@mui/material';
import VTSListToolbar from '../components/VTSListToolbar';
import moment from "moment";
import { PanelRAssign } from '../utils/PanelRoutelist';
import SendNotification from "../utils/sendNotification";
import vtsDataProvider, { ManualStartRoute } from './../providers/dataProvider'
import useMediaQuery from '@mui/material/useMediaQuery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import IconButton from "@mui/material/IconButton";
import Icon6 from "../images/Viewmap.svg";
import { useNavigate } from "react-router-dom";
import { convertUTCToLocal } from "../utils/validate"
import { Button } from 'react-admin';
import { fetchRouteAssignExport } from '../providers/dataProvider'
import urls from "../constants";

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  flexRow: { display: 'flex', flexDirection: 'row' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};
const Buttonstyle = {
  position: 'absolute',
  background: '#4646F2',
  color: '#fff',
  zIndex: 1,
  textTransform: 'capitalize',
  width: '130px',
  border: '1px solid #4546F2'
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const defaultValues = {
  name: "",
  phone: "",
  email: "",
  password: "",

};

const position = [51.505, -0.09]


const TabPanel = props => {

  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && (

        <Typography>{children}</Typography>

      )}
    </React.Fragment>
  )
}




export const RouteAssignList = ({ createper, updateper, deleteper }) => {
  const navigate = useNavigate()
  const [notifyData, setNotifyData] = useState({});
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [assignexpanded, setAssignExpand] = React.useState(false);
  const [filter, setFilter] = useState('');
  const [RAssignId, setRAssignId] = useState();
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    deleteId: null,
  });

  const [manualDialog, setManualDialog] = useState({
    open: false,
    routeId: null,
    message:"",
    successmsg:"",
    failuremsg:"",
    type:""
  });
  const refresh = useRefresh();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [selected, setSelected] = useState("all");
  const [tripStatus, setTripStatus] = useState(null);

  const selectRef = useRef(null);
  const fromDatePickerRef = React.useRef(null);
  const toDatePickerRef = React.useRef(null);
  let todate = new Date();
  let prvDate = new Date(
    todate.getFullYear(),
    todate.getMonth() - 1,
    todate.getDate()
  );
  const [startDate, setStartDate] = React.useState(prvDate);
  const [endDate, setEndDate] = React.useState(new Date());

  useEffect(()=>{
    setPerPage(150)
  },[])

  const handleStatusChange = (e) => {
    setSelected(e.target.value)
    // selectRef.current.value = e.target.value;
  };

  const matches = useMediaQuery('(min-width:1800px)');

  const handleChangeStartDate = (dates) => {
    setStartDate(dates)
  }
  const handleChangeEndDate = (dates) => {
    setEndDate(dates)
  }

  const handleFromDatePicker = () => {
    const dp = fromDatePickerRef.current;
    dp.setOpen(true);
  };
  const handleToDatePicker = () => {
    const dpt = toDatePickerRef.current;
    dpt.setOpen(true);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };

  const perPage = 150;
  const { data: statusType } = useGetList("tripsstatustype");
  const { data, isLoading, page, setPage, setPerPage, ...listContext } = useListController({
    resource: 'routeassign'
  });

  useEffect(() => {
    if (assignexpanded === false) setRAssignId(0)
  }, [assignexpanded])

  useEffect(() => {
    let arrayStatus = [];
    if (statusType && statusType?.length > 0) {
      let entries = Object.entries(statusType[0])
      entries.map(([key, val]) => {
        // return `The ${key} is ${val}`;
        if (key !== "id") arrayStatus.push({ key: key, value: val })
      });
    }
    setTripStatus(arrayStatus)
  }, [statusType])

  const filters = [
    <Box className="status-b" style={{ marginLeft: "12px" }} alwaysOn>
      {tripStatus && tripStatus?.length > 0 &&
        (<><FormLabel>Status</FormLabel>
          <Select
            name="status"
            value={selected}
            onChange={handleStatusChange}
            ref={selectRef}
            displayEmpty
            renderValue={
              selected !== null ? undefined : () => <span>Select Status</span>
            }
          >
            {/* <MenuItem>
            Select */}
            {/* </MenuItem> */}
            {tripStatus
              ? tripStatus?.length > 0 ? tripStatus.map(
                (type, index) => (
                  <MenuItem key={index} value={type?.value}>
                    {type?.key}
                  </MenuItem>
                )
              )
                : "" : ""
            }
          </Select></>)}
    </Box>,
    <Box className="date-range" sx={{ marginTop: "0.5em" }} alwaysOn>
      <FormLabel className='custom-label'>Date From</FormLabel>
      <FormControl sx={{ marginLeft: "0 !important", marginTop: "0 !important" }}>
        <DatePicker
          selected={startDate}
          onChange={(date) => handleChangeStartDate(date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={1}
          timeCaption="Time"
          dateFormat="dd/MM/yyyy hh:mm aa"
          ref={fromDatePickerRef}
          maxDate={new Date()}
        />
        <IconButton
          className="cal-icon"
          onClick={handleFromDatePicker}
        >
          <CalendarIcon />
        </IconButton>
      </FormControl>
    </Box>,
    <Box className="date-range" sx={{ marginTop: "0.5em" }} alwaysOn>
      <FormLabel className='custom-label'>Date To</FormLabel>
      <FormControl style={{ marginTop: "0" }}>
        <DatePicker
          selected={endDate}
          onChange={(date) => handleChangeEndDate(date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={1}
          timeCaption="Time"
          dateFormat="dd/MM/yyyy hh:mm aa"
          ref={toDatePickerRef}
          maxDate={new Date()}
        />
        <IconButton
          className="cal-icon"
          onClick={handleToDatePicker}
        >
          <CalendarIcon />
        </IconButton>
      </FormControl>
    </Box>,
    <TextInput label="Search" source="q" size="small" alwaysOn />,
  ];

  const filterValues = { q: filter };
  const setFilters = filters => setFilter(filters.q);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    var queryvalue = document.querySelectorAll('.list-content');
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const ExportRouteAssign = () => {
    fetchRouteAssignExport("routeassignexport", {
      filter: { startDate: moment.utc(startDate).format(), endDate: moment.utc(endDate).format(), selected: selected }
    })
  }

  const AssignActions = () => (
    <TopToolbar className='route-toolbar'>
      <Button className="Export-entity" href={`${urls.EXPORT_ROUTE_ASSIGN}?status=${selected}&from=${moment.utc(startDate).format()}&to=${moment.utc(endDate).format()}`} download>
        Export
      </Button>
      {createper && (<><AddButton className="create-btn" startIcon={<AddIcon />} sx={Buttonstyle} expand={assignexpanded} aria-expanded={assignexpanded} aria-label="show" onClick={() => { setAssignExpand(true); }}>Assign Route</AddButton></>)}
    </TopToolbar>
  );
  const RoutnameField = ({ source }) => {
    const record = useRecordContext();
    return (
      <span style={{ color: "#4646F2", display: "flex" }}><RouteIcon /><span>{record[source]}</span>
      </span>
    )
  };

  const TripStatus = ({ source }) => {
    let status = "Trip Created Not Started"
    const record = useRecordContext();
    if (record.trip.status === "started")
      status = "Trip is active and started"
    else if (record.trip.status === "finished")
      status = "Trip Finished"
    return (
      <>
        <span>{status}</span>
      </>
    );
  };

  // const DateSetter = ({ source }) => {
  //   const record = useRecordContext();
  //   const dt = (record[source] !== null) ? moment(record[source]).format("DD MMM YYYY, HH:mm:ss") : "";
  //   return (
  //     <>
  //       <span>{dt}</span>
  //     </>
  //   );
  // };

  const handleDeleteClick = (event, deleteId) => {
    setDeleteDialog({
      open: true,
      deleteId: deleteId
    });
  };

  const handleConfirmDelete = () => {
    if (deleteDialog.deleteId !== null) {
      vtsDataProvider.delete('routeassign', { id: deleteDialog.deleteId }, setNotifyData, "Route Assign Deleted Succesfully", "Failed to Delete");
      handleConfirmClose();
    }
  };

 

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const handleConfirmClose = () => {
    setDeleteDialog({
      open: false,
      deleteId: null,
    });
  };
 
  const CustomDeleteButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <DeleteIcon
          onClick={(event) => handleDeleteClick(event, record.trip.id)}
        />
      </div>
    );
  };

  const handleEditClick = (event, RAssignId) => {
    setRAssignId(RAssignId);
    setAssignExpand(true);
  }

  const CustomEditButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <EditIcon
          onClick={(event) => handleEditClick(event, record.trip.id)}
        />
      </div>
    );
  };

  const CustomManualTrips = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        {
          ((record.trip.status === "pending") || (record.trip.status === "") || (record.trip.status === null) || (record.trip.status === undefined)) ?
            <a style={{cursor:"pointer"}}
              onClick={(event) => handleManualClick(record.trip.id, "forcedStarted", "Trip started manually", "Failed to start trip","Are you sure you want to start the trip manually?")}
            >Manual start</a> :
            record.trip.status === "started" ?
              <a style={{cursor:"pointer"}}
                onClick={(event) => handleManualClick(record.trip.id, "forcedFinished", "Trip finished manually", "Failed to finish trip","Are you sure you want to finish the trip manually?")}
              >Manual finish</a> : ""
        }
      </div>
    );
  };
  
  const handleManualClick = (routeId,type,successmsg, failuremsg,warningmsg) => {
    setManualDialog({
      open: true,
      routeId: routeId,
      message:warningmsg,
      successmsg:successmsg,
      failuremsg:failuremsg,
      type:type
    });
  };

  const handleConfirmManual = () => {
    if (manualDialog.routeId !== null) {
      handleManualTripsClick()
      handleConfirmManualClose();
    }
  };

  const handleManualTripsClick = () => {
    ManualStartRoute('manualroute', { id: manualDialog.routeId, type: manualDialog?.type }, setNotifyData, manualDialog?.successmsg, manualDialog?.failuremsg);
  }

  const handleConfirmManualClose = () => {
    setManualDialog({
      open: false,
      routeId: null,
      message:"",
      successmsg:"",
      failuremsg:"",
      type:""
    });
  };

  const HandleLiveTrip = (record) => {
    navigate("/routeassigntrip", { state: record });
  };

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt = (record[source] !== null && record[source] !== "" && record[source] !== undefined) ? moment(record[source]).utc().format('DD MMM YYYY, HH:mm:ss') : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  if (isLoading) {
    return <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  } else
    return (
      <>
        {assignexpanded === false ?
          <ResourceContextProvider value="routeassign">
            <ListContextProvider debounce={8000} value={{ data, filterValues, page, setFilters, setPage, setPerPage, ...listContext }}>
              <VTSListToolbar className="route-assign" bulkActionButtons={false} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} filters={filters} setSelected={setSelected} select={selectRef} actions={<AssignActions />} />
              {matches == true ? (
                <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />

              ) : (
                <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
              )}
              <Box className="list-content">
                <Datagrid bulkActionButtons={false} expand={<PanelRAssign />} empty={<CustomEmpty />}>
                  <TextField source="route.numStoppage" label="Sequence" />
                  {/* <TextField source="trip.routeId" label="Route Id" />
              <TextField source="trip.id" label="ID" /> */}
                  <FunctionField label="Route Name" render={
                    record => <span style={{ color: "#4646F2", display: "flex" }}><img src={Icon6} onClick={() => HandleLiveTrip(record)} /><RouteIcon /><span>{`${record?.route?.name}`}</span>
                    </span>
                  } />
                  <TextField source="route.routeType" label="Route Type" />
                  <TextField source="device.name" label="Entity" />
                  <TripStatus source="trip.status" label="Trip Status" />
                  <TextField source="trip.initialOdometer" label="Initial Odometer" />
                  <TextField source="trip.closingOdometer" label="Closing Odometer" />

                  <FunctionField label="Start Time" render={
                    record => (record.trip.startTime !== null) ? convertUTCToLocal(record.trip.startTime) : ""
                  } />
                  <FunctionField label="End Time" render={
                    record => (record.trip.endTime !== null) ? convertUTCToLocal(record.trip.endTime) : ""
                  } />
                  <TextField source="trip.remarks" label="Tag" />
                  <FunctionField label="Scheduled Date" render={
                    record => (record.trip.assignedTime !== null) ? convertUTCToLocal(record.trip.assignedTime) : ""
                  } />
                  <ReferenceField label="Assigned By" source="trip.assignedBy" reference="users">
                    <TextField source="name" />
                  </ReferenceField>
                  <TextField source="trip.status" label="Status" />
                  <CustomManualTrips source="id" label="Manual Trip" />
                  <span label="Action" className="action-icons">
                    {updateper && (<><CustomEditButton source="id" /></>)}
                    {deleteper && (<><CustomDeleteButton source="id" /></>)}
                  </span>
                </Datagrid>
              </Box>

            </ListContextProvider>
          </ResourceContextProvider>
          :
          <RouteAssign assignexpanded={assignexpanded} setAssignExpand={setAssignExpand} RAssignId={RAssignId}></RouteAssign>
        }

        <Confirm
          isOpen={deleteDialog?.open}
          title="Delete Route Assign"
          content="Are you sure you want to delete the Route Assign?"
          onConfirm={handleConfirmDelete}
          onClose={handleConfirmClose}
        />

        <Confirm
          isOpen={manualDialog?.open}
          title="Manual Confirmation"
          content={manualDialog?.message}
          onConfirm={handleConfirmManual}
          onClose={handleConfirmManualClose}
        />
        <SendNotification data={notifyData} onClose={() => setNotifyData({})} />

      </>
    );
}
