import React, { useState, useEffect } from 'react';
import {
  DateField,
  useRecordContext,
  Datagrid,
  useListController,
  TextField,
  TopToolbar,
  ListContextProvider,
  Confirm,
  useRefresh,
  Pagination,
  TextInput
} from 'react-admin';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import "../group/group.css";
import { AddGroups } from "../../components/AddGroups";
import { EditGroup } from "../../components/EditGroup";
import { LinearProgress } from '@mui/material';
import VTSListToolbar from '../../components/VTSListToolbar';
import SendNotification from "../../utils/sendNotification";
import vtsDataProvider from '../../providers/dataProvider'
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import moment from "moment";
import Icon6 from '../../images/Viewmap.svg';
import useMediaQuery from '@mui/material/useMediaQuery';

const Buttonstyle = {
  position: 'absolute',
  background: '#4646F2',
  color: '#fff',
  zIndex: 1,
  textTransform: 'capitalize',
  width: '147px',
  border: '1px solid #4646F2'
}

export const GroupTab = ({ createper, updateper, deleteper }) => {
  const [notifyData, setNotifyData] = useState({});
  const [Accountopen, setAccountopen] = React.useState(false);
  const [EditGroupId, setEditGroupId] = useState(0);
  const [EditGroupopen, setEditGroupopen] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    deleteId: null,
  });

  const refresh = useRefresh();
  const handleGroupOpen = () => setAccountopen(true);
  const handleGroupClose = () => setAccountopen(false);

  const handleEditopen = () => setEditGroupopen(true);
  const handleEditClose = () => setEditGroupopen(false);

  const [filter, setFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const matches = useMediaQuery('(min-width:1800px)');

 const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };
  
  const { data, isLoading, page, setPage, setPerPage, ...listContext } = useListController({
    resource: 'groupslist'
  });

  const filters = [<TextInput label="Search" source="q" size="small" alwaysOn />];
  const filterValues = { q: filter };
  const setFilters = filters => setFilter(filters.q);

  useEffect(() => {
    var queryvalue = document.querySelectorAll('.list-content');
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const GroupActions = () => (
    <TopToolbar className='group-toolbar'>
      {/* Add your custom actions */}
      {createper && (<><Button className="Addgroup" onClick={handleGroupOpen} startIcon={<AddIcon />} sx={Buttonstyle}>Add New Group</Button></>)}
    </TopToolbar>
  );

  const handleDeleteClick = (event, deleteId) => {
    setDeleteDialog({
      open: true,
      deleteId: deleteId
    });
  };

  const handleConfirmDelete = () => {
    if (deleteDialog.deleteId !== null) {
      vtsDataProvider.delete('groupsdata', { id: deleteDialog.deleteId }, setNotifyData, "Group Deleted Succesfully", "Failed to Delete");
      handleConfirmClose();
    }
  };

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const handleConfirmClose = () => {
    setDeleteDialog({
      open: false,
      deleteId: null,
    });
    refresh();
  };

  const CustomDeleteButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <DeleteIcon
          onClick={(event) => handleDeleteClick(event, record[source])}
        />
      </div>
    );
  };

  const handleEditClick = (event, EditGroupId) => {
    setEditGroupId(EditGroupId);
    handleEditopen();
  }

  const CustomEditButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <EditIcon
          onClick={(event) => handleEditClick(event, record[source])}
        />
      </div>
    );
  };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt = (record[source] !== null) ? moment(record[source]).format("DD MMM YYYY, HH:mm:ss") : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  const VehicleNumber = () => {
    return (
      <>
        <span className='icon-blue'>11<img src={Icon6} /></span>
      </>
    );
  };

  const CustomEmpty = () => <div className="no-records">No Records</div>;

  if (isLoading) {
    return <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  } else
    return (
      <>
        <ListContextProvider debounce={8000} value={{ data, filterValues, page, setFilters, setPage, setPerPage, ...listContext }}>
          <VTSListToolbar filters={filters} actions={<GroupActions />} />
          {/* {(data === undefined || (Array.isArray(data) === true && data.length === 0)) ? <><div className='no-records'>Data Not Available</div></> :
            <> */}
          {matches == true ? (
              <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}  />
              
              ) : (
             <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
  	        )}
          <Box className="list-content">
            <Datagrid bulkActionButtons={false} empty={<CustomEmpty />}>
              {/* <TextField source="id" label="Id" /> */}
              <TextField source="name" label="Name" />
              {/* <VehicleNumber  label="No.of Vehicles" /> */}
              <DateSetter source="createdon" label="Created On" />
              <DateSetter source="lastupdate" label="Updated On" />
              <span label="Action" className="action-icons">
                {updateper && (<><CustomEditButton source="id" /></>)}
                {deleteper && (<><CustomDeleteButton source="id" /></>)}
              </span>
            </Datagrid>
          </Box>
          {/* </>} */}
        </ListContextProvider>
        <AddGroups Accountopen={Accountopen}
          handleGroupClose={handleGroupClose}
          listContext={listContext}></AddGroups>
        <EditGroup EditGroupopen={EditGroupopen}
          handleEditClose={handleEditClose}
          listContext={listContext}
          EditGroupId={EditGroupId}></EditGroup>

        <Confirm
          isOpen={deleteDialog?.open}
          title="Delete Group"
          content="Are you sure you want to delete the group?"
          onConfirm={handleConfirmDelete}
          onClose={handleConfirmClose}
        />
        <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
      </>
    );
};
