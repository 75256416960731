/* eslint-disable import/no-anonymous-default-export */
import L  from 'leaflet';
import { empty } from './validate';

/**
 * Class extends Leaflet's DivIcon to support selection (highlight) of icon and rotation based on course
 */
const DivIconExt = L.DivIcon.extend({
	options: {
        iconUrl: '',
        className: 'imgwrapper',
        highlight: false,
        course: '0'
	},

	createIcon: function (oldIcon) {
		var div = (oldIcon && oldIcon.tagName === 'DIV') ? oldIcon : document.createElement('div'),
		    options = this.options;

		if (options.html instanceof Element) {
			empty(div);
			div.appendChild(options.html);
		} else if(options.html !== false) {
			div.innerHTML =  options.html;
		} else {
            div.innerHTML = this.buildHtml();
        }

		this._setIconStyles(div, 'icon');

		return div;
	},

    buildHtml : function() {
        let options = this.options;
        let img = "<img src='" +  options.iconUrl + "' style='transform: rotate(" + options.course + "deg)' />";
        return options.highlight ? "<div class='border-div'>" + img + "</div>" : "<div class='borderless-div'>" + img + "</div>"
    },

    

});

export default DivIconExt;