import React, { useState, useEffect } from "react";
import { useGetOne, useGetList, useRefresh } from "react-admin";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Tabs, Tab, Card } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import vtsDataProvider from "./../providers/dataProvider";
import moment from "moment";
import SendNotification from "../utils/sendNotification";
import EyeIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import { TabPanel } from "./../utils/TabPanel";

const successstyle = {
  color: "blue",
  align: "center",
  fontSize: "14px",
};

const errorstyle = {
  color: "red",
  fontSize: "12px",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

let date = new Date();
const defaultValues = {
  id: 0,
  name: "",
  phone: "",
  email: "",
  password: "",
  roleid: 0,
  map: "",
  latitude: "",
  longitude: "",
  zoom: "",
  poiLayer: "",
  coordinateFormat: "",
  twelveHourFormat: "",
  expirationTime: "",
  deviceLimit: "",
  userLimit: "",
  disabled: false,
  administrator: false,
  readonly: false,
  deviceReadonly: false,
  limitCommands: false,
  createdon: "",
  lastUpdate: date,
};

const ActionField = ({
  listContext,
  Accountopen,
  EditUserId,
  handleAccountClose,
}) => {
  const [notifyData, setNotifyData] = useState({});
  const [Accountvalue, setAccountValue] = React.useState(0);
  const [formErrors, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [formValues, setFormValues] = useState(defaultValues);
  const [userroles, setUserrole] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const refresh = useRefresh();

  const { data: userrole } = useGetList("userrole");

  const {
    data: EditUserData,
    isLoading,
    error,
  } = useGetOne("users", { id: EditUserId }, { enabled: EditUserId !== 0 });

  useEffect(() => {
    const getUserrole = () => {
      if (userrole) setUserrole(userrole);
    };
    getUserrole();
  }, [userrole]);

  useEffect(() => {
    const getEditUserData = () => {
      if (EditUserData) {
        setFormValues({
          id: EditUserData.id,
          name: EditUserData.name,
          email: EditUserData.email,
          phone: EditUserData.phone,
          map: EditUserData.map,
          latitude: EditUserData.latitude,
          longitude: EditUserData.longitude,
          zoom: EditUserData.zoom,
          twelveHourFormat: EditUserData.twelveHourFormat,
          coordinateFormat: EditUserData.coordinateFormat,
          poiLayer: EditUserData.poiLayer,
          disabled: EditUserData.disabled,
          administrator: EditUserData.administrator,
          readonly: EditUserData.readonly,
          deviceReadonly: EditUserData.deviceReadonly,
          limitCommands: EditUserData.limitCommands,
          expirationTime: EditUserData.expirationTime
            ? moment(EditUserData.expirationTime).format("YYYY-MM-DD")
            : null,
          userLimit: EditUserData.userLimit,
          deviceLimit: EditUserData.deviceLimit,
          roleid: EditUserData.roleid,
          createdon: EditUserData.createdon,
          lastUpdate: date,
        });
        EditUserId = 0;
      }
    };
    getEditUserData();
  }, [EditUserData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      vtsDataProvider.update(
        "users",
        formValues,
        setNotifyData,
        "User Updated Successfully",
        "Failed to Update User"
      );
      handleAccountClose();
      setAccountValue(0);
      refresh();
    } else {
      setAccountValue(0);
    }
  }, [formErrors]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormError(validate(formValues));
    setIsSubmit(true);
  };

  const validate = (values) => {
    const errors = {};
    const phoneregex = "^\\d{10}$";
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let regexPassword = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&#])([a-zA-Z0-9@$!%*?&]{10,})$/;
    // let regexlatlng = /^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}/;
 
     let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');

     console.log("values.password",values.password)
    if (!values.name) errors.name = "Name is required!";
    if (!values.phone) errors.phone = "Phone is required!";
    if (!values.email) errors.email = "Email is required!";
    if (!values.roleid) errors.rolename = "Role is required!";
    if (values.email !== "") {
      if (!values.email.match(regexEmail))
        errors.email = "This is not a valid email format!";
    }
    if (values.phone !== "") {
      if (!values.phone.match(phoneregex))
        errors.phone = "Phone Number must be 10 digit!";
    }
    // if (values.password ===undefined || values.password ===undefined) errors.password = "Password is required!";
    // if (values.password !== "" && values.password !== undefined) {
    //   if (!values.password.match(regexPassword))
    //     errors.password = "This is not a valid. Password should be Minimum ten characters, at least one Capital letter, one number and one special character!";
    // }
    if (values.latitude !== "") {
      if (!pattern.test(values.latitude))
        errors.latitude = "This is not a valid latitude!";
    }
    if (values.longitude !== "") {
      if (!pattern.test(values.longitude))
        errors.longitude = "This is not a valid longitude!";
    }
    console.log("errors",errors)
    return errors;
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const Accounthandle = (event, newValue) => {
    setAccountValue(newValue);
  };

  const EntitytabChange = (newValue) => {
    setAccountValue(newValue);
  };

  return (
    <>
      {/*Add profile  */}
      <Modal
        open={Accountopen}
        onClose={handleAccountClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="AdduserModal"
      >
        <Box sx={style} className="modal-box">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit User
          </Typography>
          <span className="close-btn" onClick={handleAccountClose}>
            <CloseIcon />
          </span>
          <form onSubmit={handleSubmit}>
            <Box sx={{ flexGrow: 1, marginTop: "2em" }}>
              <Grid
                container
                spacing={2}
                sx={{ marginLeft: "-7px !important" }}
              >
                <Grid item xs={3} className="left-side">
                  <Tabs
                    value={Accountvalue}
                    onChange={Accounthandle}
                    className="Addrole-tabs"
                  >
                    <Tab label="Personal Information" />
                    <Tab label="Preference" />
                    <Tab label="Permission" />
                  </Tabs>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sx={{ paddingLeft: "5px" }}
                  className="right-side"
                >
                  <TabPanel value={Accountvalue} index={0}>
                    <Box sx={{ flexGrow: 1 }} className="div-box">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6" component="h4">
                            User Details
                          </Typography>
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Name</FormLabel>
                                  <TextField
                                    name="name"
                                    placeholder="Enter Name"
                                    type="text"
                                    value={formValues.name}
                                    onChange={handleInputChange}
                                  />
                                  <div style={errorstyle}>
                                    {formErrors.name}
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Phone</FormLabel>
                                  <TextField
                                    name="phone"
                                    placeholder="10 Digit Mobile No"
                                    type="text"
                                    value={formValues.phone}
                                    onChange={handleInputChange}
                                  />
                                  <div style={errorstyle}>
                                    {formErrors.phone}
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Email Address</FormLabel>
                                  <TextField
                                    name="email"
                                    placeholder="Enter Email Address"
                                    type="text"
                                    autoComplete="false"
                                    value={formValues.email}
                                    onChange={handleInputChange}
                                  />
                                  <div style={errorstyle}>
                                    {formErrors.email}
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormControl>
                                    <FormLabel>Role</FormLabel>
                                    <Select
                                      name="roleid"
                                      placeholder="Role"
                                      value={formValues.roleid}
                                      onChange={handleInputChange}
                                    >
                                      <MenuItem key="Select Role" value="0">
                                        Select Role
                                      </MenuItem>
                                      {userroles.map((role) =>
                                        role.rolename !== "" ? (
                                          <MenuItem
                                            key={role.rolename}
                                            value={role?.id}
                                          >
                                            {role.rolename}
                                          </MenuItem>
                                        ) : (
                                          ""
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                  <div style={errorstyle}>
                                    {formErrors.rolename}
                                  </div>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sx={{ marginTop: "1em" }}>
                                <Typography variant="h6" component="h4">
                                  Set Password
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Box>
                                  <FormLabel>Enter Password</FormLabel>
                                  <TextField
                                    name="password"
                                    placeholder="Enter Password"
                                    type="text"
                                    autoComplete="false"
                                    value={formValues.password}
                                    onChange={handleInputChange}
                                  />
                                  {/* {showPassword ? ( */}
                                  <EyeIcon
                                    // onClick={togglePassword}
                                    sx={{
                                      position: "relative",
                                      bottom: "32px",
                                      left: "222px",
                                    }}
                                  />
                                  {/* ) : (
<VisibilityOffIcon
onClick={togglePassword}
sx={{
position: "relative",
bottom: "32px",
left: "222px",
width:"0.8em !important"
}}
/> 
)}*/}
                                    <div style={errorstyle}>
                                    {formErrors.password}
                                  </div>  
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <div style={{ marginTop: "1em" }} className="modal-footer">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => EntitytabChange(1)}
                      >
                        Next
                      </Button>
                    </div>
                  </TabPanel>
                  <TabPanel value={Accountvalue} index={1}>
                    <Box sx={{ flexGrow: 1 }} className="div-box">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6" component="h4">
                            Preferences
                          </Typography>
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Map Layer</FormLabel>
                                  <Select
                                    sx={{ background: "#f0f0ff" }}
                                    placeholder="Select"
                                    name="map"
                                    value={formValues.map}
                                    onChange={handleInputChange}
                                  >
                                    <MenuItem key="" value="">
                                      -- Select Layer --
                                    </MenuItem>
                                    <MenuItem key="1" value="custom">
                                      custom
                                    </MenuItem>
                                    <MenuItem key="0" value="osm">
                                      osm
                                    </MenuItem>
                                  </Select>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Latitude</FormLabel>
                                  <TextField
                                    name="latitude"
                                    placeholder="Enter"
                                    type="text"
                                    value={formValues.latitude}
                                    onChange={handleInputChange}
                                  />
                                   <div style={errorstyle}>
                                    {formErrors.latitude}
                                  </div> 
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Longitude</FormLabel>
                                  <TextField
                                    name="longitude"
                                    placeholder="Enter"
                                    type="text"
                                    autoComplete="false"
                                    value={formValues.longitude}
                                    onChange={handleInputChange}
                                  />
                                   <div style={errorstyle}>
                                    {formErrors.longitude}
                                  </div> 
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormControl>
                                    <FormLabel>Zoom</FormLabel>
                                    <TextField
                                      name="zoom"
                                      placeholder="Enter"
                                      type="text"
                                      autoComplete="false"
                                      value={formValues.zoom}
                                      onChange={handleInputChange}
                                    />
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>POI Layer</FormLabel>
                                  <TextField
                                    name="poiLayer"
                                    placeholder="Enter"
                                    type="text"
                                    autoComplete="false"
                                    value={formValues?.poiLayer}
                                    onChange={handleInputChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormControl>
                                    <FormLabel>Coordinate Format</FormLabel>
                                    <TextField
                                      name="coordinateFormat"
                                      type="text"
                                      autoComplete="false"
                                      value={formValues?.coordinateFormat}
                                      onChange={handleInputChange}
                                    />
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{ display: "flex" }}
                                  className="left-checkbox"
                                >
                                  <Box
                                    sx={{
                                      marginTop: "10px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="twelveHourFormat"
                                      value={formValues?.twelveHourFormat}
                                      checked={formValues?.twelveHourFormat}
                                      onChange={handleInputChange}
                                    />
                                    <FormLabel>12 Hour Format</FormLabel>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <div style={{ marginTop: "1em" }} className="modal-footer">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => EntitytabChange(2)}
                      >
                        Next
                      </Button>
                    </div>
                  </TabPanel>
                  <TabPanel value={Accountvalue} index={2}>
                    <Box sx={{ flexGrow: 1 }} className="div-box">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6" component="h4">
                            Permissions
                          </Typography>
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Expiration</FormLabel>
                                  <TextField
                                    name="expirationTime"
                                    placeholder="Enter"
                                    type="date"
                                    value={formValues?.expirationTime}
                                    onChange={handleInputChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>Device Limit</FormLabel>
                                  <TextField
                                    name="deviceLimit"
                                    placeholder="Enter"
                                    type="text"
                                    value={formValues?.deviceLimit}
                                    onChange={handleInputChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box sx={{ marginTop: "1em" }}>
                                  <FormLabel>User limit</FormLabel>
                                  <TextField
                                    name="userLimit"
                                    placeholder="Enter"
                                    type="text"
                                    autoComplete="false"
                                    value={formValues?.userLimit}
                                    onChange={handleInputChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}></Grid>
                              <Grid item xs={12}>
                                <Box
                                  sx={{ display: "flex" }}
                                  className="left-checkbox"
                                >
                                  <Box
                                    sx={{
                                      marginTop: "10px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="disabled"
                                      value={formValues?.disabled}
                                      checked={formValues?.disabled}
                                      onChange={handleInputChange}
                                    />
                                    <FormLabel>Disabled</FormLabel>
                                  </Box>
                                  {/* <Box
                                    sx={{
                                      marginTop: "10px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="administrator"
                                      value={formValues?.administrator}
                                      checked={formValues?.administrator}
                                      onChange={handleInputChange}
                                    />
                                    <FormLabel>Admin</FormLabel>
                                  </Box>
                                  <Box
                                    sx={{
                                      marginTop: "10px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="readonly"
                                      value={formValues?.readonly}
                                      checked={formValues?.readonly}
                                      onChange={handleInputChange}
                                    />
                                    <FormLabel>Read Only</FormLabel>
                                  </Box>
                                  <Box
                                    sx={{
                                      marginTop: "10px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="deviceReadonly"
                                      value={formValues?.deviceReadonly}
                                      checked={formValues?.deviceReadonly}
                                      onChange={handleInputChange}
                                    />
                                    <FormLabel>Device Read Only</FormLabel>
                                  </Box> */}
                                   <Box
                                    sx={{
                                      marginTop: "10px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="limitCommands"
                                      value={formValues?.limitCommands}
                                      checked={formValues?.limitCommands}
                                      onChange={handleInputChange}
                                    />
                                    <FormLabel>Limit Commands</FormLabel>
                                  </Box>
                                </Box>
                                {/* <Box
                                  sx={{ display: "flex" }}
                                  className="left-checkbox"
                                >
                                  <Box
                                    sx={{
                                      marginTop: "10px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      name="limitCommands"
                                      value={formValues?.limitCommands}
                                      checked={formValues?.limitCommands}
                                      onChange={handleInputChange}
                                    />
                                    <FormLabel>Limit Commands</FormLabel>
                                  </Box>
                                </Box> */}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <div style={{ marginTop: "1em" }} className="modal-footer">
                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        className="cancel-btn"
                        color="primary"
                        sx={{ marginRight: "1em" }}
                        onClick={handleAccountClose}
                      >
                        Cancel
                      </Button>
                    </div>
                  </TabPanel>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Modal>

      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>
  );
};

export default ActionField;
