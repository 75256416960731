import React, { useEffect } from 'react';
import {
    Tabs, Tab, Box, Typography
} from '@mui/material';
import { TripTrails } from '../../components/TripTrails';
import './trail.css';
import { TrailsCardList } from '../../components/TrailsCardList';
import useTabPermissions from '../../hooks/useTabPermissions';
import { TitleContext } from '../../utils/TitleContext';

const TabPanel = props => {
    const { index, value, children } = props;
    return (
        <React.Fragment>
            {value === index && (

                <Typography>{children}</Typography>

            )}
        </React.Fragment>
    )
}

export const TrailsList = () => {
    const [value, setValue] = React.useState(0);
    const { setTitle } = React.useContext(TitleContext);

    const tabView = useTabPermissions('trails')

    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => { setTitle("Trails") }, [])
    


    return (

        <Box sx={{ width: '100%', typography: 'body1' }} className="trail-page">

            <Tabs value={value} onChange={handleChange}>
                {tabView && (tabView?.viewTrails) && <Tab label="Trails" />}
                {tabView && (tabView?.viewTripTrails) && <Tab label="Trip/Trail" />}
            </Tabs>
            {/* <Box className="map-content"> */}
            <TabPanel value={value} index={0}>
                {tabView && (tabView?.readTrails) && <TrailsCardList></TrailsCardList>}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {tabView && (tabView?.readTripTrails) && <TripTrails></TripTrails>}
            </TabPanel>
            {/* </Box> */}
        </Box>
    );
}
