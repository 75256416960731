import React, { useState, useEffect, useRef} from "react";
import {
  Datagrid,
  useRecordContext,
  useTranslate,
  DateField,
  TextField,
  ExportButton,
  useListController,
  TextInput,
  Pagination,
  TopToolbar,
  ListContextProvider,
  useGetOne,
  useRefresh,
  ReferenceField,
  useGetList,
  useRedirect,
  Confirm,
} from "react-admin";
import Button from "@mui/material/Button";
import moment from "moment";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "../views/group/group.css";
import { AddEntity } from "./AddEntity";
import VTSListToolbar from "../components/VTSListToolbar";
import { Box, LinearProgress, Typography } from "@mui/material";
import { EntityOtpSend } from "./EntityOtpSend";
import { UnTagEntity } from "./UnTagEntity";
import Completed from "../images/resolved.png";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VehicleIconField from "./vehicleIconField";
import UploadIcon from "@mui/icons-material/FileUploadOutlined";
import ExportIcon from "@mui/icons-material/GetAppOutlined";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import vtsDataProvider, { GetEditEntity, UploadExcelFile } from "../providers/dataProvider";
import XLIcon from "@mui/icons-material/TextSnippetOutlined";
import { AutocompleteInput } from "react-admin";
import { Popover, Whisper } from "rsuite";
import { default as ReactSelect } from "react-select";
import useMediaQuery from '@mui/material/useMediaQuery';
import TagIcon from '@mui/icons-material/LocalOfferOutlined';
import CloseIcon from '@mui/icons-material/HighlightOffOutlined';
import urls from "../constants";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import SendNotification from "../utils/sendNotification";

const errorstyle = {
  color: "red",
  fontSize: "12px",
};

export const EntityList = ({ createper, updateper, deleteper, importper }) => {
  const [filter, setFilter] = useState("");
  const [open, setOpen] = useState(false);
  const [bulkopen, setBulkOpen] = useState(false);
  const [untagopen, setUntagOpen] = useState(false);
  const [tagentityId, settagEntityId] = useState(0);
  const [entityId, setEntityId] = useState(0);
  const [otpverified, setOTPVerified] = useState(false);
  const [deviceId, setdeviceId] = useState(0);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileUploading, setfileUploading] = useState(false);
  const [UpdateStatus, setUpdateStatus] = useState("");
  const [ErrorMessage, setUploadErrors] = useState(""); 
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [makeList, setmakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [filteredData, setFilteredData] = useState([
    {
      label: "Select a Make proceed!",
      value: "0",
      showlabel: "Select a Make to proceed!",
    },
  ]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [vltdMake, setVltdMake] = useState();
  const [vltdModel, setVltdModel] = useState();
  const [notifyData, setNotifyData] = useState({});
  const [deviceData, setDeviceData] = useState({
    vltdMake: null,
    vltdModel: null,
  });
  const [fileErr, setFileErr] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    deleteId: null,
  })

  const refresh = useRefresh()
  const navigate = useNavigate()
  const translate = useTranslate();

  const { data: makedropList } = useGetList("makedrop");
 
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }); 

  useEffect(() => {
    if (makedropList) {
      const list = formatrtodropdown(makedropList);
      const dtaModel = formatDeviceModel(makedropList);
      setmakeList(list);
      setModelList(dtaModel);
    }
  }, [makedropList]);

  const formatrtodropdown = (list) => {
    var te = list?.map((data, index) => ({
      // label: (data?.id).toString() + " - " + (data?.name).toString(),
      label: data?.make,
      value: data?.id,
      showlabel: data?.make,
      model: data?.model,
    }));
    return te;
  };

  const formatDeviceModel = (list) => {
    var te = list?.map((data) => ({
      label: data?.model,
      value: data?.value,
    }));
    return te;
  };

  useEffect(() => {
    if (vltdMake == null && vltdModel == null) {
      setDeviceData({
        vltdMake: vltdMake?.label,
        vltdModel: vltdModel?.label,
      });
    } else if (vltdMake != null && vltdModel != null) {
      setDeviceData({
        vltdMake: vltdMake?.label,
        vltdModel: vltdModel?.label,
      });
    }
  }, [vltdMake, vltdModel]);

  const [currentEntity, setCurrentEntity] = useState({
    id: null,
    attributes: {},
    groupId: null,
    name: null,
    uniqueId: null,
    status: null,
    positionId: null,
    geofenceIds: [],
    phone: "",
    model: "",
    category: "",
    disabled: false,
    uniqueidno: "",
    vltd_make: "",
    vltdmodel: "",
    imei: "",
    iccid: "",
    model_code: "",
    simno1: "",
    network1: "",
    imsi1: "",
    sim1_expiry: "",
    simno2: "",
    network2: "",
    imsi2: "",
    sim2_expiry: "",
    flag: false,
    regno: "",
    vstate: "",
    rto_code: "",
    make: "",
    chasisno: "",
    engineno: "",
    manufacture_year: "",
    permit_holder: "",
    contact: "",
    createdon: new Date(),
    otpverified: false,
    altContactPerson: "",
    altPhone: "",
  }); 

  const handleVltdMakeChange = (selectedOption) => {
    setVltdMake(selectedOption);
  };

  const handleVltdModelChange = (selectedOption) => {
    setVltdModel(selectedOption);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const sendOTP = (event, entityId) => {
    setEntityId(entityId);
    handleOpen();
  };

  const sendTagOTP = (event, entityId) => {
    settagEntityId(entityId);
    handleUntagOpen();
  };  

  const downloadcertificate = (event, otpverified, deviceId) => {
    window.location.href =
    urls.DOWNLOAD_CERTIFICATE_URL + deviceId;
  };

  const downloadSamplefile = () => {
    //downloadFileAtURL("https://cgvtsapi.trackolet.in/api/download/vehicle/sample");
    window.location.href = urls.DOWNLOAD_SAMPLE_VEHICLE_URL;
  };

  const perPage = 10;
  const filters = [
    // <AutocompleteInput
    //   source="make"
    //   optionValue="value"
    //   optionText="label"
    //   label="Select Make"
    //   choices={makeList}
    //   size="small"
    //   style = {{fontSize:10}}
    //   alwaysOn
    // />,
    <Box className="sel-group" alwaysOn>
      <ReactSelect
        name="vltdMake"
        source="make"
        options={makeList}
        placeholder="Select Vltd Make"
        onChange={handleVltdMakeChange}
        value={vltdMake}
      />
    </Box>,
    <Box className="sel-group" alwaysOn>
      <ReactSelect
        name="vltdModel"
        source="model"
        options={filteredData}
        placeholder="Select Vltd Model"
        onChange={handleVltdModelChange}
        value={vltdModel}
      />
    </Box>,
    <TextInput label="Search" source="q" alwaysOn />,
  ];

  const { data, isLoading, page, setPage, setPerPage, ...listContext } =
    useListController({
      resource: "groups",
      filter: {
        device: deviceData,
      },
    });
   
  const setFilters = (filter) => setFilter(filter.q);
  const sort = { field: "id", order: "ASC" };
  const filterValues = { q: filter };

  const totalEntity =
    typeof listContext?.total === "undefined"
      ? 0
      : listContext?.total?.toString();

  useEffect(() => {
    var queryvalue = document.querySelectorAll(".list-content");
    if (queryvalue.length > 0) {
      queryvalue[0].scrollTop = 0;
    }
  });

  const handleBulkOpen = () => {
    setFileName("");
    setFile(null);
    setBulkOpen(true);
  };
  const handleBulkClose = () => {
    setUploadErrors("");
    setBulkOpen(false);
  };

  const FileupSuccess = () => {
    setBulkOpen(false);
    setSuccessDialog(true);
  };

  const FileupClose = () => {
    setSuccessDialog(false);
  };

  const Buttonstyle = {
    position: "absolute",
    background: "#4646F2",
    color: "#fff",
    zIndex: 1,
    textTransform: "capitalize",
    width: "147px",
    border: "1px solid #4646F2 !important",
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 320,
    bgcolor: "background.paper",
    boxShadow: 1,
    p: 4,
    borderRadius: "4px",
  };

  const untagModalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 420,
    bgcolor: "background.paper",
    boxShadow: 1,
    p: 4,
    borderRadius: "8px"
  };

  const matches = useMediaQuery('(min-width:1800px)');


 const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };
  
  const GroupActions = () => (
    <TopToolbar className="Entitty-toolbar">
      {/* Add your custom actions */}
      {/* <Button className="Export-entity">Export</Button> */}
      {data?.length>0 && (<ExportButton maxResults={99999999} className="Export-entity" startIcon={<ExportIcon />} />)}
      {importper && (
        <>
          <Button
            className="uploadbtn"
            startIcon={<UploadIcon />}
            onClick={handleBulkOpen}
          >
            Upload Bulk
          </Button></>)}
       {createper && (<><Link to='/addentity'><Button
            className="Addentity"
            startIcon={<AddIcon />}
            sx={Buttonstyle}
          >
            Assign A Device
          </Button></Link>
        </>
      )}
    </TopToolbar>
  );

  const VerificationField = ({ source }) => {
    const record = useRecordContext();
    if (record["otpverified"] === true) {
      return (
        <>
          <div className="completed-field">
            <img src={Completed} />
            Completed
          </div>
        </>
      );
    } else if (record[source] === true) {
      return (
        <>
          <Button
            className="show-btn"
            onClick={(event) => sendOTP(event, record["id"])}
          >
            Send OTP
          </Button>
        </>
      );
    } else {
      return (
        <>
          <div>Pending</div>
        </>
      );
    }
  };

  const CertificateField = ({ source }) => {
    const record = useRecordContext();

    if (record[source] === true) {
      return (
        <>
          <Button
            className="show-btn"
            onClick={(event) =>
              downloadcertificate(event, record[source], record["id"])
            }
          >
            Download
          </Button>
        </>
      );
    } else {
      return (
        <>
          <div className="verific-class">-Verfication required-</div>
        </>
      );
    }
  };

  const SOSstatus = ({ source }) => {
    const record = useRecordContext();
    if (record[source] === true)
      return (
        <>
          <div>Completed</div>
        </>
      );
    else
      return (
        <>
          <div>Pending</div>
        </>
      );
  };

  const DateSetter = ({ source }) => {
    const record = useRecordContext();
    const dt =
      record[source] !== null
        ? moment(record[source]).format("DD MMM YYYY")
        : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  const YearMade = ({ source }) => {
    const record = useRecordContext();
    const dt =
      record[source] !== null && record[source] !== 0 ? record[source] : "";
    return (
      <>
        <span>{dt}</span>
      </>
    );
  };

  const TagField = ({ source }) => {
    const record = useRecordContext();

    if (record[source] === true) {
      return (
        <>
           <span  onClick={(event) => sendTagOTP(event, record["id"])} className="tagfield" title="Untag">
          <TagIcon
          sx={{ fill: "#2E2C33", fontSize: "12px", marginRight: "2px" }}
        />
        </span>
        </>
      );
    } else {
      return (
        <>
          <span className="tagfield"></span>
        </>
      );
    }
  };


  const handleUntagOpen = () => {
    setUntagOpen(true);
  };
  const handleUntagClose = () => {
    setUntagOpen(false);
  };

  
  
  const Identitypop = ({ source }) => {
    const record = useRecordContext();
    return (
      <Whisper
        followCursor
        speaker={
          <Popover className="pop-over">
            <p>
              Device Make:
              <span>
                {record["vehicleMake"] !== "" && record["vehicleMake"] !== null
                  ? record["vehicleMake"]
                  : ""}
              </span>
            </p>
            <p>
              Model:
              <span>
                {record["vehicleModel"] !== "" && record["vehicleModel"] !== null
                  ? record["vehicleModel"]
                  : ""}
              </span>
            </p>
          </Popover>
        }
      >
        <span>{record[source]}</span>
      </Whisper>
    );
  };

  const Registerpop = ({ source }) => {
    const record = useRecordContext();
    return (
      <Whisper
        followCursor
        speaker={
          <Popover className="pop-over">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <p>
                  State:
                  <span>
                    {record["vstate"] !== "" && record["vstate"] !== null
                      ? record["vstate"]
                      : "_____"}
                  </span>
                </p>
                <p>
                  Make:
                  <span>
                    {record["vehicleMake"] !== "" &&
                    record["vehicleMake"] !== null
                      ? record["vehicleMake"]
                      : "_____"}
                  </span>
                </p>
                <p>
                  Year:
                  <span>
                    {record["manufacture_year"] !== null &&
                    record["manufacture_year"] !== 0
                      ? record["manufacture_year"]
                      : "_____"}
                  </span>
                </p>
                <p>
                  Permit contact:
                  <span>
                    {record["contact"] !== "" && record["contact"] !== null
                      ? record["contact"]
                      : "_____"}
                  </span>
                </p>
                <p>
                  Driver number:
                  <span>
                    {record["altPhone"] !== "" && record["altPhone"] !== null
                      ? record["altPhone"]
                      : "_____"}
                  </span>
                </p>
              </Grid>
              <Grid item xs={6}>
                <p>
                  RTO code:
                  <span>
                    {record["rto_code"] !== "" && record["rto_code"] !== null
                      ? record["rto_code"]
                      : "_____"}
                  </span>
                </p>
                <p>
                  Model:
                  <span>
                    {record["vehicleModel"] !== "" &&
                    record["vehicleModel"] !== null
                      ? record["vehicleModel"]
                      : "_____"}
                  </span>
                </p>
                <p>
                  Permit Holder:
                  <span>
                    {record["permit_holder"] !== "" &&
                    record["permit_holder"] !== null
                      ? record["permit_holder"]
                      : "_____"}
                  </span>
                </p>
                <p>
                  Driver Name:
                  <span>
                    {record["altContactPerson"] !== "" &&
                    record["altContactPerson"] !== null
                      ? record["altContactPerson"]
                      : "_____"}
                  </span>
                </p>
              </Grid>
            </Grid>
          </Popover>
        }
      >
        <span>{record[source]}</span>
      </Whisper>
    );
  };

  const Iccidpop = ({ source }) => {
    const record = useRecordContext();
    return (
      <Whisper
        followCursor
        speaker={
          <Popover className="pop-over">
            <p>
              SIM1 number:<span>{record["simno1"]}</span>
            </p>
            <p>
              SIM1 expiry:
              <span>
                {record["sim1_expiry"] !== null
                  ? moment(record["sim1_expiry"]).format("DD MMM YYYY")
                  : "_____"}
              </span>
            </p>
            <p>
              SIM2 number:<span>{record["simno2"]}</span>
            </p>
            <p>
              SIM2 expiry:
              <span>
                {record["sim2_expiry"] !== null
                  ? moment(record["sim2_expiry"]).format("DD MMM YYYY")
                  : "_____"}
              </span>
            </p>
          </Popover>
        }
      >
        <span>{record[source]}</span>
      </Whisper>
    );
  };

  const Activationpop = ({ source }) => {
    const record = useRecordContext();
    return (
      <Whisper
        followCursor
        speaker={
          <Popover className="pop-over">
            <p>
              Reciept No:
              <span>
                {record["activationRcptNo"] !== ""
                  ? record["activationRcptNo"]
                  : "______"}
              </span>
            </p>
          </Popover>
        }
      >
        <span>{record[source]}</span>
      </Whisper>
    );
  };

  const handleConfirmClose = () => {
    setDeleteDialog({
      open: false,
      deleteId: null
    });
  };

  const handleConfirmDelete = () => {
    if (deleteDialog.deleteId !== null) {
      vtsDataProvider.delete('addentity', { id: deleteDialog.deleteId }, setNotifyData, "Entity Deleted Succesfully", "Failed to Delete");
      handleConfirmClose();
    }
  };

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const handleDeleteClick = (event, row) => {
    setDeleteDialog({
      open: true,
      deleteId: row?.id
    });
  };

  const handleEditClick = async (event, row) => {
    // setCurrentEntity({
    //   id: row?.id,
    //   groupId: row?.groupId,
    //   name: row?.name === null ? "" : row?.name,
    //   uniqueId: row?.uniqueId === null ? "" : row?.uniqueId,
    //   status: row?.status,
    //   lastUpdate: row?.lastUpdate,
    //   positionId: row?.positionId === null ? 0 : row?.positionId,
    //   geofenceIds: row?.geofenceIds === null ? [] : row?.geofenceIds,
    //   phone: row?.phone === null ? "" : row?.phone,
    //   vehicleModel: row?.vehicleModel === null ? "" : row?.vehicleModel,
    //   contact: row?.contact,
    //   category: row?.category === null ? "" : row?.category,
    //   disabled: row?.disabled,
    //   vltdmodel: row?.vltdmodel,
    //   serialNo: row?.serialNo,
    //   iccid: row?.iccid,
    //   createdon: row?.createdon,
    //   vltd_make: row?.vltd_make,
    //   simno1: row?.simno1,
    //   network1: row?.network1,
    //   sim1_expiry: row?.sim1_expiry
    //     ? moment(row.sim1_expiry).format("YYYY-MM-DD")
    //     : null,
    //   simno2: row?.simno2,
    //   network2: row?.network2,
    //   sim2_expiry: row?.sim2_expiry
    //     ? moment(row.sim2_expiry).format("YYYY-MM-DD")
    //     : null,
    //   vstate: row?.vstate,
    //   rto_code: row?.rto_code,
    //   vehicleMake: row?.vehicleMake,
    //   chasisno: row?.chasisno,
    //   engineno: row?.engineno,
    //   manufacture_year: row?.manufacture_year
    //   ? moment(row.manufacture_year).format("YYYY-MM-DD")
    //   : null,
    //   permit_holder: row?.permit_holder,
    //   sos_status: row?.sos_status,
    //   sos_eventid: row?.sos_eventid,
    //   otpverified: row?.otpverified,
    //   altContactPerson: row?.altContactPerson,
    //   altPhone: row?.altPhone,
    //   groupName: row?.groupName,
    //   deviceActivationStatus: row?.deviceActivationStatus,
    //   iccIdValidUpto: row?.iccIdValidUpto,
    //   activationRcptNo: row?.activationRcptNo,
    //   deviceActivatedUpto: row?.deviceActivatedUpto,
    //   deviceActivationDate: row?.deviceActivationDate,
    //   createdBy: row?.createdBy,
    //   simProviderName:row?.simProviderName
    // });
   
    const res = await GetEditEntity('addentity', {id: row?.id})
    navigate("/addentity", { state: res?.data });
  };


  const CustomActionButton = () => {
    const record = useRecordContext();
    return (
      <>
        {!record["otpverified"] && (
          <>
          <span>
            <EditIcon onClick={(event) => handleEditClick(event, record)} />
          </span>
          <span>
          <DeleteIcon  onClick={(event) => handleDeleteClick(event, record)}/>
        </span>
        </>
        )}
      </>
    );
  };  

  const handlefileChange = (e) => {
    let validExt = ".xls, .xlsx";
    let getFileExt = e.target.files[0]?.name.substring(e.target.files[0]?.name.lastIndexOf('.') + 1).toLowerCase();
    const pos = validExt.indexOf(getFileExt);
    if(pos < 0) {
      setFileErr(true)
     } 
    else {
      setFileErr(false)
     }

    setFile(e.target.files[0]);
    setFileName(e.target.files[0]?.name);
  };

  const UploadFile = async () => {
    if (fileName === "") {
      // alert("Please Upload File");
      setUploadErrors("Please Upload File");
      return;
    }
   if(fileName !== "" && fileErr){
    setUploadErrors("Not allowed, Please upload valid file");
    return;
   }

    setUploadErrors("");
    setfileUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    const response = await UploadExcelFile(
      "deviceUpload",
      formData,
      setUpdateStatus
    );
    FileupSuccess();
    const timer = setTimeout(() => {
      setFileName("");
      setfileUploading(false);
      setFile(null);
    }, 800);
  };

  useEffect(() => {
    if (vltdMake != null) {
      const mList = makeList?.filter((obj) => obj?.label == vltdMake?.label);
      if (mList?.length == 0) {
        const mm = [
          {
            label: "NO Models!",
            value: "0",
            showlabel: "NO Models!",
          },
        ];
        setFilteredData(mm);
      } else {
        const dtaModel = formatDeviceModel(mList);
        setFilteredData(dtaModel);
      }
    }
  }, [vltdMake]);

const downloadFileAtURL = (url) =>{

  // fetch(url,
  // { 
  //     method: "GET",
  //     headers: { "Content-Type": "application/json",'Authorization': 'Bearer ' + localStorage.getItem("authToken")}
  // }).then(response => response.blob())
  // .then((blob) => {
  //   const blobURL = window.URL.createObjectURL(new Blob([blob]))
  //   const fileName = url.split("/").pop();
  //   const aTag = document.createElement("a");
  //   aTag.href = blobURL;
  //   aTag.setAttribute("download",fileName);
  //   document.body.appendChild(aTag);
  //   aTag.click();
  //   aTag.remove();
  // })


  fetch(url)
  .then((response) => response.blob())
  .then((blob) => {
    const blobURL = window.URL.createObjectURL(new Blob([blob]))
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = blobURL;
    aTag.setAttribute("download",fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  })
}

  const CustomEmpty = () => <div className="no-records">No Records</div>;   


  if (isLoading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  } else
    return (
      <div className="Entity-page">
        <ListContextProvider
          debounce={8000}
          value={{
            data,
            filterValues,
            page,
            setFilters,
            setPage,
            setPerPage,
            ...listContext,
          }}
        >
          <VTSListToolbar
            filters={filters}
            actions={<GroupActions />}
            setVltdMake={setVltdMake}
            setVltdModel={setVltdModel}
            type="entityRefresh"
          />
          {/* {data === undefined ||
            (Array.isArray(data) === true && data.length === 0) ? (
            <>
              <div className='no-records'>Data Not Available</div>
            </>
          ) : (
            <> */}
          {matches == true ? (<Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />) 
          : (<Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />)}
          <Box className="list-content">
            <Datagrid bulkActionButtons={false} empty={<CustomEmpty />}>
              {/* <TextField source="id" label="Id" /> */}
              <Identitypop source="serialNo" label="Serial Number" />
              <TextField source="uniqueId" label="IMEI" />
              {/* <Iccidpop label="ICCID" source="iccid" /> */}
              {/* <TextField source="vltd_make" label="Vltd Make" /> */}
              <TextField source="vltdmodel" label="Vltd Model" />
              {/* <Activationpop
                label="Activation Status"
                source="deviceActivationStatus"
              /> */}
              {/* <DateSetter
                label="Device Activated Upto"
                source="deviceActivatedUpto"
              />
              <DateSetter
                label="Device Activation Date"
                source="deviceActivationDate"
              /> */}
              <VehicleIconField source="category" label="Vehicle Type" />
              {/* <TextField source="chasisno" label="Chasis Number" /> */}
              <Registerpop source="name" label="Registration Name" />
              <TextField source="status" label="Vehicle status" />
              <TextField source="vstate" label="State" />
              <TextField source="constituencyName" label="Constituency" />
              <TextField source="sectorNumber" label="Vahan Prabhari Contact" />
              <TextField source="magistrateName" label="Vahan Prabhari" />
              <TextField source="vehicleType" label="Vehicle Category" />
              {/* <SOSstatus source="sos_status" label="SOS Testing Status" /> */}
              {/* <VerificationField label="Verification" source="sos_status" /> */}
              {/* <CertificateField label="Certificate" source="otpverified" /> */}
              <span label="Action" className="action-icons">
              {updateper && (<>
                    <CustomActionButton label="Action" source="id" />
                    <TagField label="Action" source="otpverified" /></>
              )}
               </span>
            </Datagrid>
          </Box>

          {/* </>
          )} */}
        </ListContextProvider>
        {/* {<AddEntity
          currentEntity={currentEntity}
          handleEntityClose={handleEntityClose}
          listContext={listContext}
          setCurrentEntity={setCurrentEntity}
        ></AddEntity> }       */}
        <EntityOtpSend
          open={open}
          handleClose={handleClose}
          entityId={entityId}
        ></EntityOtpSend>
        <UnTagEntity
          untagopen={untagopen}
          handleUntagClose={handleUntagClose}
          entityId={tagentityId}
          setUntagOpen={setUntagOpen}
        ></UnTagEntity>

        {/* Bulk Open */}
        <Modal
          open={bulkopen}
          onClose={handleBulkClose}
          className="bulkup-modal"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Bulk Upload
            </Typography>
            <div style={errorstyle}>{ErrorMessage}</div>
            <form id="file-upload-form" class="uploader">
              <input
                id="file-upload"
                type="file"
                name="fileUpload"
                onChange={handlefileChange}
              />
              <label for="file-upload">
                <div class="imagePreview">
                  <div id="start">
                    <UploadIcon />
                    <p>Select a file to import or Drag & Drop here</p>
                  </div>
                  <p>Only .xls or .xlsx format</p>
                  {fileName == "" ? (
                    <></>
                  ) : (
                    <span>
                      <XLIcon />
                      {fileName}
                    </span>
                  )}
                </div>
              </label>
            </form>
            {/* {fileName!=="" && <div sx={{fontSize:"10px"}}>Selected File : {fileName}</div>} */}
            <a
              href={urls.DOWNLOAD_SAMPLE_VEHICLE_URL}
            //  onClick={downloadSamplefile}
              download
              className="normal-a"
            >
              Click here to download sample excel sheet
            </a>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  className="cancel-btn"
                  color="primary"
                  sx={{ marginRight: "1em" }}
                  onClick={handleBulkClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  className="upload-btn"
                  onClick={UploadFile}
                  //onClick={() => FileupSuccess()}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Snackbar
          open={successDialog}
          autoHideDuration={6000}
          onClose={FileupClose}
          className="sucess-file-alert"
        >
          {UpdateStatus?.errors?.length > 0 ||
          UpdateStatus?.message === "ERROR TO UPLOAD" ? (
            <Alert severity="error" sx={{ width: "100%" }}>
              <h4>Status</h4>
              <p style={{fontWeight: "bold"}}>{UpdateStatus?.message}</p>
              {UpdateStatus?.errors?.length > 0
                ? UpdateStatus?.errors?.map((errormsg, index) => {
                    return <p>{errormsg?.id}</p>;
                  })
                : ""}
            </Alert>
          ) : (
            <Alert severity="success" sx={{ width: "100%" }}>
              <h4>Status</h4>
              {UpdateStatus?.length > 0 && UpdateStatus?.map(ob =>{
                return(
                  <>
                    <p style={{fontWeight: "bold"}}>{ob?.uniqueId}</p>
                    <p style={{fontWeight: "bold"}}>{ob?.message}</p>
                  </>                 
                )
              })}
            </Alert>
          )}
        </Snackbar>
        <Confirm
          isOpen={deleteDialog?.open}
          title="Delete Entity"
          content="Are you sure you want to delete this entity?"
          onConfirm={handleConfirmDelete}
          onClose={handleConfirmClose}
        />
        <SendNotification
          data={notifyData}
          onClose={() => setNotifyData({})}
            />
      </div>
    );
};
