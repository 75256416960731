import React, { useEffect } from "react";
import { ListContextProvider } from 'react-admin';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Tabs, Tab } from '@mui/material';
import "./group.css";
import { GroupTab } from './groupList';
import { AuditTrials } from './auditList';
import { EntityList } from '../../components/EntityList'
import useTabPermissions from "../../hooks/useTabPermissions";
import { TitleContext } from '../../utils/TitleContext';

const TabPanel = props => {
  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && (

        <Typography>{children}</Typography>

      )}
    </React.Fragment>
  )
}

export const GroupsList = () => {

  const [value, setValue] = React.useState(0);
  const { setTitle } = React.useContext(TitleContext);

  const tabView = useTabPermissions('groups')

  useEffect(() => { setTitle("Groups/Assets") }, [])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  


  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Tabs value={value} onChange={handleChange}>
        {tabView && (tabView?.viewEntity) && <Tab label="Entity" />}
        {tabView && (tabView?.viewGroup) && <Tab label="Group" />}
        {/* <Tab label="Audit Trails" /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        {tabView && (tabView?.readEntity) &&
          (<EntityList
            createper={tabView.createEntity}
            updateper={tabView.updateEntity}
            deleteper={tabView.deleteEntity}
            importper={tabView?.importExcel}
            >
          </EntityList>)}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {tabView && (tabView?.readGroup) && (<GroupTab createper={tabView.createGroup}
          updateper={tabView.updateGroup}
          deleteper={tabView.deleteGroup} />)}
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
                <AuditTrials />
            </TabPanel> */}
    </Box>
  );

}
