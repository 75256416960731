import React, { Component, useState ,useEffect} from 'react';
import Highcharts from 'highcharts';
import {
  HighchartsChart, Chart, withHighcharts, XAxis, YAxis, Title, Subtitle, Legend, LineSeries, Caption, Tooltip
} from 'react-jsx-highcharts';
import moment from 'moment';

const plotOptions = {
  tooltip: {
    borderColor: '#2c3e50',
    shared: true,
    formatter: function (tooltip) {
     // console.log("tooltip##",tooltip)
      const header = `<span style="color: blue;">${this.x} - ${this.x.replace(/:00/, ':59')}</span><br/>`
      
      return header + (tooltip.bodyFormatter(this.points).join(''))
    }
  },
};


const Trailchart = ({ reportsRoute}) => {

  const [speed, setSpeed] = useState([])
  const [battery, setBattery] = useState([])
  const [ignition, setIgnition] = useState([])
  const [deviceTime, setDeviceTime] = useState([])

  useEffect(() => {

   // console.log("reportsRoute",reportsRoute)
    if (reportsRoute) {
      if (reportsRoute.length > 0) {
        let speedarr = [];
        let batteryarr  = [];
        let ignitionarr = [];
        let devicetimearr = [];
        let dayarr = [];
        const Dateformat = (source) => {
          const dt = (source !== null) ? moment(source).format("hh:mm:ss") : "";
          return dt;
      };
        reportsRoute.map((data, index)=>{
          speedarr.push(data.speed);
          batteryarr.push(data.attributes?.battery?data.attributes?.battery:0);
          ignitionarr.push(data.ignition?data.ignition:0);
          const devicet = Dateformat(data.deviceTime);
          const devicet1 = moment(data.deviceTime).format("DD MMM");
          dayarr.push(devicet1)
      //    let devicet = data.deviceTime


      const [date, time] = devicet
      const[hours, min, sec] = devicet.split(':')

     //console.log("dataDevTime", devicet, "Days:" + devicet1)
      // console.log("dataDevTime2", typeof(devicet))
      //     const showTime = devicet.getHours() 
      //     + ':' + devicet.getMinutes() 
      //   //  + ":" + date.getSeconds();

      if(devicet1 !== dayarr[index-1])
      devicetimearr.push(devicet1)
      else
         devicetimearr.push(hours+":"+min)

  
        });

        setSpeed(speedarr);
        setBattery(batteryarr);
        setIgnition(ignitionarr);
        setDeviceTime(devicetimearr)

    }
  }
  }, [reportsRoute])

  

  // useEffect(()=>{
  //   console.log("deviceTime",deviceTime)
  //   console.log("speed",speed)
  //   console.log("battery",battery)
  //   console.log("ignition",ignition)

  // },[speed,battery,ignition, deviceTime])
  return(
  <div className="app">
    <HighchartsChart>
      <Chart />

      <Legend layout="vertical" align="right" verticalAlign="middle" />
      <XAxis type="datetime" categories={deviceTime} >
        <XAxis.Title>Time</XAxis.Title>
        {/* <LineSeries name="deviceTime" data={deviceTime} /> */}
      </XAxis>

      <YAxis>
        <YAxis.Title>Speed</YAxis.Title>
        <LineSeries name="ignition" data={ignition} />
        <LineSeries name="Speed" data={speed} />
        <LineSeries name="Battery" data={battery} />
      </YAxis>
      <Tooltip shared formatter={function (tooltip, x = this.x, points = this.points) {
            let s = `<b>${x}</b>`;
            points.forEach((point) =>
              s += point.series.name === "ignition"?`<br/>${point.series.name}: ${point.y === 0?"off":"on"}`:`<br/>${point.series.name}: ${point.y}`
            );
        
            return s;
        }}></Tooltip>
    </HighchartsChart>
  </div>
);
  }

export default withHighcharts(Trailchart, Highcharts);