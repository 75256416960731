import React, {useEffect, useCallback, useRef, useState} from "react";
import moment from "moment";
import { Button, useGetList, useListController, ExportButton,useInfiniteGetList } from "react-admin";
import ExportIcon from "@mui/icons-material/GetAppOutlined";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import TrainIcon from "@mui/icons-material/TrainOutlined";
import GpsIcon from "@mui/icons-material/MyLocationOutlined";
import ViewmapIcon from "../../images/Viewmap.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import urls from "../../constants";
import {
  Box,
  Typography,
  FormLabel,
  Card,
  Checkbox,
} from "@mui/material";
import { Popover, Whisper } from "rsuite";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  flexRow: { display: "flex", flexDirection: "row" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  alignEnd: {alignItems:"end", justifyContent:"end"},
  marginRight: {marginRight:"0.5rem"},
  shortWidth: {width:"145px"}
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const steps = ["", "", ""];

const OngoingTrips = () => {
  const [age, setAge] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const fromDatePickerRef = React.useRef(null);
  const toDatePickerRef = React.useRef(null);
  let todate = new Date();
  let prvDate = new Date(
    todate.getFullYear(),
    todate.getMonth() - 1,
    todate.getDate()
  );
  const [startDate, setStartDate] = React.useState(prvDate);
  const [endDate, setEndDate] = React.useState(new Date());
  const [search, setSearch] = React.useState("");
  const [searchFilter, setSearchFilter] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

const handleChangeStartDate = (dates) => {
  setStartDate(dates)
}
const handleChangeEndDate = (dates) => {
  setEndDate(dates)
}
const handleFromDatePicker = () => {
  const dp = fromDatePickerRef.current;
  dp.setOpen(true);
};
const handleToDatePicker = () => {
  const dpt = toDatePickerRef.current;
  dpt.setOpen(true);
};
  const convertdate = (dvalue) => {
    // if(dvalue !=="")
    // {
    // var ms = parseInt("/Date("+dvalue+")/".match(/\((\d+)\)/)[1]);
    // var d = new Date(ms);
    // return d.toString();
    // }
    // else
    return ""
  }

  //const { data, isLoading, ...listContext } = useGetList("ongoingtrips");

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteGetList("ongoingtrips", {
      pagination: { page: 1, perPage: 10 },
      filter:{searchKey:search}
    });


    const observerElem = useRef(null);

    const handleObserver = useCallback(
      (entries) => {
        const [target] = entries;
        if (target.isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      [fetchNextPage, hasNextPage]
    );

    useEffect(() => {
      const element = observerElem.current;
      if (!element) return;
      const option = { threshold: 0 };
      const observer = new IntersectionObserver(handleObserver, option);
      observer.observe(element);
      return () => observer.unobserve(element);
    }, [fetchNextPage, hasNextPage, handleObserver]);

    const HandleSearchChange = (e) =>{
      setSearch(e.target.value);
    }

    const SearchClick = () =>{
      setSearchFilter(search);
    }

  return (
    <Card
      sx={{
        minHeight: 52,
        marginTop: "0px",
        display: "flex",
        flexDirection: "column",
        marginBottom:"0rem",
        marginRight:"0px !important",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit",
        },
      }}
    >
      <Box
        sx={{
          overflow: "inherit",
          padding: "0",
          height: "530px",
          background: (theme) =>
            `url(${theme.palette.mode === "dark" ? "" : ""}) no-repeat`,

          justifyContent: "space-between",
          alignItems: "center",
          "& .icon": {
            color: (theme) =>
              theme.palette.mode === "dark" ? "inherit" : "#dc2440",
          },
        }}
        className="ongoing-left"
      >
         
        <Box sx={{display:"flex", justifyContent:"space-between"}}>
        <Typography color="textSecondary" component="h2">
          Ongoing Trips
        </Typography>
          <Box
                sx={{padding: "0px 0px 0 16px", marginLeft: "0px", display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center", height
              :"100%" }}
                textAlign="center"
              >
                <Typography color="textSecondary" component="h2">
                  {data?.pages?.length>0?data?.pages[0]?.total > 0 ? data?.pages[0]?.total : 0:0}
                </Typography>
                <Typography color="textSecondary" component="p">
                  Total
                </Typography>
          </Box>
        </Box>
        <Box sx={{marginBottom:"10px" }}>
          <div style={{...styles.flexRow, ...styles.alignEnd, ...styles.justifyContent}}>
            <Box style={{...styles.marginRight}} className="search-field">
              <Search >
                <SearchIconWrapper className="search-icon" onClick={SearchClick}>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search trip by name"
                  inputProps={{ 'aria-label': 'search' }}
                  sx={{width:"100% !important"}}
                  onChange={HandleSearchChange}
                  value={search}
                />
              </Search>
            </Box>
                <Box className="date-range" style={{...styles.marginRight, ...styles.shortWidth}} sx={{ marginTop: "0.5em" }}>
                    <FormControl sx={{marginLeft:"0 !important"}}>
                    <FormLabel>Date From</FormLabel>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => handleChangeStartDate(date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={1}
                      timeCaption="Time"
                      dateFormat="dd/MM/yyyy hh:mm aa"
                      ref={fromDatePickerRef}
                      maxDate={new Date()}
                    />
                    <IconButton
                      className="cal-icon"
                      onClick={handleFromDatePicker}
                    >
                      <CalendarIcon />
                    </IconButton>
                    </FormControl>
                </Box>
                  <Box className="date-range" style={{...styles.marginRight, ...styles.shortWidth}} sx={{ marginTop: "0.5em" }}>
                    <FormControl>
                    <FormLabel>Date To</FormLabel>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => handleChangeEndDate(date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={1}
                      timeCaption="Time"
                      dateFormat="dd/MM/yyyy hh:mm aa"
                      ref={toDatePickerRef}
                      maxDate={new Date()}
                    />
                    <IconButton
                      className="cal-icon"
                      onClick={handleToDatePicker}
                    >
                      <CalendarIcon />
                    </IconButton>
                    </FormControl>
                  </Box>
                  <Box sx={{marginTop: "1.5em", display:"flex", alignItems:"center", justifyContent:"end"}}>
                <Button
                    className="show-btn"
                    sx={{ float: "right !important", width:"64px !important"}} href={`${urls.ROUTE_EXPORT}?from=${moment.utc(startDate).format()}&to=${moment.utc(endDate).format()}`} download>
                    Export
                  </Button>
              </Box>
          </div>
          
        </Box>
        <Box>
       
        </Box>
        
        <Box className="ong-box">
          {data?.pages?.length > 0 ? (
             data?.pages?.map((page) => {
                return page?.data?.map((trip, index) => (
                <Box key={trip?.tripId} sx={{ padding: "10px" }} className="ongoing-progress">
                  <Box sx={{ padding: "10px" }}>
                    <div style={styles.flex}>
                      <div style={styles.leftCol}>
                        <Typography
                          color="textSecondary"
                          variant="h"
                          component="h4"
                          className="Card-Title"
                          sx={{
                            marginBottom: "15px",
                          }}
                        >
                          {trip?.vehicleNo}
                          <span>
                          ({(trip?.groupName != null && trip?.category != null) ? trip?.groupName + "," + trip?.category : trip?.groupName != null ? trip?.groupName : trip?.category})
                          </span>
                        </Typography>
                        <Box className="box-P">
                          <Typography
                            color="textSecondary"
                            variant="h"
                            component="p"
                          >
                           {trip.permitHolderName} - {trip.permitHolderContact}
                          </Typography>
                        </Box>
                      </div>
                      <div style={styles.rightCol}>
                      <Link to={'/vehicles'} state={{ type: 'map', status: trip, title: 'trip'}}>
                        <Button
                          variant="contained"
                          className="Card-Icons"
                          sx={{
                            borderColor: "blue",
                            marginTop: "0!important",
                            marginRight: "0px!important",
                            marginLeft: "10px",
                            border: "none !important",
                          }}
                          // startIcon={<GpsIcon sx={{ fill: "blue" }} />}
                        >
                          <img src={ViewmapIcon} />
                        </Button>
                        </Link>
                      </div>
                    </div>
                  </Box>
                 
                  <Box sx={{ width: "100%" }} className="ongoing-stepper">
                    
                  <Stepper >
                    
                      {
                        trip.tripLegSummarys.map((leg, index)=>{
                          return(
                          
                                <Step completed={leg.status === "finished"? true : false} active={leg.status === "finished"? true : false}>
                                  <Whisper
                              followCursor
                              speaker={
                                <Popover className="pop-over">
                                  <p> Start Time :<span> {leg.startTime} </span></p>
                                  <p> Distance Traveled :<span> {leg.distanceTraveled} </span></p>
                                  <p> Duration Traveled :<span> {leg.durationTraveled} </span></p>
                                  <p> Finish Time :<span> {leg.finishTime} </span></p>
                                  <p> Status :<span> {leg.status} </span></p>
                                  <p> Planned Time :<span> {leg.plannedTime} </span></p>
                                  <p> Planned Distance :<span> {leg.plannedDistance} </span></p>
                                  <p> Leg Index :<span> {leg.legIndex} </span></p>
                                  <p> Name From :<span> {trip.routeLegs[(leg.legIndex*1)-1]?.nameFrom}  </span></p>
                                  <p> Name To :<span> {trip.routeLegs[(leg.legIndex*1)-1]?.nameTo} </span></p>
                                  {/* <p>Actual Time :<span>{leg.actualTime} </span></p> */}
                                </Popover>
                                  }
                                  placement="auto"
                                >
                                  <StepLabel ></StepLabel>
                               

                              </Whisper>
                                    
                            </Step>
                            
                            
                          )
                        })
                      }
                     {trip.tripLegSummarys.length === 1 &&  
                     <Step completed={trip.tripLegSummarys[0].status === "finished"? true : false} active={trip.tripLegSummarys[0].status === "finished"? true : false}>
                       <Whisper
                              followCursor
                             speaker={
                                <Popover className="pop-over" position="top">
                                  <p> Start Time :<span> {trip.tripLegSummarys[0].startTime} </span></p>
                                  <p> Distance Traveled :<span> {trip.tripLegSummarys[0].distanceTraveled} </span></p>
                                  <p> Duration Traveled :<span> {trip.tripLegSummarys[0].durationTraveled} </span></p>
                                  <p> Finish Time :<span> {trip.tripLegSummarys[0].finishTime} </span></p>
                                  <p> Status :<span> {trip.tripLegSummarys[0].status} </span></p>
                                  <p> Planned Time :<span> {trip.tripLegSummarys[0].plannedTime} </span></p>
                                  <p> Planned Distance :<span> {trip.tripLegSummarys[0].plannedDistance} </span></p>
                                  <p> Leg Index :<span> {trip.tripLegSummarys[0].legIndex} </span></p>
                                  <p> Name From :<span> {trip.routeLegs[(trip.tripLegSummarys[0].legIndex*1)-1]?.nameFrom}  </span></p>
                                  <p> Name To :<span> {trip.routeLegs[(trip.tripLegSummarys[0].legIndex*1)-1]?.nameTo} </span></p>
                                  {/* <p>Actual Time :<span>{leg.actualTime} </span></p> */}
                                </Popover>
                                 }
                                 placement="auto"
                               >
                                  <StepLabel ></StepLabel>
                                 </Whisper>
                      </Step>}
                  </Stepper>
                </Box>
                  <div style={styles.flex}>
                    <div style={styles.leftCol}>
                      <Box className="location-box">
                        <Typography
                          color="textSecondary"
                          variant="h"
                          component="h4"
                          className="Card-Title"
                          sx={{
                            marginBottom: "15px",
                          }}
                        >
                          {trip?.routeLegs[0]?.nameFrom}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="h"
                          component="p"
                        >
                          {trip?.routeLegs[0]?.startTime}
                        </Typography>
                      </Box>
                    </div>

                    <div style={styles.rightCol}>
                      <Box className="location-box" textAlign="right">
                        <Typography
                          color="textSecondary"
                          variant="h"
                          component="h4"
                          className="Card-Title"
                          sx={{
                            marginBottom: "15px",
                          }}
                        >
                          {trip?.routeLegs[trip?.routeLegs.length - 1]?.nameTo}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="h"
                          component="p"
                        >
                          {trip?.routeLegs[trip?.routeLegs.length - 1]?.endTime}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                </Box>
              ));
            })
          ) : (
            <Typography variant="h6" style={{ textAlign: "center" }} className="no-message">
              No Ongoing Trips
            </Typography>
          )}

      {data?.pages[0]?.data?.length > 0 ? (
            <Typography ref={observerElem} variant="body2" color="grey.500">
              {isFetchingNextPage && hasNextPage
                ? "Loading..."
                : ""}
            </Typography>
          ) : (
            <Typography
              variant="h6"
              style={{ textAlign: "center" }}
              className="no-message"
            >
             No Ongoing Trips
            </Typography>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default OngoingTrips;
