import React, { useState, useEffect } from 'react';
import { useRefresh, useGetOne } from 'react-admin';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Tabs, Tab } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormLabel from "@mui/material/FormLabel";
import Grid from '@mui/material/Grid';
import TextField2 from '@mui/material/TextField';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from '@mui/material/Checkbox';
import vtsDataProvider from './../providers/dataProvider'
import "../views/users/users.css";
import { TreeViewReportComponent, TreeViewAlarmComponent, TreeViewPermissionComponent, TreeViewViewComponent } from "../components/treeview"
import SendNotification from "../utils/sendNotification";
import { useForm, useWatch } from 'react-hook-form'
import { TabPanel } from './../utils/TabPanel'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const errorstyle = {
    color: "red",
    fontSize: "12px",
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '850px !important',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Buttonstyle = {
    position: 'absolute',
    background: 'blue',
    color: '#fff',
    zIndex: 1,
    textTransform: 'capitalize',
    width: '100px'
}


let date = new Date();
export const EditUserRole = ({ listContext, EditRoleopen, handleEditRoleClose, EditRoleId }) => {
    const [notifyData, setNotifyData] = useState({});
    const [Roleopen, setRoleOpen] = React.useState(false);
    const [Rolevalue, setRoleValue] = React.useState(0);

    useEffect(() => { setRoleValue(0) }, [EditRoleopen])

    const refresh = useRefresh();
    const Rolehandle = (event, newValue) => {
        setRoleValue(newValue);
    };
    const [formValues, setFormValues] = useState({});

    const { data: EditData, isLoading, error } = useGetOne(
        'userrole',
        { id: EditRoleId },
        { enabled: (EditRoleId !== 0) }
    );

    const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm();

    useEffect(() => {
        const getEditRole = () => {
            if (EditData) {
                reset({
                    id: EditRoleId,
                    lastupdate: date,
                    rolename: EditData?.rolename,
                    createdon: EditData?.createdon,
                    roles: {
                        alarm: {
                            general: checkundefined("alarm", EditData?.roles?.alarm?.general),
                            sos: checkundefined("alarm", EditData?.roles?.alarm?.sos),
                            vibration: checkundefined("alarm", EditData?.roles?.alarm?.vibration),
                            movement: checkundefined("alarm", EditData?.roles?.alarm?.movement),
                            low_speed: checkundefined("alarm", EditData?.roles?.alarm?.low_speed),
                            overspeed: checkundefined("alarm", EditData?.roles?.alarm?.overspeed),
                            fall_down: checkundefined("alarm", EditData?.roles?.alarm?.fall_down),
                            low_power: checkundefined("alarm", EditData?.roles?.alarm?.low_power),
                            low_battery: checkundefined("alarm", EditData?.roles?.alarm?.low_battery),
                            fault: checkundefined("alarm", EditData?.roles?.alarm?.fault),
                            power_off: checkundefined("alarm", EditData?.roles?.alarm?.power_off),
                            power_on: checkundefined("alarm", EditData?.roles?.alarm?.power_on),
                            door: checkundefined("alarm", EditData?.roles?.alarm?.door),
                            lock: checkundefined("alarm", EditData?.roles?.alarm?.lock),
                            unlock: checkundefined("alarm", EditData?.roles?.alarm?.unlock),
                            geofence: checkundefined("alarm", EditData?.roles?.alarm?.geofence),
                            geofence_enter: checkundefined("alarm", EditData?.roles?.alarm?.geofence_enter),
                            geofence_exit: checkundefined("alarm", EditData?.roles?.alarm?.geofence_exit),
                            gps_antenna_cut: checkundefined("alarm", EditData?.roles?.alarm?.gps_antenna_cut),
                            accident: checkundefined("alarm", EditData?.roles?.alarm?.accident),
                            tow: checkundefined("alarm", EditData?.roles?.alarm?.tow),
                            idle: checkundefined("alarm", EditData?.roles?.alarm?.idle),
                            high_rpm: checkundefined("alarm", EditData?.roles?.alarm?.high_rpm),
                            acceleration: checkundefined("alarm", EditData?.roles?.alarm?.acceleration),
                            braking: checkundefined("alarm", EditData?.roles?.alarm?.braking),
                            cornering: checkundefined("alarm", EditData?.roles?.alarm?.cornering),
                            lane_change: checkundefined("alarm", EditData?.roles?.alarm?.lane_change),
                            fatigue_driving: checkundefined("alarm", EditData?.roles?.alarm?.fatigue_driving),
                            power_cut: checkundefined("alarm", EditData?.roles?.alarm?.power_cut),
                            power_restored: checkundefined("alarm", EditData?.roles?.alarm?.power_restored),
                            jamming: checkundefined("alarm", EditData?.roles?.alarm?.jamming),
                            temperature: checkundefined("alarm", EditData?.roles?.alarm?.temperature),
                            parking: checkundefined("alarm", EditData?.roles?.alarm?.parking),
                            bonnet: checkundefined("alarm", EditData?.roles?.alarm?.bonnet),
                            foot_brake: checkundefined("alarm", EditData?.roles?.alarm?.foot_brake),
                            fuel_leak: checkundefined("alarm", EditData?.roles?.alarm?.fuel_leak),
                            tampering: checkundefined("alarm", EditData?.roles?.alarm?.tampering),
                            removing: checkundefined("alarm", EditData?.roles?.alarm?.removing)
                        },
                        report: {
                            all: checkundefined("report", EditData?.roles?.report?.all),
                            route: checkundefined("report", EditData?.roles?.report?.route),
                            trip: checkundefined("report", EditData?.roles?.report?.trip),
                            summary: checkundefined("report", EditData?.roles?.report?.summary),
                            event: checkundefined("report", EditData?.roles?.report?.event),
                            stop: checkundefined("report", EditData?.roles?.report?.stop),
                            geofence: checkundefined("report", EditData?.roles?.report?.geofence),
                            panic: checkundefined("report", EditData?.roles?.report?.panic),
                            reportedPermitViolation: checkundefined("report", EditData?.roles?.report?.reportedPermitViolation),
                            generatedPanicViolation: checkundefined("report", EditData?.roles?.report?.generatedPanicViolation),
                            deviceStatistics: checkundefined("report", EditData?.roles?.report?.deviceStatistics),
                            deviceHistoryLogs: checkundefined("report", EditData?.roles?.report?.deviceHistoryLogs),
                            vltdOem: checkundefined("report", EditData?.roles?.report?.vltdOem)
                        },
                        permission: {
                            audit_trail: EditRoleValue("audittrail", EditData?.roles?.permission?.audit_trail),
                            command: EditRoleValue("command", EditData?.roles?.permission?.command),
                            dashboard: EditRoleValue("dashboard", EditData?.roles?.permission?.dashboard),
                            device: EditRoleValue("device", EditData?.roles?.permission?.device),
                            driver: EditRoleValue("driver", EditData?.roles?.permission?.driver),
                            event: EditRoleValue("event", EditData?.roles?.permission?.event),
                            geofence: EditRoleValue("geofence", EditData?.roles?.permission?.geofence),
                            group: EditRoleValue("group", EditData?.roles?.permission?.group),
                            // leg: EditRoleValue("leg", EditData?.roles?.permission?.leg),
                            notification: EditRoleValue("notification", EditData?.roles?.permission?.notification),
                            control_panel: EditRoleValue("control_panel", EditData?.roles?.permission?.control_panel),
                            position: EditRoleValue("position", EditData?.roles?.permission?.position),
                            route: EditRoleValue("route", EditData?.roles?.permission?.route),
                            schedule: EditRoleValue("schedule", EditData?.roles?.permission?.schedule),
                            server: EditRoleValue("server", EditData?.roles?.permission?.server),
                            sos: EditRoleValue("sos", EditData?.roles?.permission?.sos),
                            statistic: EditRoleValue("statistic", EditData?.roles?.permission?.statistic),
                            trip: EditRoleValue("trip", EditData?.roles?.permission?.trip),
                            role: EditRoleValue("role", EditData?.roles?.permission?.role),
                            user: EditRoleValue("user", EditData?.roles?.permission?.user),
                            vehicle_registration: EditRoleValue("vehiclereg", EditData?.roles?.permission?.vehicle_registration),
                            generate_permit_violation: EditRoleValue("generateper", EditData?.roles?.permission?.generate_permit_violation),
                        },
                        view:
                        {
                            dashboard_overall: EditRoleValue("dashboard_overall", EditData?.roles?.view?.dashboard_overall),
                            dashboard: EditRoleValue("dashboard", EditData?.roles?.view?.dashboard),
                            dashboard_vehicle_summary: EditRoleValue("dashboard_vehicle_summary", EditData?.roles?.view?.dashboard_vehicle_summary),
                            vehicle_list: EditRoleValue("vehicle_list", EditData?.roles?.view?.vehicle_list),
                            vehicle_map: EditRoleValue("vehicle_map", EditData?.roles?.view?.vehicle_map),
                            trails: EditRoleValue("trails", EditData?.roles?.view?.trails),
                            trip_trail: EditRoleValue("audittrail", EditData?.roles?.view?.trip_trail),
                            by_vehicle: EditRoleValue("by_vehicle", EditData?.roles?.view?.by_vehicle),
                            by_group: EditRoleValue("by_group", EditData?.roles?.view?.by_group),
                            schedule: EditRoleValue("schedule", EditData?.roles?.view?.schedule),
                            panic_status: EditRoleValue("panic_status", EditData?.roles?.view?.panic_status),
                            panic_sos: EditRoleValue("panic_sos", EditData?.roles?.view?.panic_sos),
                            entity: EditRoleValue("entity", EditData?.roles?.view?.entity),
                            group: EditRoleValue("group", EditData?.roles?.view?.group),
                            audit_trail: EditRoleValue("audit_trail", EditData?.roles?.view?.audit_trail),
                            route_list: EditRoleValue("route_list", EditData?.roles?.view?.route_list),
                            route_assign: EditRoleValue("route_assign", EditData?.roles?.view?.route_assign),
                            geofence_configuration: EditRoleValue("geofence_config", EditData?.roles?.view?.geofence_configuration),
                            alarm_configuration: EditRoleValue("alarm_config", EditData?.roles?.view?.alarm_configuration),
                            alarm_log: EditRoleValue("alarm_log", EditData?.roles?.view?.alarm_log),
                            user: EditRoleValue("user", EditData?.roles?.view?.user),
                            role: EditRoleValue("role", EditData?.roles?.view?.role),
                            control_panel: EditRoleValue("control_panel", EditData?.roles?.view?.control_panel)
                        }
                    }
                });

            }
            EditRoleId = 0;
        }
        getEditRole();
    }, [EditData])

    const checkundefined = (key, value) => {
        if (key === "alarm" && typeof (value) === "undefined")
            return false;
        else if (key === "report" && typeof (value) === "undefined")
            return "";
        else
            return value;

    }

    const EditRoleValue = (name, dataValue) => {
        let arrValue = [];
        if (typeof (dataValue) === 0 || typeof (dataValue) === "undefined")
            dataValue = ""
        else
            dataValue = dataValue.toString();

        if (dataValue === "12"){
            arrValue.push("8")
            arrValue.push("4")
        }
        else if (dataValue === "4")
            arrValue.push("4")
        else if (dataValue === "6")
        {
            arrValue.push("2")
            arrValue.push("4")
        }
        else if (dataValue === "5")
        {
            arrValue.push("1")
            arrValue.push("4")
        }
        else if (dataValue === "8")
        {
            arrValue.push("8")
            arrValue.push("4")
        }
        else if (dataValue === "1"){
            arrValue.push("1")
            arrValue.push("4")
        }
        else if (dataValue === "9") {
            arrValue.push("8")
            arrValue.push("1")
            arrValue.push("4")
        }
        else if (dataValue === "14") {
            arrValue.push("8")
            arrValue.push("2")
            arrValue.push("4")
        }
        else if (dataValue === "15") {
            arrValue.push("8")
            arrValue.push("2")
            arrValue.push("1")
            arrValue.push("4")
        }
        else if (dataValue === "13") {
            arrValue.push("8")
            arrValue.push("1")
            arrValue.push("4")
        }
        else if (dataValue === "7") {
            arrValue.push("2")
            arrValue.push("1")
            arrValue.push("4")
        }
        else {
            return 0;
        }
        return arrValue;
    }


    const onSubmit = data => {
        setFormValues({
            id: data.id,
            rolename: data.rolename,
            createdon: data.createdon,
            lastupdate: data.lastupdate,
            roles: {
                alarm: {
                    general: data.roles.alarm.general,
                    sos: data.roles.alarm.sos,
                    vibration: data.roles.alarm.vibration,
                    movement: data.roles.alarm.movement,
                    low_speed: data.roles.alarm.low_speed,
                    overspeed: data.roles.alarm.overspeed,
                    fall_down: data.roles.alarm.fall_down,
                    low_power: data.roles.alarm.low_power,
                    low_battery: data.roles.alarm.low_battery,
                    fault: data.roles.alarm.fault,
                    power_off: data.roles.alarm.power_off,
                    power_on: data.roles.alarm.power_on,
                    door: data.roles.alarm.door,
                    lock: data.roles.alarm.lock,
                    unlock: data.roles.alarm.unlock,
                    geofence: data.roles.alarm.geofence,
                    geofence_enter: data.roles.alarm.geofence_enter,
                    geofence_exit: data.roles.alarm.geofence_exit,
                    gps_antenna_cut: data.roles.alarm.gps_antenna_cut,
                    accident: data.roles.alarm.accident,
                    tow: data.roles.alarm.tow,
                    idle: data.roles.alarm.idle,
                    high_rpm: data.roles.alarm.high_rpm,
                    acceleration: data.roles.alarm.acceleration,
                    braking: data.roles.alarm.braking,
                    cornering: data.roles.alarm.cornering,
                    lane_change: data.roles.alarm.lane_change,
                    fatigue_driving: data.roles.alarm.fatigue_driving,
                    power_cut: data.roles.alarm.power_cut,
                    power_restored: data.roles.alarm.power_restored,
                    jamming: data.roles.alarm.jamming,
                    temperature: data.roles.alarm.temperature,
                    parking: data.roles.alarm.parking,
                    bonnet: data.roles.alarm.bonnet,
                    foot_brake: data.roles.alarm.foot_brake,
                    fuel_leak: data.roles.alarm.fuel_leak,
                    tampering: data.roles.alarm.tampering,
                    removing: data.roles.alarm.removing
                },
                report: {
                    all: data.roles.report.all * 1,
                    route: data.roles.report.route * 1,
                    trip: data.roles.report.trip * 1,
                    summary: data.roles.report.summary * 1,
                    event: data.roles.report.event * 1,
                    stop: data.roles.report.stop * 1,
                    geofence: data.roles.report.geofence * 1,
                    panic: data.roles.report.panic * 1,
                    reportedPermitViolation: data.roles.report.reportedPermitViolation * 1,
                    generatedPanicViolation: data.roles.report.generatedPanicViolation * 1,
                    deviceStatistics: data.roles.report.deviceStatistics * 1,
                    deviceHistoryLogs:data.roles.report.deviceHistoryLogs * 1,
                    vltdOem: data.roles.report.vltdOem * 1
                },
                permission: {
                    audit_trail: checkRoleValue("audittrail", data.roles.permission.audit_trail),
                    command: checkRoleValue("command", data.roles.permission.command),
                    dashboard: checkRoleValue("dashboard", data.roles.permission.dashboard),
                    device: checkRoleValue("device", data.roles.permission.device),
                    driver: checkRoleValue("driver", data.roles.permission.driver),
                    event: checkRoleValue("event", data.roles.permission.event),
                    geofence: checkRoleValue("geofence", data.roles.permission.geofence),
                    group: checkRoleValue("group", data.roles.permission.group),
                    // leg: checkRoleValue("leg", data.roles.permission.leg),
                    notification: checkRoleValue("notification", data.roles.permission.notification),
                    control_panel: checkRoleValue("control_panel", data.roles.permission.control_panel),
                    position: checkRoleValue("position", data.roles.permission.position),
                    route: checkRoleValue("route", data.roles.permission.route),
                    schedule: checkRoleValue("schedule", data.roles.permission.schedule),
                    server: checkRoleValue("server", data.roles.permission.server),
                    sos: checkRoleValue("sos", data.roles.permission.sos),
                    statistic: checkRoleValue("statistic", data.roles.permission.statistic),
                    trip: checkRoleValue("trip", data.roles.permission.trip),
                    role: checkRoleValue("role", data.roles.permission.role),
                    user: checkRoleValue("user", data.roles.permission.user),
                    vehicle_registration: checkRoleValue("vehiclereg", data.roles.permission.vehicle_registration),
                    generate_permit_violation: checkRoleValue("generateper", data.roles.permission.generate_permit_violation),
                },
                view:
                {
                    dashboard_overall: checkViewValue("dashboard_overall", data.roles.view.dashboard_overall),
                    dashboard: checkViewValue("dashboard", data.roles.view.dashboard),
                    dashboard_vehicle_summary: checkViewValue("dashboard_vehicle_summary", data.roles.view.dashboard_vehicle_summary),
                    vehicle_list: checkViewValue("vehicle_list", data.roles.view.vehicle_list),
                    vehicle_map: checkViewValue("vehicle_map", data.roles.view.vehicle_map),
                    trails: checkViewValue("trails", data.roles.view.trails),
                    trip_trail: checkViewValue("trip_trail", data.roles.view.trip_trail),
                    by_vehicle: checkViewValue("by_vehicle", data.roles.view.by_vehicle),
                    by_group: checkViewValue("by_group", data.roles.view.by_group),
                    schedule: checkViewValue("schedule", data.roles.view.schedule),
                    panic_status: checkViewValue("panic_status", data.roles.view.panic_status),
                    panic_sos: checkViewValue("panic_sos", data.roles.view.panic_sos),
                    entity: checkViewValue("entity", data.roles.view.entity),
                    group: checkViewValue("group", data.roles.view.group),
                    audit_trail: checkViewValue("audit_trail", data.roles.view.audit_trail),
                    route_list: checkViewValue("route_list", data.roles.view.route_list),
                    route_assign: checkViewValue("route_assign", data.roles.view.route_assign),
                    geofence_configuration: checkViewValue("geofence_config", data.roles.view.geofence_configuration),
                    alarm_configuration: checkViewValue("alarm_config", data.roles.view.alarm_configuration),
                    alarm_log: checkViewValue("alarm_log", data.roles.view.alarm_log),
                    user: checkViewValue("user", data.roles.view.user),
                    role: checkViewValue("role", data.roles.view.role),
                    control_panel: checkViewValue("control_panel", data.roles.view.control_panel),
                }
            }
        })
    }


    const checkViewValue = (name, dataValue) =>{
        if (dataValue?.length > 0) return 1;
            return 0;
    }

    const checkRoleValue = (name, dataValue) => {
        let changeValue = "0";
        if (Array.isArray(dataValue)) {
            if (dataValue.length === 1) {
                if (name === "control_panel") return dataValue[0]*1;
                if(dataValue.indexOf("4") > -1) return dataValue[0] * 1;
                return dataValue[0] * 1 + 4
            }
            else if (dataValue.length > 1 && dataValue.indexOf("4") > -1) {
               if (name === "control_panel") return "9" * 1
                dataValue?.map((value, index)=>{
                    changeValue = (changeValue*1) + (value*1)
                })
               
                return changeValue * 1;
            }
            else if (dataValue.length > 1 && dataValue.indexOf("4") < 0) {
                if (name === "control_panel") return "9" * 1
                dataValue?.map((value, index)=>{
                    changeValue = (changeValue*1) + (value*1)
                })
               
                return changeValue * 1 + 4;
            }
        }
        else {
            return (dataValue === "" || dataValue === false) ? 0 : dataValue * 1 ;
        }
    }

    useEffect(() => {
        if (formValues.rolename !== "" && formValues.rolename !== undefined) {
            vtsDataProvider.update('userrole', formValues, setNotifyData, "User Role Updated Successfully", "Failed to Update User Role");
            setFormValues("")
            reset();
            handleEditRoleClose();
        }

    }, [formValues])

    useEffect(() => {
        if (notifyData?.severity === "success") {
            refresh();
        }
    }, [notifyData])

    return (
        <>
            <Modal
                open={EditRoleopen}
                onClose={handleEditRoleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description" className="AddRoleModal"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={style} className="modal-box">
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "left !important" }} >
                            Edit Role
                        </Typography>

                        <Grid container spacing={2} className="rolename-top">
                            <Grid item xs={8}>
                                <Box sx={{ marginTop: '10px' }} className="rolename">
                                    <FormLabel>Role Name</FormLabel>
                                    <TextField2
                                        {...register("rolename", { required: true })}
                                        value={watch("rolename")}
                                        sx={{ width: "82%" }}
                                    />
                                    {errors.rolename && <div style={errorstyle}>This field is required</div>}
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <div className="modal-footer" style={{ marginTop: '10px' }}>
                                    <Button variant="contained" color="primary" type="submit">Update</Button>
                                    <Button variant="contained" className="cancel-btn" onClick={handleEditRoleClose} color="primary" sx={{ marginRight: '1em' }}>Cancel</Button>
                                    {/* <ToastContainer /> */}
                                </div>
                            </Grid>
                        </Grid>


                        <Box sx={{ flexGrow: 1, marginTop: '2em' }}>
                            <Grid container spacing={2} sx={{ marginLeft: "0px !important" }}>
                                <Grid item xs={2} className="left-side">
                                    <Typography variant="h6" component="h5">Permissions:</Typography>
                                    <Tabs value={Rolevalue} onChange={Rolehandle} className="Addrole-tabs">

                                        <Tab label="Alarms" />
                                        <Tab label="Report" />
                                        <Tab label="Permission" />
                                        <Tab label="View" />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={10} sx={{ paddingLeft: "5px" }}>

                                    <TabPanel value={Rolevalue} index={0}>
                                        <Typography variant="h6" component="h4">
                                            <TreeViewAlarmComponent watch={watch} register={register} />
                                        </Typography>
                                    </TabPanel>
                                    <TabPanel value={Rolevalue} index={1}>
                                        <TreeViewReportComponent watch={watch} setValue={setValue} register={register} />
                                    </TabPanel>

                                    <TabPanel value={Rolevalue} index={2}>
                                        <TreeViewPermissionComponent watch={watch} register={register} />
                                    </TabPanel>
                                    <TabPanel value={Rolevalue} index={3}>
                                        <TreeViewViewComponent watch={watch} register={register} />
                                    </TabPanel>
                                </Grid>

                            </Grid>
                        </Box>

                    </Box>


                </form>
            </Modal>
            <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
        </>
    );
};
