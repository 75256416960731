import React, { useState, useEffect } from 'react';
import { List, TextField, useGetList, Datagrid, DateTimeInput, ListContextProvider, TextInput, Pagination, ListToolbar, TopToolbar, Button } from "react-admin";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import {
  Grid,
  Card
} from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ViewIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import "./route.css";
import AddButton from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import RouteIcon from '@mui/icons-material/RouteOutlined';
import ArrowIcon from '@mui/icons-material/ArrowBackOutlined';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MapView from '../../components/mapview';
import SelectedList from '../../components/Accordian';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableRowsIcon from '@mui/icons-material/TableRowsOutlined';
import DistanceIcon from '@mui/icons-material/RouteOutlined';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import PointTypeIcon from '@mui/icons-material/PinDropOutlined';
import { RouteList } from '../../components/RouteList';
import { RouteAssignList } from '../../components/RouteAssignList';
import useTabPermissions from '../../hooks/useTabPermissions';
import { TitleContext } from '../../utils/TitleContext';
import { useLocation } from "react-router-dom";

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  flexRow: { display: 'flex', flexDirection: 'row' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};
const Buttonstyle = {
  position: 'absolute',
  background: '#4646F2',
  color: '#fff',
  zIndex: 1,
  textTransform: 'capitalize',
  width: '130px',
  border: '1px solid #4546F2'
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const defaultValues = {
  name: "",
  phone: "",
  email: "",
  password: "",

};

const position = [51.505, -0.09]


const TabPanel = props => {

  const { index, value, children } = props;
  return (
    <React.Fragment>
      {value === index && (

        <Typography>{children}</Typography>

      )}
    </React.Fragment>
  )
}




export const RouteModule = () => {
  const location = useLocation()
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [assignexpanded, setAssignExpand] = React.useState(false);
  const [filter, setFilter] = useState('');
  const { setTitle } = React.useContext(TitleContext);

  const tabView = useTabPermissions('route')
  useEffect(()=>{
    if(location?.state?.navigate)
    setValue(location?.state?.navigate)
  },[location?.state])

  useEffect(() => { setTitle("Route") }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }} className="route-sec">
      <Tabs value={value} onChange={handleChange}>
        {tabView && (tabView?.viewRoute) && <Tab label="Route List" />}
        {tabView && (tabView?.viewRouteAssign) && <Tab label="Route assign" />}

      </Tabs>
      <TabPanel value={value} index={0}>
        {tabView && (tabView?.readRoute) && (
          <RouteList
            createper={tabView.createRoute}
            updateper={tabView.updateRoute}
            deleteper={tabView.deleteRoute}>
          </RouteList>)}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {tabView && (tabView?.readRouteAssign) && (
          <RouteAssignList
            createper={tabView.createRouteAssign}
            updateper={tabView.updateRouteAssign}
            deleteper={tabView.deleteRouteAssign}>
          </RouteAssignList>
        )}
      </TabPanel>
    </Box>
  );
}
