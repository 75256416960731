import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import ModalCloseIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckIcon from '@mui/icons-material/TaskAltOutlined';
import TimeIcon from '@mui/icons-material/ScheduleOutlined';
import { Tabs, Tab, Box, Typography, Card } from "@mui/material";
import useNotification from '../hooks/useNotification';
import moment from "moment";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const Vehiclecommand = ({responseStatus,handleCommentClose}) => {

  const [notifimessage, setNotiMessage] = useState("Waiting for respose from device");
  
  const [notificationerr, notification, setNotification, setSocketUrl] = useNotification();


  //console.log("notificationvc",notification)

  useEffect(()=>{
   if(notification?.length>0)
   {
    if(notification[0].deviceId === responseStatus.deviceId && notification[0]?.type === "commandResult")
    {
      setNotiMessage("Response received")
    }
  }
  },[notification])

  const get12hoursformat = (date1) =>
  {
   var date = new Date(date1);
   //date1 = moment(date1).format("DD-MMM-YYYY hh:mm:ss")
  
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    // return "date";
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    var time = hours + ":" + minutes + ":" + seconds + " " + am_pm;
    return time;
  }

  return(
  <>
<Modal
open={responseStatus?.open}
onClose={handleCommentClose}
className="command-modal"
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"
>
<Box sx={style} className="modal-box comment-modal-box">
  <span className="close-btn" onClick={handleCommentClose}>
    <ModalCloseIcon />
  </span>
  <Box className="succ-comment" >
      <Box className="successIcon">
        <CheckIcon />
      </Box>
      <Typography id="modal-modal-title" variant="h6" component="h2">
      {responseStatus?.status}
        {/* Received */}
      </Typography>
      <Typography variant="h6" component="p" className="comment-p">
        {responseStatus?.msg}
        {/* A response was received from the vehicle */}
      </Typography>
      <Box sx={{width:"100%", overflowX:"auto",paddingBottom:"10px"}}>
      <Typography variant="h6" component="p" className="waiting-p">
         {notifimessage === "Waiting for respose from device" ?<TimeIcon />:""}{notifimessage}</Typography>
        {/* {notificationerr?.errmessage} */}
        {
        notification?.length>0?
       notification[0].deviceId === responseStatus.deviceId && notification[0]?.type === "commandResult" ?
       <>
        {/* <p class="comment-response">Device Name : {notification[0]?.deviceName}</p> */}
        <p class="comment-response">Vehicle RegNo : {notification[0]?.attributes?.vehicleRegNo}</p>
        <p class="comment-response">Result : {notification[0]?.attributes?.result}</p>
        <p class="comment-response">DeviceIMEI : {notification[0]?.attributes?.deviceIMEI}</p>
        {/* <p class="comment-response">{moment(notification[0]?.eventTime).format("DD-MMM-YYYY")} <span class="item-date">{get12hoursformat(notification[0]?.eventTime)}</span></p> */}
        </>
        :""
         :""
        }
      </Box>
    </Box>
</Box>
</Modal></>
  )
}


// {
//   "events": [
//       {
//           "id": 0,
//           "attributes": {
//               "alarm": "sos"
//           },
//           "deviceId": 109,
//           "type": "alarm",
//           "eventTime": "2023-02-16T07:17:33.000+00:00",
//           "positionId": 9713836,
//           "geofenceId": 0,
//           "maintenanceId": 0,
//           "action": null,
//           "remark": null,
//           "status": false,
//           "location": null,
//           "latitude": 21.239859,
//           "longitude": 81.648598,
//           "groupName": null,
//           "deviceName": "UP32AB1211",
//           "geofenceName": null
//       }
//   ]
// }