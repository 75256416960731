import * as React from 'react';
import PropTypes, { resetWarningCache } from 'prop-types';
import CircularProgress, {circularProgressClasses} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {

  const {value, title, statusValues} = props

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      {
        `${Math.round(value)}%` === '0%' ?
        <CircularProgress variant="determinate" {...props} sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={4}
        value={100}
        />
        :
        <>
        <CircularProgress variant="determinate" {...props} sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={4}
        value={100}
        />
      <CircularProgress variant="determinate" {...props}  
      sx={{
        color: (theme) =>
          theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
        position: "absolute",
        left: 0
      }}
      className="color-change"/>
      </>
      }
      
      
         
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic({title, statusValues}) {
  // const [progress, setProgress] = React.useState(10);
  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  let progress = 0
  if(title === 'Idle Vehicle'){
    if(!isNaN(statusValues?.vehicle_idle/statusValues?.total_devices)){
    progress = statusValues ? (statusValues?.vehicle_idle/statusValues?.total_devices) * 100 : 0
   }
  }
  if(title === 'Moving Vehicle'){
    if(!isNaN(statusValues?.vehicle_motion/statusValues?.total_devices)){
      progress = statusValues ? (statusValues?.vehicle_motion/statusValues?.total_devices) * 100 : 0
   }
  }
  if(title === 'Stopped Vehicle'){
    if(!isNaN(statusValues?.vehicle_stopped/statusValues?.total_devices)){
      progress = statusValues ? (statusValues?.vehicle_stopped/statusValues?.total_devices) * 100 : 0
   }
  }
  if(title === 'GPS Removed'){
    if(!isNaN(statusValues?.gps_removed/statusValues?.total_devices)){
      progress = statusValues ? (statusValues?.gps_removed/statusValues?.total_devices) * 100 : 0
   }
  }
  if(title === 'Other Vehicle'){
    if(!isNaN(statusValues?.no_data/statusValues?.total_devices)){
      progress = statusValues ? (statusValues?.no_data/statusValues?.total_devices) * 100 : 0
   }
  }
  if(title === 'Inactive Vehicle'){
    if(!isNaN(statusValues?.unknown/statusValues?.total_devices)){
      progress = statusValues ? (statusValues?.unknown/statusValues?.total_devices) * 100 : 0
   }
  }

  return <CircularProgressWithLabel value={progress} title={title} statusValues={statusValues} />;
}
