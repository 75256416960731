import React, { useEffect, useState, useContext } from "react";
import { Card, Box, Typography, Grid, Button } from "@mui/material";
import {
  Form,
  TextInput,
  useGetOne,
  SimpleForm,
  RefreshButton,
  useRefresh,
  required,
  minLength,
  maxLength
} from "react-admin";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormTextField from "@mui/material/TextField";
import EditIcon from "../../images/editIcon.svg";
import vtsDataProvider, { changePassword } from "../../providers/dataProvider";
import SendNotification from "../../utils/sendNotification";
import { TitleContext } from "../../utils/TitleContext";
import EyeIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const errorstyle = {
  color: "#d32f2f",
  fontSize: "12px",
  display: "block"
};

export const Account = () => {
  const [disabledName, setDisabledName] = useState(true);
  const [notifyData, setNotifyData] = useState({});
  const [disabledEmail, setDisabledEmail] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const [updateSuccess, setUpdateSuccess] = useState({});  
  const { setTitle } = useContext(TitleContext);
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [visibleOldPassword, setShowOldPassword] = useState(false);
  const [visibleNewPassword, setShowNewPassword] = useState(false);
  const [visibleConfPassword, setShowConfPassword] = useState(false);
  const [firstNameError, setfirstNameError] = useState('')
  const [lastNameError, setlastNameError] = useState('')

  const refresh = useRefresh();
  const toggleOldPassword = () => {
    setShowOldPassword(!visibleOldPassword);
  };
  const toggleNewPassword = () => {
    setShowNewPassword(!visibleNewPassword);
  };
  const toggleConfPassword = () => {
    setShowConfPassword(!visibleConfPassword);
  };

  let regexPassword =
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [passwordValues, setPasswordValues] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [formError, setFormError] = useState({
    A: {
      new_password: true,
      confirm_password: true,
    },
    B: {
      invalid: true,
      same: true
    }
  });

  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    const ferror = { ...formError };
      Object.keys(passwordValues).some((key) => {
      ferror.A[key] = false;
      if (passwordValues[key] == "" || passwordValues[key] == null)
        ferror.A[key] = true;
    });
    ferror.B["invalid"] = false;
    ferror.B["same"] = false;
    if (passwordValues["new_password"] != "") {
      if (!passwordValues?.new_password.match(regexPassword))
        ferror.B["invalid"] = true;
    }
    if (
      passwordValues?.new_password !== "" &&
      passwordValues?.confirm_password !== ""
    ) {
      if (passwordValues?.new_password !== passwordValues?.confirm_password)
        ferror.B["invalid"] = true;
    }
    if (
      passwordValues?.old_password !== "" &&
      passwordValues?.new_password !== ""
    ) {
      if (passwordValues?.old_password == passwordValues?.new_password)
        ferror.B["same"] = true;
    }
    setFormError(ferror);  
  }, [passwordValues]);

  

  const [formValues, setFormValues] = useState({
    id: 0,
    name: "",
    phone: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  const userData = JSON.parse(localStorage?.getItem("auth"));

  const { data: userdetails } = useGetOne(
    "users",
    { id: userData?.user?.id },
    { enabled: userData?.user?.id !== 0 }
  );


  useEffect(()=>{
    setTitle("My Account");   
  },[])

  useEffect(() => {    
  //  setTitle("My Account");   
   // const userData = JSON.parse(localStorage?.getItem("auth"));
    if (userdetails)
      setUserProfile({
        id: userdetails?.id,
        name: userdetails?.name,
        email: userdetails?.email,
        phone: userdetails?.phone,
        map: userdetails?.map,
        latitude: userdetails?.latitude,
        longitude: userdetails?.longitude,
        zoom: userdetails?.zoom,
        twelveHourFormat: userdetails?.twelveHourFormat,
        coordinateFormat: userdetails?.coordinateFormat,
        poiLayer: userdetails?.poiLayer,
        disabled: userdetails?.disabled,
        administrator: userdetails?.administrator,
        readonly: userdetails?.readonly,
        deviceReadonly: userdetails?.deviceReadonly,
        limitCommands: userdetails?.limitCommands,
        expirationTime: userdetails?.expirationTime,
        userLimit: userdetails?.userLimit,
        deviceLimit: userdetails?.deviceLimit,
        roleid: userdetails?.roleid,
        createdon: userdetails?.createdon,
        lastUpdate: userdetails?.lastUpdate,
      });
  }, [userdetails]);

  useEffect(() => {
    const profile = { ...userProfile };
    const getUserProfile = () => {
      if (profile) {
        setFormValues({
          id: profile?.id,
          name: profile?.name,
          phone: profile?.phone,
          email: profile?.email,
          firstName: profile?.name?.split(" ")[0]
            ? profile?.name?.split(" ")[0]
            : "",
          lastName: profile?.name?.split(" ")[1]
            ? profile?.name?.split(" ")[1]
            : "",
        });
      }
    };
    getUserProfile();
  }, [userProfile]);

  const handleChange = (e) => {
    let regex = /^[A-Za-z]+$/
    const phoneRegex = /^[0-9\b]+$/;   
    if (e.target.name == "firstName" || e.target.name == "lastName") {
    if (e.target.value == "" || regex.test(e.target.value)) {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value
      });
      setfirstNameError('');
      setlastNameError('');
    }
    else{
      if(e.target.name == "firstName"){
        setfirstNameError('Enter valid first name!')
      } else {
        setlastNameError('Enter valid last name')
      }
    }
  }
  else if (e.target.name == "phone") {
    if (e.target.value == "" || phoneRegex.test(e.target.value)) {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value
      });
    }
  }  
  else if (e.target.name == "email") { 
    setInvalidEmail(false)
    if(e.target.value != null || e.target.value != ''){
      if (!e.target.value.match(regexEmail)){
         setInvalidEmail(true)
      }
    }  
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value
      });     
  }    
  };

  const handlePasswordChange = (e) => {
    setPasswordValues({
      ...passwordValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    const formData = { ...formValues };
    const userProfileCopy = { ...userProfile };
    userProfileCopy["name"] = formData?.firstName + " " + formData?.lastName;
    userProfileCopy["email"] = formData?.email;
    userProfileCopy["phone"] = formData?.phone;
    vtsDataProvider.update(
      "users",
      userProfileCopy,
      setNotifyData,
      "User Account Updated Successfully",
      "Failed to Update UserAccount"
    );
  };

  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])

  const handleEditName = () => {
    setDisabledName(false);
  };

  const handleEditEmail = () => {
    setDisabledEmail(false);
  };

  const handleReset = () => {
    const profile = { ...userProfile };
    setFormValues(profile);
  };

  const handleResetName = () => {
    handleReset();
    setDisabledName(true);
  };

  const handleResetEmail = () => {
    handleReset();
    setDisabledEmail(true);
  };

  const handleSubmitPassword = async () => {
    const values = {
      currentPassword: passwordValues?.old_password,
      newPassword: passwordValues?.new_password,
    };
    await changePassword(values, setUpdateSuccess);
  };

  useEffect(() => {
    if (updateSuccess?.status == "SUCCESS")
      setNotifyData({
        open: true,
        message: "PASSWORD UPDATED SUCCESSFULLY!",
        severity: "success",
        duration: 4000,
      });
    else if (updateSuccess?.status == "ERROR")
      setNotifyData({
        open: true,
        message: "FAILED TO CHANGE PASSWORD! CHECK PASSWORD RULES!",
        severity: "warning",
        duration: 4000,
      });
  }, [updateSuccess]);

  const [password, showPassword] = React.useState(false);

  useEffect(() => {
    const fe = { ...formError };
    setDisabled(true)
    if(!(Object.values(fe?.A).includes(true) || Object.values(fe?.B).includes(true))){
      setDisabled(false)
    }
  }, [formError])
  

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }} className="Account-det">
        <Card
          sx={{
            minWidth: 382,
            marginTop: "2em",
            padding: "18px 22px !important",
            marginRight: "20px",
            boxShadow: "none !important",
          }}
        >
          <Form>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Typography variant="h6" component="h4">
                    Personal Information
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <span
                    label="Action"
                    className="action-icons"
                    onClick={handleEditName}
                  >
                    <img src={EditIcon} />
                    Edit
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ marginTop: "0.5em", paddingTop: "10px" }}>
                    {/* <TextInput
                        placeholder="Enter"
                        source="firstName"
                        fullWidth
                        disabled={disabled1}
                    /> */}
                    <FormControl>
                      <FormLabel>First Name</FormLabel>
                      <FormTextField
                        key="firstName"
                        name="firstName"
                        placeholder="Enter first name"
                        type="text"
                        value={formValues?.firstName}
                        disabled={disabledName}
                        onChange={handleChange}
                        fullWidth
                      />
                      {firstNameError === '' ? null :
                    <span style={errorstyle}>{firstNameError}</span>
                    }
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ marginTop: "0.5em", paddingTop: "10px" }}>
                    {/* <TextInput
                        placeholder="Enter"
                        source="lastName"
                        fullWidth
                        disabled={disabled1}
                    /> */}
                    <FormControl>
                      <FormLabel>Last Name</FormLabel>
                      <FormTextField
                        key="lastName"
                        name="lastName"
                        placeholder="Enter last name"
                        type="text"
                        value={formValues?.lastName}
                        disabled={disabledName}
                        onChange={handleChange}
                        fullWidth
                      />
                      {lastNameError === '' ? null :
                    <span style={errorstyle}>{lastNameError}</span>
                    }
                    </FormControl>
                  </Box>
                </Grid>
                {!disabledName && (
                  <Grid item xs={4}>
                    <div className="account-btn" style={{ marginTop: "10px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleResetName}
                        sx={{ marginRight: "8px !important" }}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        className="update-btn"
                        color="primary"
                      >
                        Update
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Form>
        </Card>
        <Card
          sx={{
            minWidth: 382,
            marginTop: "0.4em",
            padding: "18px 22px !important",
            marginRight: "20px",
            boxShadow: "none !important",
          }}
        >
          <Form>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Typography variant="h6" component="h4">
                    Contact Information
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <span
                    label="Action"
                    className="action-icons"
                    onClick={handleEditEmail}
                  >
                    <img src={EditIcon} />
                    Edit
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ marginTop: "0.5em", paddingTop: "10px" }}>
                    {/* <TextInput
                        placeholder="Enter"
                        source="email"
                        fullWidth
                        disabled={disabled2}
                    /> */}
                    <FormControl>
                      <FormLabel>Email</FormLabel>
                      <FormTextField
                        key="email"
                        name="email"
                        placeholder="Enter Email"
                        type="email"
                        value={formValues?.email}
                        onChange={handleChange}
                        fullWidth
                        disabled={disabledEmail}
                        error={invalidEmail}
                        helperText={invalidEmail ? 'Email not valid!' : ''}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ marginTop: "0.5em", paddingTop: "10px" }}>
                    {/* <TextInput
                        placeholder="Enter"
                        source="phone"
                        fullWidth
                        disabled={disabled2}
                    /> */}
                    <FormControl>
                      <FormLabel>Phone</FormLabel>
                      <FormTextField
                        key="phone"
                        name="phone"
                        placeholder="Enter phone"
                        type="phone"
                        value={formValues?.phone}
                        onChange={handleChange}
                        fullWidth
                        disabled={disabledEmail}
                        inputProps={{ maxLength: 12 }}
                        autoComplete="off"
                      />
                    </FormControl>
                  </Box>
                </Grid>
                {!disabledEmail && (
                  <Grid item xs={4}>
                    <div className="account-btn" style={{ marginTop: "10px" }}>
                      <Button
                        variant="contained"
                        onClick={handleResetEmail}
                        color="primary"
                        sx={{ marginRight: "8px !important" }}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        className="update-btn"
                        color="primary"
                      >
                        Update
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Box>
            </Form>
       </Card>
       <Card sx={{ minWidth: 382, marginTop: '0.4em',padding:'18px 22px !important',marginRight:"20px",boxShadow:"none !important",marginBottom:"15px" }}>
       <Form onSubmit={handleSubmitPassword}>
        <Box>
            
            <Grid item xs={10}>
                {/* <Typography variant="h6" component="h4">
                  Update Password
                </Typography> */}
                <span className="address_styling" onClick={() => showPassword(true)}>Change Password</span>
            </Grid>
            {password ?
                <Box sx={{ marginTop: '1em'}}>
                <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Box sx={{ marginTop: "0.5em", paddingTop: "10px" }}>
                    {/* <TextInput
                        placeholder="Enter"
                        source="old_password"
                        fullWidth
                    /> */}
                    <FormControl>
                      <FormLabel>Old Password</FormLabel>
                      <FormTextField
                        key="old_password"
                        name="old_password"
                        placeholder="Enter old Password"
                        type={visibleOldPassword ? "text" : "password"}
                        value={passwordValues?.old_password}
                        onChange={handlePasswordChange}
                        fullWidth
                        autoComplete="off"
                      />
                        {visibleOldPassword ? (
                          <EyeIcon
                            onClick={toggleOldPassword}
                            sx={{
                              position: "absolute",
                              right: "calc(100% - 98.6%)",
                              top: "41px",
                              fontSize: "18px",
                            }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={toggleOldPassword}
                            sx={{
                              position: "absolute",
                              right: "calc(100% - 98.6%)",
                              top: "41px",
                              fontSize: "18px",
                            }}
                          />
                        )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ marginTop: "0.5em", paddingTop: "10px" }}>
                    {/* <TextInput
                        placeholder="Enter"
                        source="new_password" 
                        validate={validatePassword}
                        fullWidth
                    /> */}
                    <FormControl>
                      <FormLabel>New Password</FormLabel>
                      <FormTextField
                        key="new_password"
                        name="new_password"
                        placeholder="Enter new Password"
                        type={visibleNewPassword ? "text" : "password"}
                        value={passwordValues?.new_password}
                        onChange={handlePasswordChange}
                        fullWidth
                        inputProps={{ maxLength: 15 }}
                        error={
                          formError?.A["new_password"] ||
                          formError?.B["invalid"] ||
                          formError?.B["same"]
                        }
                        helperText={
                          formError?.A["new_password"]
                            ? "Enter New Password"
                            : formError?.B["invalid"]
                            ? "minimum 8 to 15 characters with uppercase,lowercase,special character and number"
                            :  formError?.B["same"]
                            ?  "New Password cannot be equal to Old Password"
                            : ""
                        }
                        autoComplete="off"
                      />
                      {visibleNewPassword ? (
                          <EyeIcon
                            onClick={toggleNewPassword}
                            sx={{
                              position: "absolute",
                              right: "calc(100% - 98.6%)",
                              top: "41px",
                              fontSize: "18px",
                            }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={toggleNewPassword}
                            sx={{
                              position: "absolute",
                              right: "calc(100% - 98.6%)",
                              top: "41px",
                              fontSize: "18px",
                            }}
                          />
                        )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ marginTop: "0.5em", paddingTop: "10px" }}>
                    {/* <TextInput
                        placeholder="Enter"
                        source="confirm_password"
                        validate={validateConfirmPassword}
                        fullWidth
                    /> */}
                    <FormControl>
                      <FormLabel>Confirm Password</FormLabel>
                      <FormTextField
                        key="confirm_password"
                        name="confirm_password"
                        placeholder="Enter confirm Password"
                        type={visibleConfPassword ? "text" : "password"}
                        value={passwordValues?.confirm_password}
                        onChange={handlePasswordChange}
                        fullWidth
                        inputProps={{ maxLength: 15 }}
                        error={
                          formError?.A["confirm_password"] ||
                          formError?.B["invalid"]
                        }
                        helperText={
                          formError?.A["confirm_password"]
                            ? "Enter Confirm Password"
                            : formError?.B["invalid"]
                            ? "Passwords do not match"
                            : ""
                        }
                        autoComplete="off"
                      />
                      {visibleConfPassword ? (
                          <EyeIcon
                            onClick={toggleConfPassword}
                            sx={{
                              position: "absolute",
                              right: "calc(100% - 98.6%)",
                              top: "41px",
                              fontSize: "18px",
                            }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={toggleConfPassword}
                            sx={{
                              position: "absolute",
                              right: "calc(100% - 98.6%)",
                              top: "41px",
                              fontSize: "18px",
                            }}
                          />
                        )}
                    </FormControl>
                  </Box>
                  <div className="account-btn" style={{ marginTop: "10px" }}>
                    {/* <Button variant="contained" color="primary" sx={{marginRight:"8px !important"}}>Reset</Button> */}
                    <Button
                      variant="contained"
                      onClick={handleSubmitPassword}
                      // className="update-btn"
                      className={`${
                        disabled 
                          ? "disable"
                          : ""
                      } update-btn`}
                      color="primary"
                      disabled={disabled}
                    >
                      Update
                    </Button>
                  </div>
                </Grid>
                </Grid>
                </Box>
                :
                <></>
                }
            </Box>
            
            </Form>
       </Card>
    </Box>
    <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
    </>
  );
};
