import React, { useEffect, useState } from "react";
import {
  Datagrid,
  ListContextProvider,
  ListToolbar,
  TextField,
  Pagination,
  useGetList,
  TextInput,
  TopToolbar,
  EmailField,
  useRecordContext,
  FunctionField,
  useListController,
  Button,
  Confirm,
  RefreshButton,
  useRefresh,
} from "react-admin";

import moment from "moment";
import { Typography } from "@mui/material";
import AddButton from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ViewIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VTSListToolbar from "./VTSListToolbar";
import { Box, LinearProgress } from "@mui/material";
import EditReportSchedule from "./EditReportSchedule";
import SendNotification from "../utils/sendNotification";
import vtsDataProvider from "../providers/dataProvider";
import AddReportSchedule from './AddReportSchedule'
import useMediaQuery from '@mui/material/useMediaQuery';

const Buttonstyle = {
  position: "absolute",
  background: "#4646F2",
  color: "#fff",
  zIndex: 1,
  textTransform: "capitalize",
  width: "150px",
  border:"1px solid #4646F2"
};

export default function ReportsSchedule() {
  const [scheduleOpen, setScheduleOpen] = useState({
    open: false,
    row: null
  });
  const [editScheduleOpen, setEditScheduleOpen] = React.useState(false);
  const [notifyData, setNotifyData] = useState({});
  const [scheduleId, setScheduleId] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    deleteId: null,
  });
  
  const [currentSchedule, setCurrentSchedule] = useState({
    id: null,
    reporttype: null,
    groupid: null,
    vehicleno: null,
    reportfrom: null,
    reportto: null,
    email: null,
    emailsubject: null,
    report_period: null,
    format: null,
    day: null,
    disabled: false,
    createdon: new Date().toISOString(),
    lastupdate: new Date().toISOString(),
  });
  const [filter, setFilter] = useState("");
  const perPage = 10;
  const refresh = useRefresh()
  const sort = { field: "id", order: "ASC" };
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleScheduleOpen = () =>{
    setScheduleOpen({
      open: true,
      row: null
    })
  }

  const matches = useMediaQuery('(min-width:1800px)');


 const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPerPage(event.target.value);
  };

  const { data, isLoading, page, setPage, setPerPage, refetch, ...listContext } =
    useListController({
      resource: "reportsschedule",
    });

  const filters = [
    <TextInput label="Search" source="q" size="small" alwaysOn />,
  ];

  const filterValues = { q: filter };

  const setFilters = (filters) => setFilter(filters.q);

  const ScheduleActions = () => (
    <TopToolbar className="reports-toolbar">
      
      <AddButton
        onClick={handleScheduleOpen}
        className="Addgroup"
        startIcon={<AddIcon />}
        sx={Buttonstyle}
      >
        Schedule Report
      </AddButton>
    </TopToolbar>
  );

  const DurationField = () => {
    const record = useRecordContext();
    let reportFrom = record["reportfrom"]
      ? moment(record["reportfrom"]).format("DD MMM YYYY, HH:mm:ss")
      : null;
    let reportTo = record["reportto"]
      ? moment(record["reportto"]).format("DD MMM YYYY, HH:mm:ss")
      : null;
    return <span>{`${reportFrom} - ${reportTo}`}</span>;
  };

  const getReportDetails = (record) => {
    let details = null;
    if (record["report_period"] == 7) details = "Weekly";
    else if (record["report_period"] == 14) details = "Biweekly";
    else if (record["report_period"] == 30) details = "Monthly";
    else details = "Details Not Available";
    return <span>{details}</span>;
  };

  const StatusField = ({ source }) => {
    const record = useRecordContext();
    return <span>{`${record["disabled"] ? "Active" : "InActive"}`}</span>;
  };


  useEffect(() => {
    if (notifyData?.severity === "success") {
      refresh();
    }
  }, [notifyData])



  const handleScheduleClose = () =>{
    setScheduleOpen({
      open: false,
      row: null
    })
  }


  const CustomEditButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <EditIcon onClick={(event) => handleEditClick(event, record, false)} />
      </div>
    );
  };

  const CustomViewButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <ViewIcon onClick={(event) => handleEditClick(event, record, true)} />
      </div>
    );
  };

  const CustomDeleteButton = ({ source }) => {
    const record = useRecordContext();
    return (
      <div>
        <DeleteIcon onClick={(event) => handleDeleteClick(event, record[source])}/>
      </div>
    );
  };

  const handleEditClick = async (event, row, readOnly) => {
   setScheduleOpen({
      open: true,
      row: row,
      readOnly: readOnly
    })
  };

  const handleDeleteClick = (event,scheduleId) => {
    setDeleteDialog({
      open: true,
      deleteId:scheduleId
    });
  }

  const handleConfirmClose = () => {
    setDeleteDialog({
      open: false,
      deleteId: null,
    });
  };


  useEffect(() => {
    var queryvalue = document.querySelectorAll('.list-content');
    if(queryvalue.length>0){
      queryvalue[0].scrollTop = 0;
  }
  },[]);
  
  const handleConfirmDelete = () => {
    if(deleteDialog.deleteId !== null) 
    {
      vtsDataProvider.delete('reportsschedule', { id: deleteDialog.deleteId},setNotifyData,"Reports Schedule Deleted Succesfully","Failed to Delete Reports Schedule");
      handleConfirmClose();
    }
  }

  
  const CustomEmpty = () => <div className="no-records">No Records</div>


  if (isLoading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  } else
    return (
      <>
        <ListContextProvider
          value={{
            data,
            filterValues,
            page,
            setFilters,
            setPage,
            setPerPage,
            ...listContext,
          }}
        >
          <VTSListToolbar
            filters={filters}
            actions={<ScheduleActions />}
          />
         {matches == true ? (
              <Pagination rowsPerPageOptions={[25, 50, 100, 150]} perPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}  />
              
              ) : (
             <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} />
  	      )}
          <Box className="list-content">
          <Datagrid empty={<CustomEmpty />} bulkActionButtons={false}>
            <TextField label="Report Type" source="reporttype" />
            <FunctionField
              label="Device Group"
              render={(record) => `${record["groupid"]} ${record["vehicleno"]}`}
            />
            <FunctionField
              label="Details"
              render={(record) => getReportDetails(record)}
            />
            <DurationField label="Duration" />
            <TextField source="email" />
            <StatusField label="Status" />
            <span label="Action" className="action-icons">
              <CustomViewButton source="id" />
              <CustomEditButton source="id" />
              <CustomDeleteButton source="id" />
            </span>
          </Datagrid>
          </Box>
          
        </ListContextProvider>
        <AddReportSchedule
          scheduleOpen={scheduleOpen}
          handleScheduleClose={handleScheduleClose}
        />
        {/* <EditReportSchedule
          editScheduleOpen={editScheduleOpen}
          handleEditScheduleClose={() => setEditScheduleOpen(false)}
          currentSchedule={currentSchedule}
        /> */}
        <Confirm
        isOpen={deleteDialog?.open}
        title="Delete Reports Schedule"
        content="Are you sure you want to delete the report schedule?"
        onConfirm={handleConfirmDelete}
        onClose={handleConfirmClose}
      />
      <SendNotification data={notifyData} onClose={() => setNotifyData({})} />
      </>
    );
}
