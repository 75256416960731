// let apiBaseUrl = 'https://cgvtsapi.cgstate.gov.in/api'; //Prod
let apiBaseUrl = 'https://cgvtsapi.trackolet.in/api';
const urls = {
 SESSION_URL: apiBaseUrl + '/session',
 VEHICLE_SUMMARY_URL: apiBaseUrl + '/dashboard/vehicle_summary',
 USERS_URL: apiBaseUrl + '/users',
 USERS_SEARCH_URL: apiBaseUrl + '/search/user',
 DEVICE_VEHICLE_LIST_URL: apiBaseUrl + '/devices/vehicle',
 DEVICE_VEHICLE_SEARCH: apiBaseUrl + '/devices/search',
 AUDIT_TRAILS_LIST: apiBaseUrl + '/audit_trails',
 GEOFENCE_LIST: apiBaseUrl + '/geofences',
 USERROLE_LIST:apiBaseUrl+'/user_roles',
 USERROLE_SEARCH_URL: apiBaseUrl + '/search/userrole',
 DEVICE_LIST : apiBaseUrl+'/devices',
 WEBSOCKET_URL: apiBaseUrl + '/socket',
 PANIC_SOS_SERVICES_URL: apiBaseUrl + '/sos_services',
 PANIC_CHART_URL: apiBaseUrl + '/sos_services/bar_chart',
 ALARM_CONFIG_LIST : apiBaseUrl+ '/notifications',
 ALARM_LOG_LIST : apiBaseUrl+ '/notifications/alarms/logs',
 ALARM_TYPE : apiBaseUrl+ '/notifications/types',
 ALARM_NOTIFICATORS : apiBaseUrl+ '/notifications/notificators',
 ALARM_NOTIFICATION_SEARCH :apiBaseUrl+ '/search/alarm/notification/config',
 STATISTICS_URL: apiBaseUrl + '/statistics',
 EVENT_NOTI_LOGS: apiBaseUrl + '/search/event/logs',
 REPORTS_SCHEDULE_URL: apiBaseUrl + '/report_schedules',
 REPORTS_SCHEDULE_SEARCH: apiBaseUrl + '/search/schedule',
 GROUPS_LIST: apiBaseUrl + '/groups',
 VEHICLE_STATUS_URL: apiBaseUrl + '/dashboard/vehiclestatus',
 PANIC_STATUS_URL: apiBaseUrl + '/dashboard/panicstatus',
 ALARM_STATUS_URL: apiBaseUrl + '/dashboard/todayevents',
 ENTITY_LIST : apiBaseUrl + '/devices',
 ENTITY_LIST_SEARCH : apiBaseUrl + '/search/device',
 DEVICE_VEHICLE_INFO_VAHAN : apiBaseUrl + '/vehicle_registration/device_info',
 ADD_ENTITY : apiBaseUrl + '/vehicle_registration/register',
 REPORT_TYPES_URL : apiBaseUrl + '/drop/down/data',
 AUDIT_SEARCH : apiBaseUrl + '/search/audit/trails',
 AUDIT_DATE_SEARCH : apiBaseUrl + '/audit_trails/by_dates',
 REPORTS_LIST_URL : apiBaseUrl + '/reports',
 GROUP_SEARCH : apiBaseUrl + '/search/group',
 ENTITY_OTP : apiBaseUrl + '/otps',
 DEVICE_DOWNLOAD_CERTIFICATE : apiBaseUrl + '/devices/download',
//COMMUNICATION_STATUS_URL: apiBaseUrl + '/dashboard/gps_gprs',
 COMMUNICATION_STATUS_URL: apiBaseUrl + '/dashboard/device_statistics',
 GEOOFENCE_LIST: apiBaseUrl + '/geofences',
 GEOOFENCE_SEARCH: apiBaseUrl + '/search/geofence',
 CONTROL_PANEL_URL: apiBaseUrl + '/permissions/fetch',
 CONTROL_PANEL_PERMISSIONS: apiBaseUrl + '/permissions/bulk',
 TRAILS_REPORT: apiBaseUrl + '/reports/trips',
 ROUTES_LIST: apiBaseUrl + '/routes',
 ROUTES_SEARCH: apiBaseUrl + '/search/route',
 ROUTE_ASSGIN: apiBaseUrl + '/trips',
 REPORTS_ROUTE: apiBaseUrl + '/reports/route',
 STOP_TYPE:apiBaseUrl + '/drop/down/data/general',
 ROUTE_ADDRESS: apiBaseUrl + '/server/geocode',
 LEG_DETAILS : apiBaseUrl + '/routes/subroute', 
 NOTIFI_POPUP : apiBaseUrl + '/notifications/bydate/logs',
 NOTIFICATION_WEBSOCKET_URL: apiBaseUrl + '/socket/notification',
 GROUP_DEVICE_PERMISSIONS: apiBaseUrl + '/permissions/group/device', 
 FORGOT_PASSWORD_OTP: apiBaseUrl + '/password/otp',
 VERIFY_OTP: apiBaseUrl + '/password/otp/verify', 
 UPDATE_PASSWORD: apiBaseUrl + '/password/update', 
 NEW_REGISTER: apiBaseUrl + '/users/register', 
 CHANGE_PASSWORD: apiBaseUrl + '/password',
 TRIP_TRAILS: apiBaseUrl + '/trips/trails',
 STATE_LIST: apiBaseUrl + '/states',
 RTO_LIST: apiBaseUrl + '/rtos',
 SIM_PROVIDER: apiBaseUrl + '/sim/providers',
 VEHICLE_CATEGORY: apiBaseUrl + '/vehicle/categories',
 GET_SIM_DATA: apiBaseUrl + '/sim/data',
 UPLOAD_ENTITY: apiBaseUrl + '/vehicle_registration/data/upload',
 ONGOING_TRIPS: apiBaseUrl + '/dashboard/active/trips',
 GEOFENCE_UPLOAD : apiBaseUrl + '/upload/bulk/geofence',
 DEVICE_COMMAND : apiBaseUrl + '/commands/send',
 ROLE_DROPDOWN : apiBaseUrl + '/user_roles',
 MAKE_DROPDOWN : apiBaseUrl + '/oem/make/model',
 NEW_REGISTER_OTP: apiBaseUrl + '/user/otps',
 TRAILS_STOP_POINTS:apiBaseUrl + '/reports/events', 
 NON_COMMUNICATION_DEVICES_EXPORT: apiBaseUrl + '/reports/device_statistics/export',
 VEHICLE_MAKE_DROPDOWN : apiBaseUrl + '/vehicle/make',
 UNTAG_ENTITY_OTP : apiBaseUrl + '/untag/otp',
 DEVICE_LOG_HISTORY: apiBaseUrl + '/device/logs',
 DEVICE_WEBSOCKET : apiBaseUrl + '/socket/device/logs',
 MMI_Token: apiBaseUrl + '/map/access/token',
 DOWNLOAD_CERTIFICATE_URL: apiBaseUrl + '/devices/download?deviceId=',
 DOWNLOAD_SAMPLE_VEHICLE_URL: apiBaseUrl + '/download/vehicle/sample',
 GEOFENCE_SAMPLE_FILE_URL: apiBaseUrl + '/download/geofence/sample?fileType=circle',
 MISC_REPORT_EXPORT_URL: apiBaseUrl + '/reports/device/history/logs?deviceId=',
 CONSTITUENCY_LIST: apiBaseUrl + '/constituencies', 
 ROUTE_EXPORT: apiBaseUrl + '/reports/route/assign/export',
 EC_DASHBOARD_URL: apiBaseUrl + '/dashboard/ePolling',
 EC_DASHBOARD_CONSTITUENCY_URL: apiBaseUrl + '/devices',
 TRIPS_STATUS_TYPE: apiBaseUrl +"/trips/status/type",
 EXPORT_ROUTE_ASSIGN: apiBaseUrl +"/trips/export",
 TRIPS_CONSOLIDATED: apiBaseUrl +"/trips/consolidated",
 VEHCILE_COUNT:apiBaseUrl+ "/dashboard/vehicle/count/status",
 MANUAL_ROUTE:apiBaseUrl+ "/trips/manual/trigger"
};


 //export const SITE_URL = "http://localhost:3000/#";
// export const SITE_URL = "http://192.46.210.120/#";
export const SITE_URL = "https://cgvts.trackolet.in/#";
//export const SITE_URL = "https://cgvts.cgstate.gov.in/#";

export default urls;